import { type FC, type ReactElement, useCallback } from "react"

import { type NavigateFunction, useNavigate } from "react-router-dom"

import { ParticipantUrls } from "@/constants"
import { type IUseTranslation, useTranslation } from "@/hooks"
import Button from "@/shared/button/Button"
import type { TEmptyCallback } from "@/shared/types/functions"
import { OwlSadImage } from "@/svgs/OwlSad"

const SwapSessionNASessionsBlock: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const navigate: NavigateFunction = useNavigate()

    const handleClickBackToSessions: TEmptyCallback = useCallback(
        (): void => navigate(ParticipantUrls.SESSION_LIST),
        [navigate]
    )

    return (
        <div className="swap-session__na-sessions">
            <div className="swap-session__na-sessions__message">
                <p className="m-0">
                    <span className="font-extrabold mr-2 color-gray">{t("common.disappointingExclamation")}</span>
                    <span className="color-gray">
                        {t("participantSide.swapSessionsScreen.noSessionsBlock.message")}
                    </span>
                </p>
            </div>

            <div className="swap-session__na-sessions__image-box">
                <OwlSadImage />
            </div>

            <Button onClick={handleClickBackToSessions} variant="accent">
                <span>{t("participantSide.swapSessionsScreen.noSessionsBlock.backButton.label")}</span>
            </Button>
        </div>
    )
}

export { SwapSessionNASessionsBlock }
