import { SVGProps, memo } from "react"

type Props = {
    width?: number
    height?: number
    className?: string
} & SVGProps<SVGSVGElement>

const Arrow = (props: Props) => {
    const { width = 8, height = 13, className = "", ...rest } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            viewBox="0 0 8 13"
            fill="none"
            {...rest}
        >
            <path d="M0.875 11.75L6.125 6.5L0.875 1.25" stroke="#FD4D00" strokeWidth="1.875" />
        </svg>
    )
}

export default memo(Arrow)
