import { type FC, type ReactElement, useLayoutEffect } from "react"

import { type SetURLSearchParams, useSearchParams } from "react-router-dom"

import { type TUseSecondaryAuth, useSecondaryAuth } from "@/hooks"
import Loader from "@/shared/spinner/PageLoader"

const Page: FC = (): ReactElement => {
    const [searchParams]: [URLSearchParams, SetURLSearchParams] = useSearchParams()

    const userId: string = searchParams.get("userId")

    const { reloadUser }: TUseSecondaryAuth = useSecondaryAuth()

    useLayoutEffect((): void => reloadUser({ userId }), [reloadUser, userId])

    return <Loader />
}

export { Page as CoachItineraryPage }
