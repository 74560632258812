import { type FC, type ReactElement } from "react"

import classNames from "classnames"

import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { type IUseTranslation, useTranslation } from "@/hooks"
import Avatar from "@/shared/avatar/Avatar"

import { SwapSessionSessionTime as SessionTimeBlock } from "."

type TSwapSessionSessionBlockProps = {
    coachPhoto?: string
    coachName?: string
    coachLastName?: string
    sessionTime?: string
    isOriginal?: boolean
}

const SwapSessionSessionBlock: FC<TSwapSessionSessionBlockProps> = ({
    coachPhoto,
    coachName,
    coachLastName,
    sessionTime,
    isOriginal = false
}: TSwapSessionSessionBlockProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const avatarSize: number = isMobile ? 40 : 50

    return (
        <article className={classNames("swap-session__session-block", { original: isOriginal })}>
            {isOriginal && !isMobile && (
                <p className="color-black mb-2 swap-session__session-block__title">
                    {t("participantSide.swapSessionsScreen.originalSessionBlock.title")}
                </p>
            )}
            <div className="swap-session__session-block__body">
                {isOriginal && isMobile && (
                    <p className="color-black swap-session__session-block__title">
                        {t("participantSide.swapSessionsScreen.originalSessionBlock.title")}
                    </p>
                )}
                <Avatar url={coachPhoto} width={avatarSize} height={avatarSize} />

                {!isMobile && (
                    <p className="swap-session__session-block__name">
                        <span>{coachName}</span>
                        <span>{coachLastName}</span>
                    </p>
                )}

                <hr />

                {sessionTime && <SessionTimeBlock withTimezone withDays startTime={sessionTime} />}
            </div>
        </article>
    )
}

export { SwapSessionSessionBlock as default, type TSwapSessionSessionBlockProps }
