import { useCallback } from "react"

import { useTranslation } from "react-i18next"

import { useSimulabContext } from "@/entities/simulab"
import Button from "@/shared/button/Button"
import CloseIconSvg from "@/svgs/CloseIconSvg"

export const SimulabErrorBox = () => {
    const { t } = useTranslation()
    const { hasError, onError } = useSimulabContext()

    const onCloseClick = useCallback(() => {
        onError(null)
    }, [])

    return (
        hasError && (
            <div className="simulab-error-message ">
                <div className="simulab-error-message__box">
                    <p className="font-extrabold m-0 fs-14 text-center color-brand">{t("Oops, try sending again.")}</p>
                    <p className="fs-14 text-center m-0">
                        {t("Our system encountered an unexpected error and failed to send your message.")}
                    </p>
                    <Button variant="default" className="p-1 simulab-error-message__close-btn" onClick={onCloseClick}>
                        <CloseIconSvg width={15} height={15} />
                    </Button>
                </div>
            </div>
        )
    )
}
