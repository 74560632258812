import { FC } from "react"

import sanitizeHtml from "sanitize-html"

import Modal from "@/shared/modal"

import "./styles.scss"

interface IProps {
    show: boolean
    onClose: () => void
    data: string
}

const ProfileSetupModal: FC<IProps> = ({ data, show, onClose }) => {
    return (
        <Modal
            onClose={onClose}
            show={show}
            headerContent={
                <h5 className="h3 font-extrabold mb-0 mx-auto text-center" id="modalAssignModuleLabel">
                    What does it mean to include my manager?
                </h5>
            }
        >
            {data && <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(data) }} />}
            <button className="btn btn--brand" data-dismiss="modal" onClick={onClose}>
                Got it
            </button>
        </Modal>
    )
}

export default ProfileSetupModal
