import { CSSProperties, FC, ReactNode, useMemo } from "react"

import classNames from "classnames"

import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { useStoreContext } from "@/context"

import "./styles.scss"

interface IProps {
    className?: string
    children: ReactNode
}

const Sidebar: FC<IProps> = ({ children, className }) => {
    const { isOpenBurgerMenu } = useStoreContext()
    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const sideBarStyles = useMemo<CSSProperties>(() => {
        if (matches && isOpenBurgerMenu) {
            return {
                display: "block"
            }
        }

        if (matches) {
            return {
                display: "none"
            }
        }

        return {
            display: "block"
        }
    }, [isOpenBurgerMenu, matches])

    return (
        <div className={classNames("sidebar-left", { [className]: className })} style={sideBarStyles}>
            {children}
        </div>
    )
}

export default Sidebar
