import { type FC, type ReactElement } from "react"

import { SimpleLayout } from "@/layouts"
import DateWidget from "@/shared/DateWidget"

import { CoachRelinkAccountsContainer } from "."

import "./CoachRelinkAccounts.scss"

const CoachRelinkAccountsPage: FC = (): ReactElement => (
    <SimpleLayout
        header={<DateWidget className="coach-date-title" />}
        headerVariant="gray"
        showProfilePhoto
        withUserDropdownWithoutMargin
    >
        <CoachRelinkAccountsContainer />
    </SimpleLayout>
)

export { CoachRelinkAccountsPage }
