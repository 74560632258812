import { useMemo } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { Option } from "$/models/option"

import Select from "@/shared/select-default/Select"
import { GoalsSortType } from "@/shared/types/sort"

import "./styles.scss"

type Props = {
    sort?: GoalsSortType
    className?: string
    onChangeSelect: (sort: Option) => void
}

export const GoalsSortSelector = ({ onChangeSelect, sort, className = "" }: Props) => {
    const { t } = useTranslation()

    const sortOptions: Option[] = useMemo(
        () => [
            {
                value: "active",
                label: t("Current")
            },
            {
                value: "archived",
                label: t("Archived")
            },
            {
                value: "all",
                label: t("All")
            }
        ],
        []
    )

    const defaultOptionIndex = sortOptions?.findIndex(option => option.value === sort) ?? 0

    return (
        <div className={classNames("d-flex align-items-center", className)}>
            <span className="mr-2">{t("View")}:</span>
            <Select
                className="goals-select"
                onSelect={onChangeSelect}
                optionsList={sortOptions}
                defaultOptionIndex={defaultOptionIndex}
            />
        </div>
    )
}
