import type { FC, ReactElement } from "react"

type TOwlSadImageProps = {
    width?: number
    height?: number
}

const OwlSadImage: FC<TOwlSadImageProps> = ({ width = 150, height = 150 }: TOwlSadImageProps): ReactElement => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_11782_49619)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M116.156 58.9337L93.0628 66.0684C85.8444 68.2986 81.8006 75.9582 84.0308 83.1766C84.3144 84.0943 84.6937 84.9796 85.1627 85.8178L93.4194 100.575C119.526 102.605 134.484 103.028 138.291 101.844C140.077 101.289 141.863 100.705 143.652 100.093C146.004 99.2881 147.258 96.7285 146.453 94.3761C146.278 93.8639 146.012 93.3875 145.667 92.9698L118.077 59.5086C117.612 58.9452 116.853 58.7182 116.156 58.9337Z"
                fill="#DA4200"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.5359 58.7995L59.0747 67.5277C66.1199 70.256 69.6195 78.179 66.8912 85.2243C66.5444 86.12 66.1042 86.9766 65.5779 87.7801L56.3119 101.926C30.1269 102.129 15.1764 101.508 11.4606 100.061C9.71853 99.3828 7.97691 98.6761 6.23577 97.9408C3.94526 96.9736 2.87254 94.3326 3.83979 92.0421C4.05039 91.5434 4.34922 91.0867 4.72193 90.6941L34.5795 59.239C35.0822 58.7094 35.855 58.5358 36.5359 58.7995Z"
                fill="#DA4200"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.5709 123.322L61.2776 142.265C61.6095 143.203 62.6386 143.694 63.5761 143.362C64.0884 143.181 64.4913 142.778 64.6727 142.265L67.1772 135.191L69.2783 129.257L70.3288 126.29L62.9751 123.322H54.5709Z"
                fill="#FEA57F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82.6432 126.29L83.6937 129.257L85.7948 135.191L88.2993 142.265C88.6313 143.203 89.6603 143.694 90.5979 143.362C91.1101 143.181 91.5131 142.778 91.6944 142.265L98.4011 123.322H89.9969L82.6432 126.29Z"
                fill="#FEA57F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.7241 6.73987L62.1411 18.6523C71.1907 22.0837 81.1834 22.0837 90.233 18.6523L121.805 6.68101C122.968 6.24025 124.267 6.82528 124.708 7.98771C124.909 8.51838 124.903 9.10547 124.689 9.63143L121.905 16.4996C120.562 19.8134 119.871 23.3555 119.871 26.9313V32.3454V65.6738V88.8715C119.871 112.873 100.414 132.331 76.4122 132.331C52.4105 132.331 32.9532 112.873 32.9532 88.8715V65.6738V32.3454V26.9313C32.9532 23.3597 32.2219 19.8259 30.8044 16.5476L27.8599 9.73802C27.3665 8.59695 27.8915 7.27194 29.0326 6.77853C29.5699 6.54618 30.1767 6.53232 30.7241 6.73987Z"
                fill="#FE4D00"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M75.5133 75.582H77.3114C90.8677 75.582 101.857 86.5716 101.857 100.128C101.857 113.684 90.8677 124.674 77.3114 124.674H75.5133C61.957 124.674 50.9674 113.684 50.9674 100.128C50.9674 86.5716 61.957 75.582 75.5133 75.582Z"
                fill="#FEA57F"
                stroke="#FEA57F"
                strokeWidth="3.60157"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.4984 62.6024C65.5819 62.6024 72.1349 56.049 72.1349 47.965C72.1349 41.2536 67.7637 34.9702 61.4592 33.8698C58.3525 33.3275 56.8031 37.2774 53.4792 39.848C49.3255 43.0604 42.8619 43.5205 42.8619 47.965C42.8619 56.049 49.4149 62.6024 57.4984 62.6024Z"
                fill="#FFDCCE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M95.0145 62.6024C86.9311 62.6024 80.3781 56.049 80.3781 47.965C80.3781 41.2536 84.7493 34.9702 91.0537 33.8698C94.1604 33.3275 95.7098 37.2774 99.0337 39.848C103.187 43.0604 109.651 43.5205 109.651 47.965C109.651 56.049 103.098 62.6024 95.0145 62.6024Z"
                fill="#FFDCCE"
            />
            <path
                opacity="0.9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M95.2944 56.2306C91.1905 56.2306 87.8636 52.9035 87.8636 48.7992C87.8636 45.9794 89.4019 43.4635 91.7481 42.2672C93.5118 41.3679 95.1573 42.2687 97.4185 43.9361C99.5289 45.4923 102.725 44.695 102.725 48.7992C102.725 52.9035 99.3983 56.2306 95.2944 56.2306Z"
                fill="#071C41"
            />
            <path
                d="M98.1723 47.6054C99.4159 47.6054 100.424 46.5972 100.424 45.3535C100.424 44.1098 99.4159 43.1016 98.1723 43.1016C96.9287 43.1016 95.9205 44.1098 95.9205 45.3535C95.9205 46.5972 96.9287 47.6054 98.1723 47.6054Z"
                fill="#FFDCCE"
            />
            <path
                d="M99.0069 50.7726C99.6975 50.7726 100.257 50.2127 100.257 49.522C100.257 48.8314 99.6975 48.2715 99.0069 48.2715C98.3163 48.2715 97.7565 48.8314 97.7565 49.522C97.7565 50.2127 98.3163 50.7726 99.0069 50.7726Z"
                fill="#FFDCCE"
            />
            <path
                opacity="0.9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.7771 56.2306C61.8811 56.2306 65.208 52.9035 65.208 48.7992C65.208 45.9794 63.6696 43.4635 61.3235 42.2672C59.5598 41.3679 57.9143 42.2687 55.6531 43.9361C53.5427 45.4923 50.3463 44.695 50.3463 48.7992C50.3463 52.9035 53.6732 56.2306 57.7771 56.2306Z"
                fill="#071C41"
            />
            <path
                d="M54.4028 47.6054C55.6464 47.6054 56.6545 46.5972 56.6545 45.3535C56.6545 44.1098 55.6464 43.1016 54.4028 43.1016C53.1592 43.1016 52.151 44.1098 52.151 45.3535C52.151 46.5972 53.1592 47.6054 54.4028 47.6054Z"
                fill="#FFDCCE"
            />
            <path
                d="M53.9864 51.1905C54.677 51.1905 55.2369 50.6307 55.2369 49.94C55.2369 49.2493 54.677 48.6895 53.9864 48.6895C53.2958 48.6895 52.736 49.2493 52.736 49.94C52.736 50.6307 53.2958 51.1905 53.9864 51.1905Z"
                fill="#FFDCCE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72.5589 64.7872C72.5711 64.768 72.5833 64.7486 72.5956 64.7289C73.8585 62.7049 76.5229 62.0879 78.5469 63.3507C79.1249 63.7114 79.6094 64.2036 79.9608 64.7872C80.5936 65.8382 81.5853 66.8711 82.9357 67.886C83.2935 68.1549 83.3655 68.6629 83.0967 69.0207C83.0714 69.0542 83.0436 69.0858 83.0135 69.1151L81.9184 70.1788C80.7293 71.3338 79.6944 72.6377 78.8396 74.0581L77.5285 76.2365C77.1131 76.9267 76.2168 77.1495 75.5266 76.7341C75.3226 76.6113 75.1518 76.4405 75.029 76.2365L73.7179 74.0581C72.8631 72.6377 71.8282 71.3338 70.6391 70.1788L69.5706 69.1408C69.2495 68.829 69.2421 68.316 69.5539 67.995C69.5863 67.9616 69.6216 67.9311 69.6592 67.9037C70.7955 67.0791 71.762 66.0403 72.5589 64.7872Z"
                fill="#FFDCCE"
            />
            <path
                d="M62.2272 104.676L68.3142 110.267C68.8653 110.773 69.7121 110.773 70.2632 110.267L75.3757 105.571C75.9268 105.065 76.7736 105.065 77.3247 105.571L82.4373 110.267C82.9884 110.773 83.8352 110.773 84.3862 110.267L90.4733 104.676"
                stroke="#FE4D00"
                strokeWidth="3.60157"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M62.2272 92.5162L68.3142 98.1067C68.8653 98.6129 69.7121 98.6129 70.2632 98.1067L75.3757 93.4112C75.9268 92.9051 76.7736 92.9051 77.3247 93.4112L82.4373 98.1067C82.9884 98.6129 83.8352 98.6129 84.3862 98.1067L90.4733 92.5162"
                stroke="#FE4D00"
                strokeWidth="3.60157"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_11782_49619">
                <rect width="150" height="150" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export { OwlSadImage, type TOwlSadImageProps }
