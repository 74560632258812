import { FC } from "react"

import { useTranslation } from "react-i18next"
import sanitizeHtml from "sanitize-html"

import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import TextInput from "@/shared/input/TextInput"

import QuestionRow from "../diagnostic-question/QuestionRow"

interface IProps {
    item: any // TODO add typings
    error: any
    register: any
    name: string
    order: number
    className?: string
}

const ReflectionQuestion: FC<IProps> = ({ item, register, name, error, order, className }) => {
    const { t } = useTranslation()

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    return (
        <section className={className}>
            <QuestionRow
                order={order}
                question={sanitizeHtml(getValueFromTranslatedObject(item.question), {
                    allowedTags: ["b", "i", "em", "strong", "a"]
                })}
            />
            <WarningMessage message={error} />
            <TextInput
                className="min-heigth-52"
                register={register}
                placeholder={getValueFromTranslatedObject(item?.prompt) || t("Start typing...")}
                name={name}
                variant="rect"
            />
        </section>
    )
}

export default ReflectionQuestion
