import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"
import { ServerStateKeys } from "@/constants"

const getOffboarding = async () => {
    const { data } = await http.get(Urls.offboardingJourneyReflection())
    return data
}

export default function useOffBoarding() {
    return useQuery([ServerStateKeys.Offboarding], getOffboarding, {
        select: useCallback(data => {
            return data
        }, [])
    })
}
