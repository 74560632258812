import { HTMLProps } from "react"

import classNames from "classnames"

import TextInput from "./TextInput"

type Props = {
    register: any
    className?: string
    labelText: string
    inputClassName?: string
    inputBehavior?: "textarea" | "input"
    inputVariant?: "default" | "rect" | "rect-no-border"
    boxVariant?: "default" | "rect"
    backgroudVariant?: "dark" | "light"
    errors?: any
} & HTMLProps<HTMLInputElement | HTMLTextAreaElement>

const TextInputBox = ({
    register,
    className = "",
    placeholder,
    inputClassName,
    name,
    backgroudVariant = "dark",
    labelText,
    errors,
    inputVariant = "default",
    boxVariant = "default",
    inputBehavior = "input",
    onBlur,
    ...rest
}: Props) => {
    return (
        <div className={classNames("text-input-box", className, backgroudVariant, boxVariant)}>
            <p>{labelText}</p>
            <TextInput
                textArea={inputBehavior === "textarea"}
                name={name}
                variant={inputVariant}
                register={register}
                errors={errors}
                placeholder={placeholder}
                onBlur={onBlur}
                className={classNames("gray-placeholder text-input-box__input", inputClassName)}
                groupClassName="mb-0 text-input-box__input-wrapper"
                {...rest}
            />
        </div>
    )
}

export default TextInputBox
