import { type FC, type ReactElement, type ReactNode } from "react"

import classNames from "classnames"

type TCoachTableGeneralCellProps = {
    children: ReactNode
    isHidden?: boolean
    isLarge?: boolean
    withoutLeftPadding?: boolean
    withoutLeftAlignedText?: boolean
    withCenterAlignedText?: boolean
}

const CoachTableGeneralCell: FC<TCoachTableGeneralCellProps> = ({
    children,
    isHidden = false,
    isLarge = false,
    withoutLeftAlignedText = false,
    withoutLeftPadding = false,
    withCenterAlignedText = false
}: TCoachTableGeneralCellProps): ReactElement => (
    <td
        className={classNames({
            "d-none": isHidden,
            "d-lg-table-cell": isLarge,
            "text-left": !withoutLeftAlignedText,
            "text-center": withCenterAlignedText,
            "pl-0": !withoutLeftPadding
        })}
    >
        {children}
    </td>
)

export { CoachTableGeneralCell, type TCoachTableGeneralCellProps }
