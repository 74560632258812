import { type FC, type ReactElement } from "react"

import { useTranslation } from "react-i18next"

import { SimpleLayout } from "@/layouts"

import OwlErrorLogo from "../../svgs/OwlErrorLogo"
import BackButton from "../back-btn/BackButton"

import ErrorTooltip from "./ErrorTooltip"

const InternalErrorPage: FC = (): ReactElement => {
    const { t } = useTranslation()

    return (
        <SimpleLayout withProfileHeader withFooter>
            <div className="d-flex justify-content-center align-items-center">
                <div className="mt-4">
                    <ErrorTooltip>
                        <div className="d-flex">
                            <span className="font-extrabold color-gray d-block text-center">
                                {t("participantSide.internalErrorPage.errorCode")}
                            </span>
                            &nbsp;
                            <span className="color-gray">{t("participantSide.internalErrorPage.title")}</span>
                        </div>
                    </ErrorTooltip>
                    <BackButton className="mx-auto my-3" />
                    <div className="d-flex justify-content-center">
                        <OwlErrorLogo />
                    </div>
                </div>
            </div>
        </SimpleLayout>
    )
}

export { InternalErrorPage }
