import { SVGProps, memo } from "react"

const LockSvgGray = (props: SVGProps<SVGSVGElement>) => {
    const { width = 18, height = 18, className = "" } = props

    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.897 7.45589V5.01076C12.897 3.29112 11.3765 1.89709 9.5 1.89709C7.62385 1.89709 6.10296 3.29248 6.10296 5.01076V7.45589H5.4138C4.77107 7.45589 4.25003 8.00994 4.25003 8.69022V14.8686C4.25003 15.5504 4.77072 16.103 5.4138 16.103H13.5862C14.229 16.103 14.75 15.5489 14.75 14.8686V8.69022C14.75 8.00849 14.2293 7.45589 13.5862 7.45589H12.897ZM13.25 8.99999V14.625H5.75V8.99999H13.25ZM7.625 7.45589V5.01076C7.625 3.99932 8.28382 3.37498 9.5 3.37498C10.717 3.37498 11.375 3.99838 11.375 5.01076V7.45589H7.625ZM10.1176 11.0286C10.1176 10.6875 9.84352 10.411 9.5 10.411C9.1589 10.411 8.88236 10.6851 8.88236 11.0286V12.8818C8.88236 13.2228 9.15648 13.4993 9.5 13.4993C9.8411 13.4993 10.1176 13.2252 10.1176 12.8818V11.0286Z"
                fill="#6F6F6F"
            />
        </svg>
    )
}

export default memo(LockSvgGray)
