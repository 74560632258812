import { type FC, type ReactElement } from "react"

import { type IUseTranslation, useTranslation } from "@/hooks"

const CoachRelinkAccountsSuccessfullyLinkedStatusElement: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className="d-flex align-items-center">
            <p className="color-gray mb-0 mr-1">{t("coachSide.relinkAccountsPage.calendarsBlock.status.successful")}</p>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="20" height="20" rx="10" fill="#F3F3F3" />
                <path
                    d="M6.28271 10.1032L8.55445 12.5815L13.7175 7.41846"
                    stroke="#6F6F6F"
                    strokeWidth="1.78125"
                    strokeLinecap="square"
                />
            </svg>
        </div>
    )
}

export { CoachRelinkAccountsSuccessfullyLinkedStatusElement }
