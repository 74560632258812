import { TranslationObj } from "$/utils/lang"

interface CoachRatingApi {
    id: number
    question_key: string
    questions_data: QuestionDataApi
    required: boolean
    free_response_promt: string | null
    product_type: number
    main_question_pref_lang: string
}

interface QuestionDataApi {
    main_question: TranslationObj
    one_star_question: TranslationObj
    two_star_question: TranslationObj
    five_star_question: TranslationObj
    four_star_question: TranslationObj
    three_star_question: TranslationObj
}

class QuestionsData {
    mainQuestion: TranslationObj

    oneStarQuestion: TranslationObj

    twoStarQuestion: TranslationObj

    fiveStarQuestion: TranslationObj

    fourStarQuestion: TranslationObj

    threeStarQuestion: TranslationObj

    constructor(data: QuestionDataApi) {
        this.mainQuestion = data.main_question
        this.oneStarQuestion = data.one_star_question
        this.twoStarQuestion = data.two_star_question
        this.threeStarQuestion = data.three_star_question
        this.fourStarQuestion = data.four_star_question
        this.fiveStarQuestion = data.five_star_question
    }
}

export class CoachRating {
    id: number

    questionKey: string

    questionData: QuestionsData

    required: boolean

    freeResponsePrompt: string | null

    productType: number

    mainQuestionTranslated: string

    constructor(data: CoachRatingApi) {
        this.id = data.id
        this.questionKey = data.question_key
        this.questionData = new QuestionsData(data.questions_data)
        this.required = data.required
        this.freeResponsePrompt = data.free_response_promt
        this.productType = data.product_type
        this.mainQuestionTranslated = data.main_question_pref_lang
    }
}
