import { SimulabMessage, SimulabMessageApi } from "./simulab-message"

export interface StageApi {
    id: number
    title: string
    order: number
    phase: string
    current: boolean
    completed: boolean
    messages: SimulabMessageApi[]
}

export class Stage {
    id: number

    title: string

    order: number

    phase: string

    current: boolean

    completed: boolean

    messages: SimulabMessage[]

    constructor(data: StageApi) {
        this.id = data.id
        this.title = data.title
        this.order = data.order
        this.phase = data.phase || ""
        this.current = data.current || false
        this.completed = data.completed || false
        this.messages = data?.messages?.map(message => new SimulabMessage(message)) || []
    }
}
