import type { TStoredSubUser } from "@/services"

enum ECoachUserListItemVariant {
    Coach,
    Participant,
    ProgramManager
}

type TCoachUserListItemToRender = Omit<TStoredSubUser, "avatarColor"> & {
    variant: ECoachUserListItemVariant
    description: string
    color: string
}

type TEmailBannerConfig = { isVisible: boolean; productType: string | null }

export { ECoachUserListItemVariant, type TCoachUserListItemToRender, type TEmailBannerConfig }
