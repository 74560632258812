import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "$/http"

import { ServerStateKeys } from "@/constants"
import Chapter, { IChapterApi } from "@/models/chapter"

import Urls from "./urls"

export const getChapter = async (id: number): Promise<IChapterApi> => {
    const { data } = await http.get(Urls.chapter(id))
    return data
}

const transformChapterData = data => new Chapter(data)

export type TUseChaptersQuery = UseQueryResult<Chapter, AxiosError>

export default function useChapters(id: number): ReturnType<(id: number) => TUseChaptersQuery> {
    return useQuery([ServerStateKeys.ChapterLearn, id], () => getChapter(id), {
        select: transformChapterData,
        refetchOnMount: "always",
        cacheTime: 0,
        enabled: !!id
    })
}
