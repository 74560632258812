import { BehavioralPracticeStatus } from "../consts"

export type PracticeStatus = (typeof BehavioralPracticeStatus)[keyof typeof BehavioralPracticeStatus]

export interface BehavioralChangeApi {
    id: number
    description: string
    order: number
    participant_id: number
    projected_impact: string
    additional_notes: string
    title: string
    practices: BehavioralPracticeApi[]
}

export interface BehavioralPracticeApi {
    id: number
    behavioral_change_id: number
    description: string
    order: number
    status: PracticeStatus
}

export class BehavioralChange {
    id: number

    description: string

    title: string

    order: number

    participantId: number

    projectedImpact: string

    additionalNotes: string

    practices: BehavioralPractice[]

    uuid?: string

    constructor(data: BehavioralChangeApi) {
        this.id = data.id
        this.description = data.description
        this.title = data.title
        this.order = data.order
        this.participantId = data.participant_id
        this.projectedImpact = data.projected_impact
        this.additionalNotes = data.additional_notes
        this.practices = data.practices.map(practice => new BehavioralPractice(practice))
    }
}

export class BehavioralPractice {
    id: number

    behavioralChangeId: number

    description: string

    order: number

    status: PracticeStatus

    constructor(data: BehavioralPracticeApi) {
        this.id = data.id
        this.behavioralChangeId = data.behavioral_change_id
        this.description = data.description
        this.order = data.order
        this.status = data.status
    }
}
