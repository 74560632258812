import { SVGProps, memo } from "react"

interface Props extends SVGProps<SVGSVGElement> {
    disabled?: boolean
}

const MessageSendIconSvg = memo(({ width = 15, height = 15, className = "", disabled = false, ...rest }: Props) => (
    <svg
        width={width}
        height={height}
        className={className}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M0.0113282 14.3042L1.27875 8.81753C1.3288 8.58404 1.5289 8.40061 1.77905 8.36729L8.86666 7.6335C9.06676 7.61683 9.06676 7.31662 8.86666 7.2833L1.77905 6.59957C1.5289 6.5829 1.3288 6.39947 1.27875 6.16598L0.0113282 0.695875C-0.105387 0.228954 0.394921 -0.154624 0.828502 0.0621871L14.6869 6.99971C15.1038 7.21654 15.1038 7.81688 14.6869 8.03364L0.828502 14.9378C0.394921 15.1546 -0.105381 14.771 0.0113282 14.3041V14.3042Z"
            fill={disabled ? "#B0B0B0" : "white"}
        />
    </svg>
))

export { MessageSendIconSvg }
