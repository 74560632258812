import { FC, memo, useMemo } from "react"

import classNames from "classnames"
import { DateTime } from "luxon"
import { useParams } from "react-router-dom"

import { Option } from "$/models/option"

import useParticipantDataByCoach from "@/api/use-participant-data"
import { DATE_FORMAT, Status } from "@/constants"
import { useAuthContext } from "@/context"
import { ModuleItem } from "@/entities/module-navigation/ui/ModuleItem"
import { Phase } from "@/models/phase"
import { UserEnrollModule } from "@/models/user"
import BackButton from "@/shared/back-btn/BackButton"
import Sidebar from "@/shared/coach-sidebar/Sidebar"
import Select from "@/shared/select-default/Select"

import PhasesLinks from "./PhasesLinks"
import ProfileCard from "./components/ProfileCard"

import "./styles.scss"

interface IProps {
    loadingEnrollments: boolean
    loadingPhases: boolean
    phasesList: Phase[]
    selectedPhase: Phase
    selectedChapter: any
    onboardingSelected: boolean
    showCoachingPlan: boolean
    onSelectModule: (option) => void
    onSelectPhase: (phase: Phase) => void
    onSelectChapter: (chapter) => void
    onOpenProfileModal: () => void
    onSelectCoachingPlan: () => void
    onSelectPrejourney: () => void
    onSelectModuleIndex: (index: number) => void
}

const LeftSidebar: FC<IProps> = ({
    loadingEnrollments,
    phasesList,
    selectedPhase,
    selectedChapter,
    loadingPhases,
    onboardingSelected,
    showCoachingPlan,
    onSelectModuleIndex,
    onSelectModule,
    onSelectPhase,
    onSelectChapter,
    onOpenProfileModal,
    onSelectPrejourney,
    onSelectCoachingPlan
}) => {
    const { user } = useAuthContext()
    const params = useParams()
    const { data: participant } = useParticipantDataByCoach(params.id)

    const onboardingSelect = new UserEnrollModule({
        id: Date.now(),
        name: { en: "Onboarding" },
        status: participant?.onboardingFinished ? Status.Completed : Status.Queued,
        version_id: 0,
        version_name: "",
        first_phase_start: "",
        last_phase_end: "",
        related_session: null,
        module_reflection_passed: false,
        show_post_module_reflection: false
    })

    const unassignedModuleCount = participant?.journeyLength - participant?.enrolledModules?.length

    const hasCoachingGoal = user?.availableCoachingPlan && participant?.availableCoachingPlan

    const optionsList = useMemo(() => {
        const options = hasCoachingGoal
            ? [new Option({ label: "Client Goal", value: "client-goal", disabled: false }), onboardingSelect]
            : [onboardingSelect]

        if (participant?.enrolledModules?.length === 0) {
            return options
        }

        return [...options, ...(participant?.enrolledModules ?? [])]
    }, [participant?.enrolledModules, hasCoachingGoal, onboardingSelect])

    const modules: UserEnrollModule[] = Array.from(
        { length: hasCoachingGoal ? participant?.journeyLength + 2 : participant?.journeyLength + 1 },
        (_, i) => optionsList?.[i] ?? null
    )

    return (
        <Sidebar>
            <BackButton className="mb-30 pl-0" />

            <div className="mb-50">
                <ProfileCard profile={participant} onClick={onOpenProfileModal} />
            </div>

            <Select
                defaultOptionIndex={0}
                indexIncrease={hasCoachingGoal ? -1 : 0}
                optionsList={modules}
                onSelect={onSelectModule}
                className="select-participant-works"
                loading={loadingEnrollments}
            >
                {({ onSelect, option, index, selectedOption }) =>
                    option?.value === "client-goal" ? (
                        <li
                            key={option.id}
                            role="option"
                            aria-selected={selectedOption == index}
                            className={classNames({
                                "font-italic bg-gray-lighter": option?.disabled,
                                "selected-option": selectedOption === index
                            })}
                            tabIndex={0}
                            onClick={() => {
                                if (!option.disabled) {
                                    onSelect(index)
                                    onSelectCoachingPlan()
                                }
                            }}
                        >
                            {option?.label}
                        </li>
                    ) : (
                        <ModuleItem
                            module={option as UserEnrollModule}
                            className="module-select-item"
                            moduleName={
                                <>
                                    <span className="font-extrabold">{hasCoachingGoal ? index - 1 : index}: </span>
                                    <span
                                        className={classNames({
                                            "font-italic": !option,
                                            "font-weight-light": !option
                                        })}
                                    >
                                        {option?.name?.en ?? "Unassigned"}
                                    </span>
                                </>
                            }
                            isActive={selectedOption === index}
                            isLastItem={index === optionsList.length - 1}
                            isCollapsed
                            itemIndex={index + 1}
                            onClick={() => {
                                onSelect(index)
                                onSelectModuleIndex(index)
                            }}
                            unAssignCount={unassignedModuleCount}
                        />
                    )
                }
            </Select>

            <div className="phase-container">
                {onboardingSelected && (
                    <div className={classNames("phase-item-title", { active: true })} onClick={onSelectPrejourney}>
                        <span className="phase-item-name font-extrabold">Pre-Journey</span>
                    </div>
                )}
                {showCoachingPlan && (
                    <div className={classNames("phase-item-title", { active: true })}>
                        <span className="phase-item-name font-extrabold d-block">
                            {DateTime.now().toFormat(DATE_FORMAT.SHORT_MONTH_DOT_DAY)}
                        </span>
                        <span className="color-gray fs-12 font-italic">Initial Goal & Behaviors</span>
                    </div>
                )}
                <PhasesLinks
                    phases={phasesList}
                    isLoading={loadingPhases}
                    selectedPhase={selectedPhase}
                    // @ts-expect-error unused prop below
                    selectedChapter={selectedChapter}
                    onSelectChapter={onSelectChapter}
                    onSelectPhase={onSelectPhase}
                />
            </div>
        </Sidebar>
    )
}

export default memo(LeftSidebar)
