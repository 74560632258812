import { TrackComponent } from "./chapter-component"

export type TrackPracticeModalData = {
    show: boolean
    component: TrackComponent & { chapterId: number; chapterIndex: number }
    phaseName: string
}

export type Modality = "individual" | "group" | "no_coach"

export enum CohortModalities {
    Individual = "individual",
    Group = "group",
    NoCoach = "no_coach"
}

export enum SelfAssessmentType {
    Diagnostic = "diagnostic",
    ReflectionObjective = "reflection_objective"
}

export type TranslateField = {
    en?: string
    es?: string
}

export const ClassOmit = <T, K extends keyof T>(
    Class: new (any) => T,
    keys: K[]
): new (any) => Omit<T, (typeof keys)[number]> => Class

export enum AttendanceStatuses {
    PRESENT = "present",
    NOT_PRESENT = "not_present"
}

export enum InvitationStatuses {
    ACCEPTED = "accepted",
    TENTATIVE = "tentative",
    DECLINED = "declined",
    PENDING = "pending",
    UNKNOWN = "unknown"
}

export type InvitationStatus = "accepted" | "tentative" | "declined" | "pending" | "unknown"
export type AttendanceStatus = "present" | "not_present" | null
export type LikertColorType = "blank" | "gray" | "colored" | "brand" | "blank-2"

export interface ColorRange {
    from_option: number
    to_option: number
    uuid: string
    color: string
}
