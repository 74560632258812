import { type MutableRefObject, useCallback, useRef, useState } from "react"

import { ReflectionComponentType } from "@/constants"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { ColorRange, LikertColorType } from "@/models/types"
import getColor from "@/utils/get-color"

import type { TEmptyCallback } from "../types/functions"

import LikertRadio from "./LikertRadio"

interface LikertOption {
    uuid: string
    response: {
        en: string
    }
    [key: string]: any
}

type Props = {
    options: LikertOption[]
    colorRange?: ColorRange[]
    likertColorType: LikertColorType
    likertType: ReflectionComponentType.LIKERT_GROUP | ReflectionComponentType.COLORED_LIKERT_GROUP
    register: any
    control: any
    name: any
    className?: string
    showOddLabels?: boolean
}

const handleFieldsetAddChildrenHover: (ref: MutableRefObject<HTMLFieldSetElement>) => void = (
    parentRef: MutableRefObject<HTMLFieldSetElement>
): void => {
    // <LikertRadio /> hover state handle is unreadable and unmanageable. This is written on top of it to cover WISER-3591
    // DON'T REMOVE UNLESS REQUESTED
    const children: HTMLCollection = parentRef?.current?.children

    if (children) {
        for (let i: number = 0; i < children.length; i += 1) {
            children[i].classList.add("unhovered")
        }
    }
}

const handleFieldsetRemoveChildrenHover: (ref: MutableRefObject<HTMLFieldSetElement>) => void = (
    parentRef: MutableRefObject<HTMLFieldSetElement>
): void => {
    // <LikertRadio /> hover state handle is unreadable and unmanageable. This is written on top of it to cover WISER-3591
    // DON'T REMOVE UNLESS REQUESTED
    const children: HTMLCollection = parentRef?.current?.children

    if (children) {
        for (let i: number = 0; i < children.length; i += 1) {
            children[i].classList.remove("unhovered")
        }
    }
}

const LikertRow = ({
    options,
    likertColorType,
    likertType,
    colorRange = [],
    register,
    control,
    name,
    className = "",
    showOddLabels = false
}: Props) => {
    const isDefaultLikert = likertType === ReflectionComponentType.LIKERT_GROUP
    const [hoveredId, setHoveredId] = useState(null)

    const likertColor = (index: number) => {
        if (isDefaultLikert) return ""
        return getColor(index, colorRange)
    }

    const fieldsetRef: MutableRefObject<HTMLFieldSetElement> = useRef<HTMLFieldSetElement>(null)

    const handleMouseEnter: TEmptyCallback = useCallback((): void => handleFieldsetAddChildrenHover(fieldsetRef), [])
    const handleMouseLeave: TEmptyCallback = useCallback((): void => handleFieldsetRemoveChildrenHover(fieldsetRef), [])

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    return (
        <fieldset
            ref={fieldsetRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={`mb-4 level-choice level-choice--${likertColorType} ${className} d-flex justify-content-center`}
        >
            {options?.map((checkbox, index, options) => (
                <LikertRadio
                    name={name}
                    register={register}
                    control={control}
                    label={getValueFromTranslatedObject(checkbox.response)}
                    id={checkbox.uuid}
                    hoveredId={hoveredId}
                    setHovered={setHoveredId}
                    showHover={likertColorType === "blank"}
                    value={checkbox.uuid}
                    isFirstOption={index === 0}
                    key={checkbox.uuid}
                    color={likertColor(index + 1)}
                    showLabel={showOddLabels && options?.length % 2 !== 0 ? (index + 1) % 2 !== 0 : true}
                    isLastOption={index === options.length - 1}
                />
            ))}
        </fieldset>
    )
}

export default LikertRow
