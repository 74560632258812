import {
    type Dispatch,
    type FunctionComponent,
    type MutableRefObject,
    type ReactElement,
    type ReactNode,
    type SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react"

import ReactSwiperCore from "swiper"
import { Navigation as ReactSwiperNavigation, Pagination as ReactSwiperPagination } from "swiper/modules"
import {
    Swiper as ReactSwiper,
    type SwiperProps as ReactSwiperProps,
    SwiperSlide as ReactSwiperSlide,
    type SwiperSlideProps as ReactSwiperSlideProps
} from "swiper/react"
import type { Swiper as ReactSwiperClass } from "swiper/types"

import type { TEmptyCallback } from "@/shared/types/functions"

import { SwiperNavigation, SwiperPagination } from "./components"

import "./styles.scss"

type ISwiperSlideProps = ReactSwiperSlideProps & {
    children?: ReactNode
    slideClassName?: string
}

interface ISwiperProps extends ReactSwiperProps {
    withPagination?: boolean
    withNavigation?: boolean
    withTouchableSlides?: boolean
    slides: ISwiperSlideProps[]
}

interface ISwiperPropsInternal extends Omit<ISwiperProps, "slides"> {
    ref: MutableRefObject<ReactSwiperClass>
}

const Swiper: FunctionComponent<ISwiperProps> = ({
    spaceBetween,
    initialSlide = 0,
    slidesPerView = 1,
    withPagination = false,
    withNavigation = false,
    withTouchableSlides = false,
    slides,
    ...rest
}: ISwiperProps): ReactElement => {
    const prevNavigationButtonRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
    const nextNavigationButtonRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    const paginationRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    const swiperRef: MutableRefObject<ReactSwiperClass> = useRef<ReactSwiperClass>(null)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_swiperKey, setSwiperKey]: [number, Dispatch<SetStateAction<number>>] = useState<number>(0)

    useEffect(
        (): TEmptyCallback => (
            (ReactSwiperCore.use([ReactSwiperNavigation, ReactSwiperPagination]), setSwiperKey(1)),
            (): void => swiperRef.current.update()
        ),
        []
    )

    const swiperProps: ISwiperPropsInternal = {
        ref: swiperRef,
        allowTouchMove: withTouchableSlides,
        observer: true,
        observeParents: true,
        modules: useMemo(
            () => [withNavigation && ReactSwiperNavigation, withPagination && ReactSwiperPagination].filter(Boolean),
            [withNavigation, withPagination]
        ),
        ...(withNavigation && {
            navigation: {
                prevEl: prevNavigationButtonRef.current,
                nextEl: nextNavigationButtonRef.current
            }
        }),
        ...(withPagination && {
            pagination: {
                el: paginationRef.current,
                clickable: true
            }
        }),
        onSwiper: useCallback(
            (swiper: ReactSwiperClass): void => (
                (swiperRef.current = swiper),
                (swiperRef.current.init(), swiperRef.current.update()),
                (withNavigation &&
                    ((swiperRef.current.params.navigation.prevEl = prevNavigationButtonRef.current),
                    (swiperRef.current.params.navigation.nextEl = nextNavigationButtonRef.current)),
                swiperRef.current.navigation.destroy(),
                swiperRef.current.navigation.init(),
                swiperRef.current.navigation.update()),
                withPagination &&
                    ((swiperRef.current.params.pagination.el = document.getElementById("swiper-pagination")),
                    swiperRef.current.pagination.destroy(),
                    swiperRef.current.pagination.init(),
                    swiperRef.current.pagination.update())
            ),
            [withNavigation, withPagination]
        ),
        spaceBetween,
        slidesPerView,
        initialSlide,
        ...rest
    }

    return (
        <div className="swiper__container">
            <div className="swiper__wrapper">
                <ReactSwiper {...(swiperProps as unknown as ISwiperProps)}>
                    {slides?.map(({ children, ...rest }: ISwiperSlideProps, idx: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <ReactSwiperSlide key={`swiper-slide-${idx}`} {...rest}>
                            {children}
                        </ReactSwiperSlide>
                    ))}
                </ReactSwiper>
            </div>
            <SwiperNavigation
                prevRef={prevNavigationButtonRef}
                nextRef={nextNavigationButtonRef}
                isVisible={withNavigation}
            />
            <SwiperPagination paginationRef={paginationRef} isVisible={withPagination} />
        </div>
    )
}

export { Swiper, type ISwiperProps, type ISwiperSlideProps }
