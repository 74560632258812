import { EXTRA_PARTY_TYPES } from "@/constants"
import { AttendanceStatus } from "@/models/types"

export interface ThreeWayManagerApi {
    email: string
    first_name: string
    last_name: string
}

export interface ThreeWayManagerSessionApi {
    attendance_status: AttendanceStatus
    email: string
    first_name: string
    last_name: string
    type: EXTRA_PARTY_TYPES
}

export class ThreeWayManagerSession {
    attendanceStatus: AttendanceStatus

    email: string

    firstName: string

    lastName: string

    type: EXTRA_PARTY_TYPES

    constructor(data: ThreeWayManagerSessionApi) {
        this.attendanceStatus = data.attendance_status
        this.email = data.email
        this.firstName = data.first_name
        this.lastName = data.last_name
        this.type = data.type
    }
}

export interface ThreeWayManagerFormValues {
    email: string
    first_name: string
    last_name: string
}

export class ThreeWayManager {
    managerEmail: string

    managerFirstName: string

    managerLastName: string

    constructor(data: ThreeWayManagerApi) {
        this.managerEmail = data.email
        this.managerFirstName = data.first_name
        this.managerLastName = data.last_name
    }
}
