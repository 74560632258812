import { type FC, type ReactElement } from "react"

import type { TEmptyCallback } from "@/shared/types/functions"

import type { TCoachTableRowData } from "../types"

type TCoachTableRowProps = {
    handleRowClick: TEmptyCallback
    renderRowColumns(data: TCoachTableRowData): ReactElement[]
    rowData: TCoachTableRowData
}

const CoachTableRow: FC<TCoachTableRowProps> = ({
    handleRowClick,
    renderRowColumns,
    rowData
}: TCoachTableRowProps): ReactElement => (
    <tr className="table-row" onClick={handleRowClick as TEmptyCallback}>
        {renderRowColumns?.(rowData)}
    </tr>
)

export { CoachTableRow, type TCoachTableRowProps }
