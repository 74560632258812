import { useTranslation } from "react-i18next"

import ProductTypeLogo from "../ProductTypeLogo"
import Button from "../button/Button"

import "./styles.scss"

type Props = {
    link?: string
}

export const LearnMore = ({ link }: Props) => {
    const { t } = useTranslation()

    return (
        <div className="learn-more">
            <div className="d-flex justify-content-center">
                <ProductTypeLogo />
            </div>
            <p className="font-extrabold mb-0 text-center">{t("Curious about Wiser?")}</p>
            <p className="text-center">
                {t(
                    "We bring transformational development to all levels of organizations through scalable individual and group coaching. Wiser’s developmental coaching is guided by a deeply researched methodology and delivered by the best-trained coaches in the world."
                )}
            </p>
            <div className="text-center">
                <Button>
                    <a href={link} target="_blank" rel="noreferrer">
                        {t("Learn More")}
                    </a>
                </Button>
            </div>
        </div>
    )
}
