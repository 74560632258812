import { FC } from "react"

import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { sanitizeContent } from "@/utils/common"

interface IProps {
    shortSummary?: {
        en: string
    }
    summary?: {
        en: string
    }
}

const SummaryBlock: FC<IProps> = ({ shortSummary, summary }) => {
    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    return (
        <div className="text-center mb-2">
            <h2 className="h5 color-brand text-uppercase font-extrabold">
                {getValueFromTranslatedObject(shortSummary)}
            </h2>
            <div
                className="text-container text-container--sm content-text font-bold"
                dangerouslySetInnerHTML={{ __html: sanitizeContent(getValueFromTranslatedObject(summary)) }}
            />
        </div>
    )
}

export default SummaryBlock
