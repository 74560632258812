import type { FunctionComponent, MutableRefObject, ReactElement } from "react"

import classNames from "classnames"

interface ISwiperNavigationProps {
    prevRef: MutableRefObject<HTMLDivElement>
    nextRef: MutableRefObject<HTMLDivElement>
    isVisible?: boolean
}

const SwiperNavigation: FunctionComponent<ISwiperNavigationProps> = ({
    prevRef,
    nextRef,
    isVisible
}: ISwiperNavigationProps): ReactElement => (
    <div className={classNames({ "d-none": !isVisible })}>
        <div ref={prevRef} className="swiper-btn-prev">
            <i />
        </div>
        <div ref={nextRef} className="swiper-btn-next">
            <i />
        </div>
    </div>
)

export { SwiperNavigation, type ISwiperNavigationProps }
