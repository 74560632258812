import { isNullOrUndefined } from "$/utils/gates"

import type { ICoachAccountsRelinkingConfirmMutationProfileApiData } from "./api"
import type { THandleCoachAccountsConfirmRelinkMutationSuccessFunctionParams } from "./types"

function doesCoachAccountsConfirmRelinkMutationResponseDataAllowToMakeRedirect(
    data: THandleCoachAccountsConfirmRelinkMutationSuccessFunctionParams
): boolean {
    return (
        !isNullOrUndefined(data?.data) &&
        data.data.zoom_profile.connected &&
        data.data.calendar_profiles.every((c: ICoachAccountsRelinkingConfirmMutationProfileApiData) => c.connected)
    )
}

const coachRelinkAccountsUtils = {
    doesCoachAccountsConfirmRelinkMutationResponseDataAllowToMakeRedirect
}

export { coachRelinkAccountsUtils }
