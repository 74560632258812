import { FC, memo } from "react"

import classNames from "classnames"

import { type IUseTranslation, useTranslation } from "@/hooks"
import { type TEmptyCallback, emptyCallback } from "@/shared/types/functions"

interface IProps {
    height?: number
    width?: number
    className?: string
    onClick?: TEmptyCallback
}

const WiserLogo: FC<IProps> = ({ height = 26, width = 34, className = "", onClick = emptyCallback }: IProps) => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <svg
            width={width}
            onClick={onClick}
            height={height}
            className={classNames("cursor-pointer", { [className]: className })}
            viewBox="0 0 289 380"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>{t("Logo")}</title>
            <desc>{t("Orange owl")}</desc>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Wiser-Symbol" transform="translate(-251.000000, -199.000000)">
                    <g id="Group-25-Copy-16" transform="translate(251.000000, 199.000000)">
                        <path
                            d="M190.501187,164.13555 L248.453691,162.282911 C266.568729,161.703806 281.723326,175.919488 282.302432,194.034526 C282.376053,196.337476 282.206931,198.641776 281.797864,200.9093 L274.595727,240.831881 C216.963582,265.826191 183.366719,278.467406 173.80514,278.755525 C169.322412,278.890604 164.814017,278.962353 160.279957,278.970775 C154.315292,278.981854 149.470994,274.155518 149.459916,268.190853 C149.457503,266.892139 149.689354,265.603636 150.144335,264.387224 L186.592995,166.939916 C187.206672,165.29922 188.750373,164.19152 190.501187,164.13555 Z"
                            id="Rectangle-Copy-96"
                            fill="#DA4200"
                            transform="translate(216.885047, 219.979167) scale(-1, 1) translate(-216.885047, -219.979167) "
                        />
                        <path
                            d="M45.731094,164.13555 L103.683598,162.282911 C121.798635,161.703806 136.953233,175.919488 137.532338,194.034526 C137.605959,196.337476 137.436837,198.641776 137.02777,200.9093 L129.825634,240.831881 C72.1934883,265.826191 38.5966259,278.467406 29.0350467,278.755525 C24.5523181,278.890604 20.0439235,278.962353 15.5098632,278.970775 C9.5451982,278.981854 4.70090081,274.155518 4.68982209,268.190853 C4.68740987,266.892139 4.91926095,265.603636 5.37424121,264.387224 L41.8229013,166.939916 C42.4365783,165.29922 43.9802796,164.19152 45.731094,164.13555 Z"
                            id="Rectangle-Copy-97"
                            fill="#DA4200"
                        />
                        <path
                            d="M92.9121495,323.053884 L109.001124,368.497646 C109.79739,370.74672 112.266126,371.924455 114.515201,371.128189 C115.744023,370.693135 116.71069,369.726467 117.145744,368.497646 L123.154077,351.526942 L123.154077,351.526942 L128.194398,337.290413 L130.714558,330.172149 L113.073434,323.053884 L92.9121495,323.053884 Z"
                            id="Path-5-Copy-19"
                            fill="#FEA57F"
                        />
                        <path
                            d="M160.25474,330.172149 L162.774901,337.290413 L167.815222,351.526942 L173.823554,368.497646 C174.61982,370.74672 177.088556,371.924455 179.337631,371.128189 C180.566453,370.693135 181.53312,369.726467 181.968174,368.497646 L198.057149,323.053884 L198.057149,323.053884 L177.895864,323.053884 L160.25474,330.172149 Z"
                            id="Path-5-Copy-23"
                            fill="#FEA57F"
                        />
                        <path
                            d="M35.7066027,29.3769932 L111.074531,57.9543741 C132.784131,66.1860384 156.756056,66.1860384 178.465655,57.9543741 L254.205998,29.2357844 C256.994605,28.1784239 260.112377,29.5818761 261.169738,32.3704827 C261.652442,33.6435312 261.636448,35.051933 261.124959,36.3136916 L257.075614,46.3027599 C252.11614,58.5369678 249.566355,71.6139294 249.566355,84.8151502 L249.566355,104.803434 L249.566355,184.756569 L249.566355,184.756569 L249.566355,240.406765 C249.566355,297.985805 202.889321,344.66284 145.31028,344.66284 C87.7312402,344.66284 41.0542056,297.985805 41.0542056,240.406765 L41.0542056,184.756569 L41.0542056,184.756569 L41.0542056,104.803434 L41.0542056,84.8151502 C41.0542056,71.6293192 38.3543919,58.5831198 33.1210903,46.4802817 L28.8356007,36.5694087 C27.6519459,33.8320196 28.9114964,30.6533855 31.6488855,29.4697307 C32.9379377,28.9123408 34.3934319,28.8790761 35.7066027,29.3769932 Z"
                            id="Rectangle-Copy-34"
                            fill="#FE4D00"
                        />
                        <path
                            d="M144.5,46.9994781 C150.982243,46.9994781 156.519159,44.7035266 161.110748,40.1116236 C165.702336,35.5197205 167.998131,29.9824257 167.998131,23.4997391 C167.998131,17.0170524 165.702336,11.4797576 161.110748,6.88785455 C156.519159,2.29595152 150.982243,0 144.5,0 C138.017757,0 132.480841,2.29595152 127.889252,6.88785455 C123.297664,11.4797576 121.001869,17.0170524 121.001869,23.4997391 C121.001869,29.9824257 123.297664,35.5197205 127.889252,40.1116236 C132.480841,44.7035266 138.017757,46.9994781 144.5,46.9994781 Z"
                            id="Path-Copy-50"
                            fill="#FE4D00"
                            fillRule="nonzero"
                        />
                        <path
                            d="M143.153562,208.52642 L147.466998,208.52642 C179.987956,208.52642 206.351402,234.889866 206.351402,267.410824 C206.351402,299.931782 179.987956,326.295227 147.466998,326.295227 L143.153562,326.295227 C110.632604,326.295227 84.2691589,299.931782 84.2691589,267.410824 C84.2691589,234.889866 110.632604,208.52642 143.153562,208.52642 Z"
                            id="Rectangle-Copy-35"
                            stroke="#FEA57F"
                            strokeWidth="8.64"
                            fill="#FEA57F"
                        />
                        <ellipse
                            id="Oval-Copy-131"
                            fill="#FFDCCE"
                            cx="99.9345794"
                            cy="131.274404"
                            rx="35.1121495"
                            ry="35.1145526"
                        />
                        <ellipse
                            id="Oval-Copy-132"
                            fill="#FFDCCE"
                            cx="189.285171"
                            cy="131.274404"
                            rx="35.1121495"
                            ry="35.1145526"
                        />
                        <ellipse
                            id="Oval-Copy-133"
                            fill="#071C41"
                            opacity="0.9"
                            cx="189.605607"
                            cy="131.274404"
                            rx="17.8261682"
                            ry="17.8273883"
                        />
                        <ellipse
                            id="Oval-Copy-134"
                            fill="#071C41"
                            opacity="0.9"
                            cx="99.9345794"
                            cy="131.274404"
                            rx="17.8261682"
                            ry="17.8273883"
                        />
                        <ellipse
                            id="Oval-Copy-135"
                            fill="#FFDCCE"
                            cx="87.5102804"
                            cy="121.010151"
                            rx="5.40186916"
                            ry="5.40223886"
                        />
                        <ellipse
                            id="Oval-Copy-136"
                            fill="#FFDCCE"
                            cx="177.181308"
                            cy="121.010151"
                            rx="5.40186916"
                            ry="5.40223886"
                        />
                        <path
                            d="M136.066042,156.630759 C136.09533,156.584706 136.124713,156.538054 136.154194,156.490806 L136.154168,156.49079 C139.183637,151.635402 145.575576,150.155203 150.430963,153.184671 C151.817538,154.04981 152.979817,155.230652 153.822881,156.630759 C153.822881,156.630759 153.822881,156.630759 153.822881,156.630759 C155.340997,159.151952 157.719882,161.629892 160.959536,164.06458 C161.817823,164.709603 161.990704,165.928275 161.34568,166.78656 C161.28513,166.86713 161.218405,166.942869 161.146111,167.013092 L158.51907,169.564852 C155.666407,172.33577 153.183856,175.46378 151.133115,178.871144 L147.987925,184.096964 C146.991376,185.752759 144.841226,186.287183 143.185431,185.290633 C142.696089,184.99612 142.286275,184.586305 141.991762,184.096964 L138.846572,178.871144 C136.795831,175.46378 134.313279,172.33577 131.460617,169.564852 L128.897185,167.074878 C128.127079,166.326805 128.109189,165.096078 128.857244,164.325955 C128.935008,164.245897 129.019521,164.172684 129.109849,164.107129 C131.835701,162.128832 134.154432,159.636709 136.066042,156.630759 Z"
                            id="Rectangle-Copy-36"
                            fill="#FFDCCE"
                        />
                        <g
                            id="Group-Copy-22"
                            transform="translate(145.159028, 286.102570) scale(1, -1) translate(-145.159028, -286.102570) translate(111.278505, 278.323346)"
                            stroke="#FE4D00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="8.64"
                        >
                            <path
                                d="M0,15.5584479 L14.6025192,2.14705328 C15.9245321,0.932876733 17.9559913,0.932876733 19.2780041,2.14705328 L31.5427809,13.4113946 C32.8647938,14.6255712 34.896253,14.6255712 36.2182658,13.4113946 L48.4830426,2.14705328 C49.8050555,0.932876733 51.8365146,0.932876733 53.1585275,2.14705328 L67.7610467,15.5584479 L67.7610467,15.5584479"
                                id="Path-3"
                            />
                        </g>
                        <g
                            id="Group-Copy-23"
                            transform="translate(145.159028, 256.930480) scale(1, -1) translate(-145.159028, -256.930480) translate(111.278505, 249.151256)"
                            stroke="#FE4D00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="8.64"
                        >
                            <path
                                d="M0,15.5584479 L14.6025192,2.14705328 C15.9245321,0.932876733 17.9559913,0.932876733 19.2780041,2.14705328 L31.5427809,13.4113946 C32.8647938,14.6255712 34.896253,14.6255712 36.2182658,13.4113946 L48.4830426,2.14705328 C49.8050555,0.932876733 51.8365146,0.932876733 53.1585275,2.14705328 L67.7610467,15.5584479 L67.7610467,15.5584479"
                                id="Path-3"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default memo(WiserLogo)
