import { type MutableRefObject, useCallback, useRef, useState } from "react"

import { ReflectionComponentType } from "@/constants"
import { PairingQuestionAnswerOption } from "@/entities/pairing-question"
import { SurveyQuestionPreviosAnswer } from "@/entities/survey/models"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { ColorRange, LikertColorType } from "@/models/types"
import type { TEmptyCallback } from "@/shared/types/functions"
import getColor from "@/utils/get-color"

import LikertRadio from "../LikertRadio"

type Props = {
    options: PairingQuestionAnswerOption[]
    colorRange?: ColorRange[]
    likertColorType: LikertColorType
    likertType: ReflectionComponentType.LIKERT_GROUP | ReflectionComponentType.COLORED_LIKERT_GROUP
    register: any
    control: any
    name: any
    className?: string
    previosAnswer?: SurveyQuestionPreviosAnswer
    showOddLabels?: boolean
}

const handleFieldsetAddChildrenHover: (ref: MutableRefObject<HTMLFieldSetElement>) => void = (
    parentRef: MutableRefObject<HTMLFieldSetElement>
): void => {
    // <LikertRadio /> hover state handle is unreadable and unmanageable. This is written on top of it to cover WISER-3591
    // DON'T REMOVE UNLESS REQUESTED
    const children: NodeListOf<ChildNode> = parentRef?.current?.children?.[0]?.childNodes

    if (children) {
        for (let i: number = 0; i < children.length; i += 1) {
            // @ts-expect-error types mess here
            children[i].classList.add("unhovered")
        }
    }
}

const handleFieldsetRemoveChildrenHover: (ref: MutableRefObject<HTMLFieldSetElement>) => void = (
    parentRef: MutableRefObject<HTMLFieldSetElement>
): void => {
    // <LikertRadio /> hover state handle is unreadable and unmanageable. This is written on top of it to cover WISER-3591
    // DON'T REMOVE UNLESS REQUESTED
    const children: NodeListOf<ChildNode> = parentRef?.current?.children?.[0]?.childNodes

    if (children) {
        for (let i: number = 0; i < children.length; i += 1) {
            // @ts-expect-error types mess here
            children[i].classList.remove("unhovered")
        }
    }
}

export const LikertRow = ({
    options,
    likertColorType,
    likertType,
    colorRange = [],
    register,
    control,
    previosAnswer,
    name,
    className = "",
    showOddLabels = false
}: Props) => {
    const isDefaultLikert = likertType === ReflectionComponentType.LIKERT_GROUP
    const [hoveredId, setHoveredId] = useState<string | null>(null)
    const likertColor = (index: number) => {
        if (isDefaultLikert) return ""
        return getColor(index, colorRange)
    }

    const fieldsetRef: MutableRefObject<HTMLFieldSetElement> = useRef<HTMLFieldSetElement>(null)

    const handleMouseEnter: TEmptyCallback = useCallback((): void => handleFieldsetAddChildrenHover(fieldsetRef), [])
    const handleMouseLeave: TEmptyCallback = useCallback((): void => handleFieldsetRemoveChildrenHover(fieldsetRef), [])

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    return (
        <fieldset
            data-testid="likert-row"
            ref={fieldsetRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                className={`mb-4 level-choice level-choice--${likertColorType} ${className} d-flex justify-content-center`}
            >
                {options?.map((checkbox, index, options) => (
                    <LikertRadio
                        name={name}
                        previousAnswer={previosAnswer?.[index + 1] ?? ""}
                        register={register}
                        control={control}
                        hoveredId={hoveredId}
                        setHovered={setHoveredId}
                        showHover
                        label={getValueFromTranslatedObject(checkbox.optionText)}
                        id={checkbox.uuid}
                        value={`${index + 1}`}
                        isFirstOption={index === 0}
                        key={checkbox.uuid}
                        color={likertColor(index + 1) || checkbox?.color}
                        colored={!!checkbox?.color}
                        showLabel={showOddLabels && options?.length % 2 !== 0 ? (index + 1) % 2 !== 0 : true}
                        isLastOption={index === options.length - 1}
                    />
                ))}
            </div>
            <legend className="d-none" />
        </fieldset>
    )
}
