import { FC, useState } from "react"

import { AxiosError } from "axios"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { HTTPStatus } from "$/constants"
import { getErrorMessages } from "$/utils/get-error-messages"

import useSpotCoaching from "@/api/use-spot-coaching"
import Button from "@/shared/button/Button"
import useNavigationOnboarding from "@/utils/hooks/use-navigation-onboarding"

import useNextStep from "./api/mutations/use-next-step"
import ModulesCategory from "./components/ModulesCategory"
import { StepsNavigator } from "./components/StepsNavigator"
import { WarningMessage } from "./components/WarningMessage"
import { OnboardingSteps } from "./constants"
import OnboardingLayout from "./layout/OnboardingLayout"
import { useOnboardingStoreContext } from "./onboardingStore"

interface IProps {}

const ModuleListStep: FC<IProps> = () => {
    const { t } = useTranslation()
    const { storeData } = useOnboardingStoreContext()
    const { getNextStepUrl } = useNavigationOnboarding()
    const handleNextStep = useNextStep()
    const [isBusy, setIsBusy] = useState(false)
    const navigate = useNavigate()
    const { data: productType } = useSpotCoaching()
    const [errorsMsg, setErrorsMsg] = useState(null)
    const journeyLength: number = storeData?.currenUser?.journey_length || null

    const handleNext = () => {
        setIsBusy(true)
        handleNextStep.mutate(
            { step: OnboardingSteps.MODULE_LIST_STEP },
            {
                async onSuccess() {
                    const nextStep = await getNextStepUrl(OnboardingSteps.MODULE_LIST_STEP)
                    navigate(nextStep.url)
                },
                onError(error: AxiosError) {
                    const errorMessage = getErrorMessages(error)
                    if (error?.response?.status === HTTPStatus.BAD_REQUEST) {
                        setErrorsMsg(errorMessage)
                    }
                    console.log(errorMessage)
                },
                onSettled() {
                    setIsBusy(false)
                }
            }
        )
    }

    return (
        <OnboardingLayout header={<StepsNavigator activeStep={{ id: OnboardingSteps.MODULE_LIST_STEP, order: 2 }} />}>
            <div className="container-md text-center">
                <WarningMessage message={errorsMsg} />
                <div className="mb-180 mb-sm-7 mt-6 ">
                    <h1 className="h1 font-extrabold">
                        <span className="color-brand">{t("Next Step")}:</span>{" "}
                        {t("Complete Your Pre-Program Diagnostic")}
                    </h1>
                    <p className="color-gray">
                        {t(
                            "You will have the opportunity to choose {{journeyLength}} learning modules during your {{title}} journey.",
                            { journeyLength, title: productType?.titleData }
                        )}
                        <br />
                        {t('Click "Start Diagnostic" below to assess your key strength areas and areas to improve.')}
                    </p>
                    <div className="mt-5 mb-3">
                        <Button onClick={handleNext} isBusy={isBusy}>
                            {t("Start Diagnostic")}
                        </Button>
                    </div>
                    <p className="font-italic font-sm color-gray">
                        {t("This shouldn’t take more than 10 minutes max.")}
                    </p>
                </div>
                <div className="accordion-background accordion-mob">
                    <h2 className="h2 h2-mob font-extrabold mb-3">
                        {t("If you’d like, you can browse the available learning modules here")}:
                    </h2>
                    <ModulesCategory />
                </div>
            </div>
        </OnboardingLayout>
    )
}

export default ModuleListStep
