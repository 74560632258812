import { useLayoutEffect } from "react"

import { Link, NavigateFunction, useNavigate } from "react-router-dom"

import { INDEX_URL } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"

import { ErrorPage } from "./ErrorPage"

export const ForbiddenComponent = () => {
    const { user }: IAuthContext = useAuthContext()

    const navigate: NavigateFunction = useNavigate()

    useLayoutEffect(() => {
        !!user && navigate(INDEX_URL)
    }, [user, navigate])

    return (
        <ErrorPage
            errorContent={
                <div className="text-center">
                    <span className="font-extrabold color-gray">Access Denied.</span>{" "}
                    <br className="d-block d-md-none" />
                    <Link to="/" className="color-brand font-bold">
                        Back to dashboard
                    </Link>
                </div>
            }
        />
    )
}
