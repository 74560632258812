import { FC } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { i18n } from "$/i18n"
import { isNullOrUndefined } from "$/utils/gates"

import useSpotCoaching from "@/api/use-spot-coaching"
import { CARE_TEAM_EMAIL } from "@/constants"

interface IProps {
    className?: string
    text?: string
    variant?: "default" | "brand"
}

const CareTeamLink: FC<IProps> = ({ className, text = i18n.t("Questions?"), variant = "default" }) => {
    const { t } = useTranslation()
    const { data: productType } = useSpotCoaching()

    const productTypeData = isNullOrUndefined(productType)
        ? {
              supportEmail: CARE_TEAM_EMAIL.split(":")[1],
              supportTeamName: "Wiser Care"
          }
        : productType

    return (
        <div
            className={classNames("color-gray font-italic mt-50", {
                [className]: className,
                "care-team-brank-link": variant === "brand"
            })}
        >
            <span>{text} </span>
            <a href={`mailto:${productTypeData?.supportEmail}`} className="text-underline color-inherit">
                {t("Contact")} {productTypeData?.supportTeamName}
            </a>
        </div>
    )
}

export default CareTeamLink
