import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import { ServerStateKeys } from "@/constants"
import { GroupSession, GroupSessionApi } from "@/models/session"

import Urls from "./urls"

export const getAssignedModules = async (id: number) => {
    const { data } = await http.get(Urls.assignedModuleDetails(id))
    return data
}

export default function useGetAssignedModules(id: number) {
    return useQuery<GroupSessionApi, Error, GroupSession>(
        [ServerStateKeys.AssignedModules, id],
        () => getAssignedModules(id),
        {
            enabled: !!id,
            select: useCallback(data => new GroupSession(data), [])
        }
    )
}
