import { ReactNode } from "react"

import classNames from "classnames"

import { i18n } from "$/i18n"

import { WarningMessage } from "@/components/onboarding/components/WarningMessage"

import TextArea from "./TextArea"

import "./styles.scss"

interface IProps {
    register: any
    onFocus?: () => void
    placeholder?: string
    name: string
    onBlur?: () => void
    className?: string
    hasFocus?: boolean
    error?: any
    rows?: number
    headerText: ReactNode
}

const TextAreaOffboarding = ({
    register,
    onBlur,
    onFocus,
    placeholder = i18n.t("Start typing..."),
    name,
    className = "",
    hasFocus,
    rows = 4,
    headerText,
    error
}: IProps) => {
    return (
        <div className={classNames("textarea-offboarding", { [className]: className })}>
            <div className="textarea-offboarding__header">{headerText}</div>
            <WarningMessage message={error} />
            <div className="textarea-offboarding__body">
                <TextArea
                    register={register}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    rows={rows}
                    placeholder={placeholder}
                    name={name}
                    className="blank-textarea w-100"
                    hasFocus={hasFocus}
                />
            </div>
        </div>
    )
}

export default TextAreaOffboarding
