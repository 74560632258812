export const ServerStateKeys = {
    RespondentSurvey: "respondentSurvey"
} as const

export const SURVEY_STAGE = {
    BASELINE: 1,
    INTERIM: 2,
    FINAL: 3
} as const

export const SURVEY_STATUS = {
    NOT_STARTED: "Survey hasn't started yet.",
    EXPIRED: "Survey time has expired.",
    COMPLETED: "Survey was completed.",
    INVALID: "Survey code is invalid."
} as const
