import { useCallback } from "react"

import { Draggable } from "react-beautiful-dnd"

import DragDropWrapper from "@/shared/drag-n-drop/DragDropWrapper"
import { GoalsSortType } from "@/shared/types/sort"

import { BehavioralChange } from "../model/behavioral-goal"

import { BehavioralGoalCard } from "./BehavioralGoalCard"

type Props = {
    archivedBehavioral: number[]
    goals: BehavioralChange[]
    sort: GoalsSortType
    onBlurBehavioralChange: (index: number) => void
    onArchiveClick: (index: number) => void
    onDragEnd: (result: any) => void
    setHasArchivedPractices: () => void
    showInitialProjectedDeleteModal: (value: string, index: number) => void
}

export const BehavioralList = ({
    archivedBehavioral,
    goals,
    sort,
    onBlurBehavioralChange,
    onArchiveClick,
    onDragEnd,
    setHasArchivedPractices,
    showInitialProjectedDeleteModal
}: Props) => {
    const isArchived = useCallback(
        (id: number) => {
            return archivedBehavioral?.includes(id)
        },
        [archivedBehavioral]
    )

    return (
        <DragDropWrapper onDragEnd={onDragEnd} droppableId="droppable" type="behavior-goal-drag">
            {goals.map((goal, index) => (
                <Draggable key={`item-${goal.id}`} draggableId={`item-${goal.id}`} index={index}>
                    {(provided, snapshot) => {
                        const isArchivedChange = isArchived(goal.id)

                        return (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                <BehavioralGoalCard
                                    setHasArchivedPractices={setHasArchivedPractices}
                                    showInitialProjectedDeleteModal={showInitialProjectedDeleteModal}
                                    showDrag={goals.length > 1 && !isArchivedChange}
                                    isArchived={isArchivedChange}
                                    onBlurBehavioralChange={onBlurBehavioralChange}
                                    index={index}
                                    snapshot={snapshot}
                                    sort={sort}
                                    onArchive={onArchiveClick}
                                    provider={provided}
                                />
                            </div>
                        )
                    }}
                </Draggable>
            ))}
        </DragDropWrapper>
    )
}
