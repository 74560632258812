import { FC, ReactNode, useRef } from "react"

import classNames from "classnames"

import "./styles.scss"

interface IProps {
    active: boolean
    className?: string
    withTransition?: boolean
    children: ReactNode
}

const Accordion: FC<IProps> = ({ active, className = "", withTransition = true, children }) => {
    const accordionRef = useRef<HTMLDivElement>()

    return (
        <div
            className={classNames("accordion-wrapper", className, { "no-transition": !withTransition })}
            style={active ? { height: `${accordionRef?.current?.scrollHeight}px` } : { height: "0px" }}
            ref={accordionRef}
            aria-expanded={active}
        >
            {children}
        </div>
    )
}

export default Accordion
