import { useEffect, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import Heading from "$/components/Heading/Heading"
import Skeleton from "$/components/Skeleton/Skeleton"
import useUpdateEffect from "$/hooks/use-update-effect"
import { Option } from "$/models/option"
import { isEmptyString, isNullOrUndefined } from "$/utils/gates"
import { getErrorMessages } from "$/utils/get-error-messages"

import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { useAuthContext } from "@/context"
import { ArchiveModal, isEnabledArchiveModal } from "@/entities/archive-goal"
import { BehavioralChange, BehavioralList, BehavioralQueryKey, useBehavioralApi } from "@/entities/behavioral-goal"
import { BehavioralDeleteModal } from "@/entities/behavioral-goal/ui/BehavioralDeleteModal"
import { GoalsSortSelector } from "@/features/goals-sort-selector/ui/GoalsSortSelector"
import { StorageService } from "@/services"
import PlusIcon from "@/shared/assets/icon/PlusIcon"
import Button from "@/shared/button/Button"
import { GoalsSortType } from "@/shared/types/sort"

const exampleGoal: BehavioralChange = {
    description: "",
    order: 1,
    projectedImpact: "",
    additionalNotes: "",
    title: "",
    practices: [],
    id: 0,
    participantId: 0
}

type FormValues = {
    goals: BehavioralChange[]
}

const storageService: StorageService = new StorageService()

export const BehavioralGoals = () => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const { user } = useAuthContext()
    const params = useParams()
    const userId = !isNullOrUndefined(params?.id) ? +params?.id : user?.id

    const [sort, setSort] = useState<GoalsSortType>("active")
    const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false)
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
    const [archiveText, setArchiveText] = useState({ text: "", id: null })
    const [errorMsg, setErrorMsg] = useState(null)
    const [hasArchived, setHasArchived] = useState(false)
    const [initialProjected, setInitialProjected] = useState({ index: null, text: "" })

    const {
        behavioralChanges,
        isLoadingBehavioralChanges,
        behavioralError,
        archivedBehavioralChanges,
        archiveBehavioralChange,
        archiveError,
        createBehavioralChange,
        editBehavioralChange,
        sortBehavioralChange,
        unarchiveBehavioralChange
    } = useBehavioralApi({ userId, sort })

    const methods = useForm<FormValues>({
        defaultValues: {
            goals: behavioralChanges ?? []
        }
    })

    const { prepend, move, replace, remove, fields } = useFieldArray({
        control: methods.control,
        name: "goals",
        keyName: "uuid"
    })

    useEffect(() => {
        if (!isLoadingBehavioralChanges && behavioralChanges) {
            replace(behavioralChanges)
        }
    }, [behavioralChanges, isLoadingBehavioralChanges])

    useUpdateEffect(() => {
        if (archivedBehavioralChanges?.length > 0) {
            setHasArchived(true)
        } else {
            setHasArchived(false)
        }
    }, [archivedBehavioralChanges])

    useUpdateEffect(() => {
        if (sort === "archived" && !hasArchived) {
            setSort("active")
        }
    }, [hasArchived, sort])

    const addBehavioralGoal = () => {
        const hasEmptyTitle = methods.getValues("goals").some(goal => isEmptyString(goal.title))
        const hasEmptyAddedGoal = hasEmptyTitle && fields.length > 0

        if (hasEmptyAddedGoal) {
            return
        }

        prepend({ ...exampleGoal, participantId: userId, id: Date.now() }, { shouldFocus: true })
    }

    const onChangeSort = (sort: Option) => {
        setSort(sort.value as GoalsSortType)
    }

    const onArchiveCardClick = async (index: number) => {
        const { id, title, description } = methods.getValues(`goals.${index}`)
        const isArchived = archivedBehavioralChanges.includes(id)

        if (isArchived) {
            try {
                await unarchiveBehavioralChange.mutateAsync(id)
                invalidateQueries()
                queryClient.invalidateQueries([
                    BehavioralQueryKey.BehavioralPractice,
                    { behavioralChangeId: id, participantId: userId }
                ])
            } catch (error) {
                setErrorMsg(getErrorMessages(error))
            }
            return
        }

        setArchiveText({ text: `${title}: ${description}`, id })

        if (isEnabledArchiveModal(storageService)) {
            setIsOpenArchiveModal(true)
        } else {
            onArchiveClick(id)
        }
    }

    const onArchiveClick = async (id?: number) => {
        setIsOpenArchiveModal(false)
        try {
            await archiveBehavioralChange.mutateAsync(id ?? archiveText.id)
            invalidateQueries()
            queryClient.invalidateQueries([
                BehavioralQueryKey.BehavioralPractice,
                { behavioralChangeId: id ?? archiveText.id, participantId: userId }
            ])
        } catch (error) {
            setErrorMsg(getErrorMessages(error))
        }
        setArchiveText({ text: "", id: null })
    }

    const onCloseArchiveModal = () => {
        setIsOpenArchiveModal(false)
        setArchiveText({ text: "", id: null })
    }

    const onDragEnd = async result => {
        const { source, destination, type } = result
        if (!destination) return
        if (type === "behavior-goal-drag") {
            move(source.index, destination.index)
            const ids = methods.getValues("goals").map(goal => goal.id)

            try {
                await sortBehavioralChange.mutateAsync({ ids })
                invalidateQueries()
            } catch (error) {
                setErrorMsg(getErrorMessages(error))
            }
        }
    }

    const onBlurBehavioralChange = async (index: number) => {
        const goals = methods.getValues("goals")
        const { title, description, order, projectedImpact, additionalNotes, id } = methods.getValues(`goals.${index}`)
        const noValues = [title, description, projectedImpact, additionalNotes].every(value => isEmptyString(value))

        if (noValues) {
            remove(index)
            return
        }

        const goal = {
            description,
            order,
            title,
            projected_impact: projectedImpact,
            additional_notes: additionalNotes
        }

        const isEditBehavioral = goals.length === behavioralChanges.length

        try {
            if (isEditBehavioral) {
                await editBehavioralChange.mutateAsync({ ...goal, id })
                invalidateQueries()
                return
            }
            const { data } = await createBehavioralChange.mutateAsync(goal)
            makeSortBehavioralChanges({ oldId: id, newId: data.id })
        } catch (error) {
            setErrorMsg(getErrorMessages(error))
        }
    }

    const makeSortBehavioralChanges = async ({ oldId, newId }: { oldId: number; newId: number }) => {
        const goals = methods.getValues("goals")
        const ids = goals.map(goal => (goal.id === oldId ? newId : goal.id))

        try {
            await sortBehavioralChange.mutateAsync({ ids })
            invalidateQueries()
        } catch (error) {
            setErrorMsg(getErrorMessages(error))
        }
    }

    const invalidateQueries = () => {
        queryClient.invalidateQueries([BehavioralQueryKey.BehavioralChange])
    }

    const setHasArchivePractices = () => {
        setHasArchived(true)
    }

    const showDeleteModal = (initialText: string, index: number) => {
        setInitialProjected({ index, text: initialText })
        setIsOpenDeleteModal(true)
    }

    const onCloseBehavioralDeleteModal = () => {
        setIsOpenDeleteModal(false)
        methods.setValue(`goals.${initialProjected.index}.projectedImpact`, initialProjected.text)
        invalidateQueries()
        replace(behavioralChanges)
    }

    const onDeleteEntry = () => {
        setInitialProjected({ index: null, text: "" })
        setIsOpenDeleteModal(false)
        onBlurBehavioralChange(initialProjected.index)
    }

    if (isLoadingBehavioralChanges) {
        return (
            <div className="mb-2">
                <Skeleton rows={1} height={50} width="200px" className="mb-1" />
                <Skeleton rows={3} height={70} />
            </div>
        )
    }

    if (behavioralError || archiveError) {
        const error = behavioralError ?? archiveError
        const msg = getErrorMessages(error?.message)?.join() ?? t("An error occurred, please try again.")
        return <WarningMessage message={msg} />
    }

    return (
        <FormProvider {...methods}>
            <section className="mb-3">
                <div className="mb-10 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                    <div className="d-flex flex-column flex-row-md align-items-md-center flex-md-row">
                        <Heading tag="h2" fontSize={16} className="mb-3 m-0 mb-md-0 mr-4">
                            {t("Behavioral Changes")}
                        </Heading>
                        <div>
                            <Button variant="outline-rect" onClick={addBehavioralGoal}>
                                <PlusIcon />
                                <span className="d-inline-block ml-1">{t("Add Behavioral Change")}</span>
                            </Button>
                        </div>
                    </div>
                    {hasArchived && (
                        <GoalsSortSelector
                            className="align-self-md-end mt-3 m-md-0"
                            sort={sort}
                            onChangeSelect={onChangeSort}
                        />
                    )}
                </div>
                {fields?.length === 0 ? (
                    <p className="m-0 color-gray font-italic font-sm">
                        {t("What concrete behavioral changes could support progress on your goal?")}
                    </p>
                ) : null}
            </section>
            <WarningMessage message={errorMsg} />
            <BehavioralList
                goals={fields}
                archivedBehavioral={archivedBehavioralChanges}
                sort={sort}
                onDragEnd={onDragEnd}
                onBlurBehavioralChange={onBlurBehavioralChange}
                onArchiveClick={onArchiveCardClick}
                setHasArchivedPractices={setHasArchivePractices}
                showInitialProjectedDeleteModal={showDeleteModal}
            />

            <BehavioralDeleteModal
                isOpen={isOpenDeleteModal}
                initialText={initialProjected.text}
                onClose={onCloseBehavioralDeleteModal}
                onSubmit={onDeleteEntry}
            />

            <ArchiveModal
                archiveTitle={t("Behavioral Change")}
                archiveText={archiveText.text}
                isOpen={isOpenArchiveModal}
                onClose={onCloseArchiveModal}
                onArchive={onArchiveClick}
            />
        </FormProvider>
    )
}
