import { useMemo } from "react"

import { COLORS } from "@/constants"

type Props = {
    isCurrentSelected: boolean
    hasSelectedAnother: boolean
}

const StarRatingSvg = ({ isCurrentSelected = false, hasSelectedAnother }: Props) => {
    const fillColor = useMemo(() => {
        if (hasSelectedAnother && !isCurrentSelected) {
            return COLORS.BRAND_SECONDARY
        }

        if (isCurrentSelected) {
            return COLORS.BRAND_SECONDARY_LIGHT
        }

        return ""
    }, [isCurrentSelected, hasSelectedAnother])

    return (
        <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.2577 1.09115C17.0227 -0.266138 18.9773 -0.26614 19.7423 1.09115L23.8986 8.4652C24.184 8.97155 24.6756 9.3287 25.2454 9.44366L33.5429 11.1179C35.0701 11.426 35.6741 13.2849 34.6197 14.4319L28.8909 20.6635C28.4975 21.0914 28.3098 21.6693 28.3765 22.2467L29.3483 30.6554C29.5272 32.2032 27.9459 33.352 26.5292 32.7036L18.8323 29.1809C18.3038 28.939 17.6962 28.939 17.1677 29.1809L9.47078 32.7036C8.05407 33.352 6.4728 32.2032 6.65168 30.6554L7.6235 22.2467C7.69023 21.6693 7.50247 21.0914 7.10909 20.6635L1.38033 14.4319C0.325876 13.2849 0.929864 11.426 2.45713 11.1179L10.7546 9.44366C11.3244 9.3287 11.816 8.97156 12.1014 8.4652L16.2577 1.09115Z"
                fill={fillColor}
            />
            <path
                d="M16.6933 1.33666C17.267 0.318692 18.733 0.318692 19.3067 1.33666L23.463 8.71071C23.8198 9.34365 24.4343 9.79008 25.1465 9.93378L33.444 11.608C34.5894 11.8391 35.0424 13.2333 34.2516 14.0935L28.5228 20.3251C28.0311 20.86 27.7964 21.5823 27.8798 22.3041L28.8516 30.7129C28.9858 31.8737 27.7998 32.7353 26.7373 32.249L19.0404 28.7263C18.3798 28.4239 17.6202 28.4239 16.9596 28.7263L9.2627 32.249C8.20016 32.7353 7.01421 31.8737 7.14837 30.7129L8.12019 22.3041C8.20361 21.5823 7.96891 20.86 7.47719 20.3251L1.74842 14.0935C0.957581 13.2333 1.41057 11.8391 2.55602 11.608L10.8535 9.93378C11.5657 9.79008 12.1802 9.34365 12.537 8.71071L16.6933 1.33666Z"
                stroke="black"
                strokeOpacity={isCurrentSelected || hasSelectedAnother ? "0" : ""}
            />
        </svg>
    )
}

export default StarRatingSvg
