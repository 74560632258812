import { FC } from "react"

interface IProps {
    heigth?: number
    width?: number
    className?: string
}

const Trash: FC<IProps> = ({ heigth = 19, width = 19, className = "" }) => {
    return (
        <svg
            width={width}
            height={heigth}
            className={className}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.3023 3.72094H15.1953L14.574 1.23257C14.486 0.87995 14.2825 0.566945 13.9959 0.34354C13.7092 0.120134 13.3559 -0.000796058 12.9926 3.94384e-06H7.00561C6.64202 -0.000795481 6.28876 0.12028 6.00206 0.343893C5.71544 0.567522 5.51194 0.880676 5.42422 1.2335L4.80467 3.72094H0.697699C0.448435 3.72094 0.218128 3.85394 0.0934771 4.06978C-0.031159 4.28563 -0.031159 4.55161 0.0934771 4.76746C0.218113 4.98331 0.448416 5.1163 0.697699 5.1163H1.86049V16.5117C1.86151 17.4365 2.22931 18.3232 2.88331 18.9772C3.5373 19.6312 4.424 19.999 5.3488 20H14.6511C15.5759 19.9988 16.4624 19.6309 17.1164 18.9769C17.7704 18.323 18.1383 17.4364 18.1395 16.5116V5.1162H19.3023C19.5516 5.1162 19.7819 4.98321 19.9065 4.76737C20.0312 4.55151 20.0312 4.28554 19.9065 4.06969C19.7819 3.85384 19.5516 3.72085 19.3023 3.72085V3.72094ZM6.77953 1.57117C6.80562 1.46761 6.89886 1.39515 7.00561 1.39537H12.9944C13.1012 1.39515 13.1944 1.46761 13.2205 1.57117L13.7573 3.72094H6.24197L6.77953 1.57117ZM16.7442 16.5117C16.7434 17.0665 16.5227 17.5985 16.1303 17.9908C15.738 18.3832 15.206 18.604 14.6511 18.6047H5.34884C4.7939 18.6042 4.26177 18.3835 3.8694 17.9911C3.47695 17.5987 3.25632 17.0666 3.25582 16.5117V5.1163H16.7442V16.5117Z"
                fill="#666666"
            />
            <path
                d="M7.20939 8.37207C7.02436 8.37207 6.8469 8.44554 6.71607 8.57642C6.58518 8.70724 6.51172 8.8847 6.51172 9.06975V14.6512C6.51172 14.9004 6.64471 15.1307 6.86056 15.2554C7.07641 15.38 7.34238 15.38 7.55823 15.2554C7.77408 15.1307 7.90707 14.9004 7.90707 14.6512V9.06975C7.90707 8.88472 7.83359 8.70725 7.70271 8.57642C7.5719 8.44554 7.39443 8.37207 7.20939 8.37207Z"
                fill="#666666"
            />
            <path
                d="M12.7914 8.37207C12.6064 8.37207 12.4289 8.44554 12.2981 8.57642C12.1672 8.70724 12.0938 8.8847 12.0938 9.06975V14.6512C12.0938 14.9004 12.2267 15.1307 12.4426 15.2554C12.6584 15.38 12.9244 15.38 13.1403 15.2554C13.3561 15.1307 13.4891 14.9004 13.4891 14.6512V9.06975C13.4891 8.88472 13.4156 8.70725 13.2847 8.57642C13.1539 8.44554 12.9765 8.37207 12.7914 8.37207Z"
                fill="#666666"
            />
        </svg>
    )
}

export default Trash
