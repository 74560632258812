import { JS_CONF } from "@/constants"

const OwlErrorLogo = () => {
    return (
        <div className="owl-error">
            <img alt={String()} src={`${JS_CONF?.static}img/404owl.png`} />
        </div>
    )
}

export default OwlErrorLogo
