import { SVGProps, memo } from "react"

interface Props extends SVGProps<SVGSVGElement> {
    width?: number
    height?: number
    className?: string
}

const NoFeedbackSvg = memo(({ width = 36, height = 27, className, ...props }: Props) => (
    <svg
        width={width}
        height={height}
        className={className}
        viewBox="0 0 36 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.62671 3.08861V2.89873C7.62671 1.85009 8.4768 1 9.52544 1H33.1014C34.15 1 35.0001 1.85009 35.0001 2.89873V19.038C35.0001 20.0866 34.15 20.9367 33.1014 20.9367H30.4409C30.3252 20.9367 30.2188 20.9998 30.1633 21.1013L27.762 25.4923C27.6419 25.7118 27.3267 25.7118 27.2067 25.4923L24.8053 21.1013C24.7498 20.9998 24.6434 20.9367 24.5277 20.9367H9.52544C8.4768 20.9367 7.62671 20.0866 7.62671 19.038V18.813"
            stroke="#FD4D00"
            strokeWidth={1.39241}
            strokeLinecap="round"
        />
        <g>
            <path d="M15.6963 7.3291L28.671 7.3291" stroke="#FD4D00" strokeWidth={1.39241} strokeLinecap="round" />
            <path d="M17.595 10.1772H29.3038" stroke="#FD4D00" strokeWidth={1.39241} strokeLinecap="round" />
            <path d="M16.3292 13.0254H23.9242" stroke="#FD4D00" strokeWidth={1.39241} strokeLinecap="round" />
        </g>
        <path
            d="M7.62665 6.01255L9.08948 8.87897C9.2826 9.2574 9.645 9.52069 10.0646 9.58742L13.2428 10.0929L10.9687 12.3699L11.4613 12.8619L10.9687 12.3699C10.6684 12.6705 10.53 13.0965 10.5962 13.5162L11.0976 16.695L8.2293 15.2359C7.85063 15.0432 7.40268 15.0432 7.024 15.2359L4.15571 16.695L4.65709 13.5162C4.72329 13.0965 4.58486 12.6705 4.28464 12.3699L3.82099 12.8329L4.28464 12.3699L2.01055 10.0929L5.18872 9.58742C5.6083 9.52069 5.9707 9.25739 6.16382 8.87897L7.62665 6.01255ZM4.04517 16.7512C4.0452 16.7512 4.04522 16.7512 4.04525 16.7512L4.04517 16.7512Z"
            stroke="#FD4D00"
            strokeWidth={1.39241}
        />
    </svg>
))

export { NoFeedbackSvg }
