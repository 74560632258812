import { useState } from "react"

import { useForm } from "react-hook-form"

import Skeleton from "$/components/Skeleton/Skeleton"
import { http } from "$/http"
import { getErrorMessages } from "$/utils/get-error-messages"

import Urls from "@/api/urls"
import useNps from "@/api/use-nps"
import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { DEFAULT_LIKERT_COLOR_VAR, ERROR_TEXT } from "@/constants"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import DividerLine from "@/shared/divider/DividerLine"
import TextAreaOffboarding from "@/shared/input/TextAreaOffboarding"
import LikertRadio from "@/shared/likert/LikertRadio"

import QuestionRow from "../diagnostic-question/QuestionRow"

type Props = {
    errorMsg: any
    showDividerLine?: boolean
    order?: number | null
    onSubmitNps: () => void
}

const NpsQuestion = ({ onSubmitNps, errorMsg, showDividerLine = true, order = 2 }: Props) => {
    const { data, isLoading } = useNps()
    const { register, control, getValues } = useForm()
    const [showFreeResponse, setShowFreeResponse] = useState(false)
    const [npsData, setNpsData] = useState(null)
    const [submitError, setSubmitError] = useState(null)

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    const getColor = index => {
        const foundColorPallete = data.questionData.colorRange.find(
            colorItem => index >= colorItem.from && index <= colorItem.to
        )
        return foundColorPallete ? foundColorPallete.color : DEFAULT_LIKERT_COLOR_VAR
    }

    const { onChange, ...registerData } = register("rating")

    const onChangeLikert = (e, color) => {
        onChange(e)
        sendNps(color)
        setShowFreeResponse(true)
    }

    const sendNps = async color => {
        const submitData = {
            rating: +getValues("rating"),
            color,
            question: data.id
        }

        try {
            if (npsData) {
                await http.patch(Urls.sendNps(npsData.id), submitData)
            } else {
                const { data } = await http.post(Urls.sendNps(), submitData)
                setNpsData(data)
            }
            onSubmitNps()
        } catch (error) {
            console.log(getErrorMessages(error))
            setSubmitError(ERROR_TEXT)
        }
    }

    const sendFreeResponse = async () => {
        try {
            await http.patch(Urls.sendNps(npsData.question), {
                ...npsData,
                free_response: getValues("free_response_prompt")
            })
        } catch (error) {
            console.log(getErrorMessages(error))
            setSubmitError(ERROR_TEXT)
        }
    }

    return (
        <div className="nps-questions">
            {isLoading && !data ? (
                <Skeleton rows={2} height={40} />
            ) : (
                <>
                    <QuestionRow
                        question={getValueFromTranslatedObject(data?.questionData?.question)}
                        order={order}
                        isRequired
                        className="pl-3"
                    />
                    <WarningMessage message={submitError || errorMsg} />
                    <div className="mb-4 level-choice level-choice--colored d-flex justify-content-center">
                        {data?.questionData?.options?.map((checkbox, index, options) => {
                            const color = getColor(index + 1)

                            return (
                                <LikertRadio
                                    name="rating"
                                    value={`${index + 1}`}
                                    id={`${index + 1}`}
                                    control={control}
                                    label={getValueFromTranslatedObject(checkbox?.response)}
                                    isFirstOption={index === 0}
                                    isLastOption={index === options.length - 1}
                                    register={() => ({ ...registerData, onChange: e => onChangeLikert(e, color) })}
                                    key={checkbox.uuid}
                                    color={color}
                                />
                            )
                        })}
                    </div>
                    {showFreeResponse && data?.freeResponseVisible ? (
                        <TextAreaOffboarding
                            name="free_response_prompt"
                            className="mt-40 text-left"
                            register={register}
                            placeholder={data?.freeResponsePrompt}
                            rows={2}
                            onBlur={sendFreeResponse}
                            headerText={data?.freeResponseQuestion}
                        />
                    ) : null}
                    {getValueFromTranslatedObject(data?.questionData?.question) && showDividerLine ? (
                        <DividerLine />
                    ) : null}
                </>
            )}
        </div>
    )
}

export default NpsQuestion
