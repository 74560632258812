import { FC } from "react"

import { useTranslation } from "react-i18next"

import { SECONDARY_PORTAL_ID } from "@/constants"
import Modal from "@/shared/modal"

interface IProps {
    show: boolean
    withPortal?: boolean
    onClose: () => void
    onOk: () => void
}

const DeleteModal: FC<IProps> = ({ show, onClose, onOk, withPortal = true }) => {
    const { t } = useTranslation()

    return (
        <Modal
            show={show}
            onClose={onClose}
            modalBodyClass="track-practice-delete-modal"
            withPortal={withPortal}
            portalId={SECONDARY_PORTAL_ID}
        >
            <p className="h4 text-center p_none">{t("Delete row? Data will be lost")}</p>
            <div className="track-practice-delete-modal-actions">
                <button onClick={onClose}>{t("No")}</button>
                <button onClick={onOk}>{t("Yes")}</button>
            </div>
        </Modal>
    )
}

export default DeleteModal
