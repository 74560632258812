import type { FC, ReactElement } from "react"

import classNames from "classnames"

import { type IUseTranslation, useTranslation } from "@/hooks"
import { capitalized } from "@/utils/common"

import type { TEmailBannerConfig } from "./types"

type TProps = TEmailBannerConfig

const EmailBanner: FC<TProps> = ({ isVisible, productType }: TProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className={classNames("coach-welcome__email-banner", { "d-none": !isVisible })}>
            <p className="font-extrabold mr-1 mb-0 font-sm">{t("coachSide.welcomePage.emailBanner.question")}</p>
            <p className="ml-1 mb-0 font-sm">
                {t("coachSide.welcomePage.emailBanner.description", {
                    cohortProductType: productType ? capitalized(productType) : String()
                })}
            </p>
        </div>
    )
}

export { EmailBanner as CoachWelcomeEmailBanner, TProps as TCoachWelcomeEmailBannerProps }
