import type { TUserModel } from "@/adapters"
import type { TStoredSubUser } from "@/services"
import { capitalized } from "@/utils/common"
import { isCoach, isProgramManager } from "@/utils/gates/user"

import {
    COACH_WELCOME_EMAIL_URI_PARAMETER_NAME,
    COACH_WELCOME_EMAIL_URI_PARAMETER_VALUE,
    COACH_WELCOME_PRODUCT_TYPE_URI_PARAMETER_NAME
} from "../config"

import { ECoachUserListItemVariant, type TCoachUserListItemToRender, type TEmailBannerConfig } from "./types"

function mapSubUserListAndCurrentUserToCoachListToRender({
    subUserList,
    currentUser
}: {
    subUserList: TStoredSubUser[]
    currentUser: TUserModel
}): TCoachUserListItemToRender[] {
    const participantListToRender: TCoachUserListItemToRender[] =
        subUserList?.map(
            ({ ...p }: TStoredSubUser): TCoachUserListItemToRender => ({
                ...p,
                color: p.avatarColor,
                description: "Explore the participant experience.",
                variant: ECoachUserListItemVariant.Participant
            })
        ) ?? []

    const currentUserToRender: TCoachUserListItemToRender = {
        role: capitalized(currentUser.role),
        photo: currentUser.photo,
        color: undefined,
        id: currentUser?.userId ?? currentUser?.id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        jwtPair: currentUser.jwtPair,
        ...(isProgramManager(currentUser) && {
            variant: ECoachUserListItemVariant.ProgramManager,
            description: "This is your normal program manager account"
        }),
        ...(isCoach(currentUser) && {
            variant: ECoachUserListItemVariant.Coach,
            description: "This is your normal coach account"
        })
    }

    return participantListToRender.length ? [currentUserToRender, ...participantListToRender] : [currentUserToRender]
}

function getEmailBannerConfigFromUri(params: URLSearchParams): TEmailBannerConfig {
    return {
        isVisible: params.get(COACH_WELCOME_EMAIL_URI_PARAMETER_NAME) === COACH_WELCOME_EMAIL_URI_PARAMETER_VALUE,
        productType: params.get(COACH_WELCOME_PRODUCT_TYPE_URI_PARAMETER_NAME)
    }
}

type TUtils = {
    mapSubUserListAndCurrentUserToCoachListToRender({
        subUserList,
        currentUser
    }: {
        subUserList: TStoredSubUser[]
        currentUser: TUserModel
    }): TCoachUserListItemToRender[]
    getEmailBannerConfigFromUri(params: URLSearchParams): TEmailBannerConfig
}

const utils: TUtils = {
    mapSubUserListAndCurrentUserToCoachListToRender,
    getEmailBannerConfigFromUri
}

export { utils as coachWelcomePageUtils, type TUtils as TCoachWelcomePageUtils }
