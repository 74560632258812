import { useEffect, useState } from "react"

export function usePageRefreshed(): boolean {
    const [isRefreshed, setIsRefreshed] = useState(false)

    useEffect(() => {
        const isPageReloaded = performance.navigation.type === performance.navigation.TYPE_RELOAD
        setIsRefreshed(isPageReloaded)
    }, [])

    return isRefreshed
}
