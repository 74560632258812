export interface CoachingGoalApi {
    id: number
    description: string
    order: number
    participant_id: number
}

export class CoachingGoalModel {
    id: number

    description: string

    order: number

    participantId: number

    constructor(data: CoachingGoalApi) {
        this.id = data.id
        this.description = data.description
        this.order = data.order
        this.participantId = data.participant_id
    }
}
