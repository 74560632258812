export class Urls {
    static api = "/api/practice-lab"

    static coacheesList = () => `${Urls.api}/coachees/`

    static conversation = () => `${Urls.api}/conversation/`

    static getConversation = (conversationId: string) => `${Urls.conversation()}${conversationId}/`

    static endConversation = (conversationId: string) => `${Urls.conversation()}${conversationId}/end/`

    static conversationFeedback = (conversationId: string) => `${Urls.conversation()}${conversationId}/feedback/`

    static getNewMessages = (converationId: string) => `${Urls.conversation()}${converationId}/new-messages/`

    static getMessages = (converationId: string) => `${Urls.conversation()}${converationId}/messages/`

    static sendMessage = (conversationId?: string) => `${Urls.conversation()}${conversationId}/message/`

    static sendFeedback = () => `${Urls.api}/feedback/`
}
