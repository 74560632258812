import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import { ServerStateKeys } from "@/constants"

import Urls from "./urls"

const getParticipantProgress = async ({ moduleId, participantId }: { moduleId: number; participantId: number }) => {
    const { data } = await http.get(Urls.participantProgress({ moduleId, participantId }))
    return data.results
}

export default function useParticipantProgress({
    moduleId,
    participantId
}: {
    moduleId: number
    participantId: number
}) {
    return useQuery(
        [ServerStateKeys.ParticipantProgress, moduleId, participantId],
        () => getParticipantProgress({ moduleId, participantId }),
        {
            select: useCallback(data => {
                return data
            }, []),
            enabled: !!moduleId && !!participantId
        }
    )
}
