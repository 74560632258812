import { type KeyboardEvent, type UIEvent, useCallback } from "react"

import { type NavigateFunction, useNavigate } from "react-router-dom"

import { CoachUrls } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import { emptyCallback } from "@/shared/types/functions"
import { isParticipant } from "@/utils/gates/user"

import { type TUseSecondaryAuth, useSecondaryAuth } from "."

type THandleNavigationFunction = ({ userId }: { userId: string }) => void
type THandleActionFunction = (event: UIEvent) => void

type TUseHook = {
    handleAction: THandleActionFunction
    handleNavigation: THandleNavigationFunction
}

function handler({ event, cb }: { event: UIEvent; cb: THandleNavigationFunction }): void {
    const target: HTMLLIElement = (event.target as HTMLElement).closest("li")

    if (!target) return

    if (event.type === "keydown" && ((event as KeyboardEvent).key === "Enter" || (event as KeyboardEvent).key === "")) {
        cb({ userId: target.dataset.index })
    }

    if (event.type === "click") {
        cb({ userId: target.dataset.index })
    }
}

function navigateNewTab(url: string, options: { withNoReferrer?: boolean } = { withNoReferrer: true }): void {
    window.open(url, "_blank", `${options?.withNoReferrer ? "noreferrer" : ""}`)
}

function useHook(this: { shouldNavigateToNewTab: boolean }): ReturnType<() => TUseHook> {
    const { user }: IAuthContext = useAuthContext()

    const navigate: NavigateFunction = useNavigate()

    const { reloadUser }: TUseSecondaryAuth = useSecondaryAuth()

    const handleNavigation: THandleNavigationFunction = useCallback(
        ({ userId }: { userId: string }): void => (
            Number(userId) === (user?.userId ?? user?.id)
                ? isParticipant(user)
                    ? emptyCallback()
                    : this.shouldNavigateToNewTab
                      ? emptyCallback()
                      : navigate(CoachUrls.SESSIONS_LIST)
                : (this.shouldNavigateToNewTab
                      ? navigateNewTab(`${CoachUrls.ITINERARY}?userId=${userId}`)
                      : reloadUser({ userId }),
                  void 0),
            void 0
        ),
        [navigate, navigateNewTab, reloadUser, user]
    )

    const handleAction: THandleActionFunction = useCallback(
        (event: UIEvent): void => handler({ event, cb: handleNavigation }),
        [handleNavigation]
    )

    return {
        handleAction,
        handleNavigation
    }
}

export { useHook as useSubUserListAction, type TUseHook as TUseSubUserListAction }
