import { type FC, type ReactElement } from "react"

type TSwapSessionNoSessionsMessageProps = {
    message: string
}

const SwapSessionErrorMessage: FC<TSwapSessionNoSessionsMessageProps> = ({
    message
}: TSwapSessionNoSessionsMessageProps): ReactElement => <p className="text-center mt-2 fs-16 font-bold">{message}</p>

export { SwapSessionErrorMessage }
