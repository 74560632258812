import { FC } from "react"

interface IProps {
    heigth?: number
    width?: number
    className?: string
}

const ArrowRightMobile: FC<IProps> = ({ heigth = 25, width = 46, className = "" }) => {
    return (
        <svg
            width={width}
            height={heigth}
            className={className}
            viewBox="0 0 46 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="46" height="25" rx="2" fill="white" />
            <path d="M13 12.5H32.6429" stroke="#FD4D00" strokeWidth="2" />
            <path d="M27.9297 7L33.4297 12.5L27.9297 18" stroke="#FD4D00" strokeWidth="2" />
        </svg>
    )
}

export default ArrowRightMobile
