import { FC, useEffect, useMemo, useState } from "react"

import { datadogLogs } from "@datadog/browser-logs"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

import Heading from "$/components/Heading/Heading"
import { http } from "$/http"
import { isEmptyString, isNullOrUndefined } from "$/utils/gates"

import useReflectionSubmit from "@/api/mutations/use-reflection-submit"
import useSkipReflection from "@/api/mutations/use-skip-reflection"
import Urls from "@/api/urls"
import useCoachingSessions from "@/api/use-coaching-sessions"
import useReflectionQuestions from "@/api/use-reflection-questions"
import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { CoachRatingAskedFrom } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import Coach from "@/models/coach"
import Button from "@/shared/button/Button"
import LabelDropdown from "@/shared/dropdown/LabelDropdown"
import Modal from "@/shared/modal"
import { Spinner } from "@/shared/spinner"
import { formatMonthDayYear } from "@/utils/date"

import AssessmentResponses from "../assessment-responses/AssessmentResponses"
import CoachRating from "../coach-rating/CoachRating"

import DiganosticResponses from "./DiagnosticQuestions"
import ReflectionQuestions from "./ReflectionQuestions"

interface IProps {
    show: boolean
    moduleId: number
    sessionId: number
    onClose: () => void
}

const ReflectionJourneyModal: FC<IProps> = ({ show, moduleId, sessionId, onClose }) => {
    const { t } = useTranslation()
    const { user }: IAuthContext = useAuthContext()
    const submitModuleReflection = useReflectionSubmit()
    const submitSkipReflection = useSkipReflection(sessionId)
    const { data: sessions } = useCoachingSessions()
    const { data, isLoading: isLoadingReflection } = useReflectionQuestions(moduleId)
    const [coachRating, setCoachRating] = useState(null)
    const [error, setError] = useState(null)
    const [activeDropdown, setActiveDropdown] = useState(false)

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    useEffect(() => {
        if (activeDropdown) {
            sendDDReflectionOpenLogs()
        }
    }, [activeDropdown])

    const coachSession = useMemo(
        () =>
            sessions?.live_session?.session_id === sessionId
                ? sessions?.live_session
                : sessions?.passed_sessions?.find(session => session.session_id === sessionId),
        [sessions]
    )

    const module = useMemo(() => user?.enrolledModules?.find(module => module.id === moduleId), [moduleId, user])

    const coach = useMemo(() => {
        if (coachSession) {
            return !isNullOrUndefined(coachSession?.coach) ? new Coach(coachSession?.coach) : null
        }
        return null
    }, [coachSession])

    const sendDDReflectionOpenLogs = () => {
        datadogLogs.logger.info("Post Session Reflection Popup", {
            name: "POST SESSION REFLECTION POPUP",
            userId: user.id,
            userName: `${user.firstName} ${user.lastName}`,
            opened: activeDropdown,
            org: user.organization,
            cohort: user.cohort,
            previous_answers: data?.activeDiagnosticAnswers
        })
    }

    const shapeWithDynamicValidation = useMemo(() => {
        const validationSchema = Object.create({})
        if (data?.diagnosticQuestions) {
            validationSchema.diagnostic = yup.object(
                data?.diagnosticQuestions?.reduce((validationMap, question) => {
                    validationMap[question.uuid] = yup.string().required(t("Answer is required")).nullable(true)
                    return validationMap
                }, {})
            )
        }
        return validationSchema
    }, [data])

    const methods = useForm({
        resolver: yupResolver(yup.object(shapeWithDynamicValidation))
    })

    const {
        handleSubmit,
        formState: { errors }
    } = methods

    const onSubmit = async values => {
        const moduleReflectionAnswers = values?.module_reflection
            ? Object.entries(values?.module_reflection).reduce((acc: any[], [question_uuid, answerText]: any) => {
                  const answer = Object.create({})
                  answer.question_uuid = question_uuid
                  answer.answer = answerText
                  acc.push(answer)
                  return acc
              }, [])
            : []

        const diagnosticResponsesAnswers = values?.diagnostic
            ? Object.entries(values?.diagnostic).reduce((acc: any[], [question_uuid, optionUUid]: any) => {
                  const answer = Object.create({})
                  answer.question_uuid = question_uuid
                  answer.option_uuid = optionUUid
                  answer.question = data?.diagnosticQuestions?.find(
                      question => question.uuid === question_uuid
                  )?.question
                  answer.answer = getValueFromTranslatedObject(
                      data?.diagnosticQuestions
                          ?.find(item => item.uuid === question_uuid)
                          ?.options.find(option => option.uuid === optionUUid)?.response
                  )
                  acc.push(answer)
                  return acc
              }, [])
            : []

        const moduleReflection = {
            type: "module_reflection",
            answer_list: moduleReflectionAnswers,
            question_list: data?.reflection?.module_reflection || []
        }

        const diagnosticResponses = {
            type: "diagnostic",
            answer_list: diagnosticResponsesAnswers,
            question_list: data?.diagnosticQuestions,
            summary_list: data?.reflection?.self_assessment?.summary_list || []
        }

        if (
            isNullOrUndefined(coachRating) &&
            moduleReflection?.answer_list?.length === 0 &&
            diagnosticResponses?.answer_list?.length === 0
        ) {
            setError("Rating is required")
            return
        }

        try {
            if (coachRating) {
                await http.post(Urls.submitRating(), {
                    participant: coachRating.participant,
                    coach: coachRating.coach,
                    rating: coachRating.rating,
                    question: coachRating.question,
                    free_response: coachRating?.free_response ?? "",
                    coaching_session: coachRating?.coaching_session
                })
            }

            const data = []

            if (moduleReflection?.answer_list?.length > 0) {
                data.push(moduleReflection)
            }

            if (diagnosticResponses?.answer_list?.length > 0) {
                data.push(diagnosticResponses)
            }

            if (data.length > 0) {
                submitModuleReflection.mutate(
                    { module_id: moduleId, responses: data },
                    {
                        onSuccess: () => {
                            onClose()
                        }
                    }
                )
                return
            }

            onClose()
        } catch (e) {
            console.log(e)
        }
    }

    const onRatingClick = (data: any) => {
        setCoachRating(data)
        setError(null)
    }

    const onSaveFreeResponse = (_: number, response: string) => {
        setCoachRating(data => ({ ...data, free_response: response }))
    }

    const onClickSkip = () => {
        submitSkipReflection.mutate(null, {
            onSuccess: () => {
                onClose()
            }
        })
    }

    const onToggleActiveDropdown = () => {
        setActiveDropdown(prev => {
            return !prev
        })
    }

    const sessionTitle = useMemo(() => {
        const sessionName = coachSession?.session_type_pref_lang?.name
        const sessionTime = formatMonthDayYear(coachSession?.session_time)

        if (sessionName && sessionTime) {
            return `${sessionName}: ${sessionTime}`
        }

        return ""
    }, [coachSession])

    return (
        <Modal
            show={show}
            onClose={onClickSkip}
            enableOutSideClick={false}
            closeOnEscapePress={false}
            disableBodyScroll
            showCloseIcon
            modalBodyClass="journey-reflection-modal"
            headerContent={
                <div className="p-3">
                    <Heading tag="h2" className="text-center m-0 reflection-header-title" fontSize={24}>
                        {t("How was your session?")}
                    </Heading>

                    {!isEmptyString(sessionTitle) ? (
                        <p className="text-center fs-14 color-gray m-0">{sessionTitle}</p>
                    ) : null}
                </div>
            }
        >
            <div className="journey-reflection-modal-body">
                <FormProvider {...methods}>
                    <div className="my-2">
                        <CoachRating
                            orderNumber={null}
                            askedFrom={CoachRatingAskedFrom.POST_MODULE_REFLECTION_POPUP}
                            coachSessionId={sessionId}
                            saveCoachRating={onSaveFreeResponse}
                            onStarSelect={onRatingClick}
                            textInputBgVariant="light"
                            sendFreeResponse={false}
                            coachData={coach}
                        />
                        <WarningMessage message={error} />
                    </div>
                    {!isNullOrUndefined(module) && (
                        <Heading tag="h2" fontSize={16} className="modal-module-title">
                            {t("{{moduleName}} Module Questions", {
                                moduleName: getValueFromTranslatedObject(module?.name)
                            })}
                        </Heading>
                    )}
                    {data?.activeDiagnosticAnswers?.length ? (
                        <LabelDropdown
                            text={`${activeDropdown ? t("Hide") : t("Show")} ${t("My Previous Responses")}`}
                            active={activeDropdown}
                            onToggle={onToggleActiveDropdown}
                            className={activeDropdown ? "mb-3" : "mb-40"}
                        />
                    ) : null}

                    {activeDropdown && (
                        <AssessmentResponses
                            diagnosticAnswers={data?.activeDiagnosticAnswers}
                            title={t("Initial Assessment Responses")}
                            className="mb-40 text-left"
                            variant="offboarding"
                        />
                    )}

                    {isLoadingReflection && (
                        <div className="my-3 p-5 d-flex align-items-center justify-content-center">
                            <Spinner />
                        </div>
                    )}

                    {data?.diagnosticQuestions?.length > 0 && (
                        <DiganosticResponses diagnosticQuestions={data?.diagnosticQuestions} errors={errors} />
                    )}

                    <ReflectionQuestions moduleReflection={data?.reflection?.module_reflection} errors={errors} />

                    <div className="text-center mb-30">
                        <Button onClick={handleSubmit(onSubmit)} isBusy={isLoadingReflection}>
                            {t("Submit")}
                        </Button>
                    </div>

                    <p className="text-center m-0 color-gray fs-14">
                        {t(
                            "Your responses will be anonymized and aggregated with fellow participants before being shared back to your organization."
                        )}
                    </p>
                </FormProvider>
            </div>
        </Modal>
    )
}

export default ReflectionJourneyModal
