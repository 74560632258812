import type { FC, ReactElement } from "react"

import Title from "$/components/Heading/Heading"

import { type IUseTranslation, useTranslation } from "@/hooks"

const Heading: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <Title className="mb-4" textAlign="center">
            {t("coachSide.welcomePage.heading")}
        </Title>
    )
}

export { Heading as CoachWelcomeHeading }
