import { ReactNode, useMemo, useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import sanitizeHtml from "sanitize-html"

import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_SMALL_MOBILE_MEDIA } from "@/constants"
import Coach from "@/models/coach"
import ArrowDown from "@/svgs/ArrowDown"

import { COACH_IMAGE_SIZE } from "./constants"

import "./styles.scss"

type Props = {
    coach: Coach
    actionBtn?: ReactNode | null
    className?: string
}

const CoachBlock = ({ coach, actionBtn = null, className = "" }: Props) => {
    const { t } = useTranslation()
    const [showMore, setShowMore] = useState(false)
    const [hasMoreText, setHasMoreText] = useState(false)
    const isMobile = useMediaQuery(MAX_WIDTH_SMALL_MOBILE_MEDIA)

    console.log(coach, "coahc")

    const coachText = useMemo(() => {
        if (coach) {
            if (coach.bio.length > 500 && isMobile) {
                setHasMoreText(true)
                if (showMore) {
                    return coach.bio
                }
                return `${coach.bio.substring(0, 500)}...`
            }
            return coach.bio
        }
    }, [coach, showMore])

    const toggleShowMore = () => {
        setShowMore(prev => !prev)
    }

    return (
        <article className={classNames("coach-info", { [className]: className })} data-testid="coach-block">
            <div className="coach-info-left p-3">
                <img src={coach.photo} width={COACH_IMAGE_SIZE} height={COACH_IMAGE_SIZE} alt="coach-photo" />
                <p className="coach-info-left__name">
                    {coach.firstName} {coach.lastName}
                </p>
                {coach.certification ? <span className="coach-info-left__degree">{coach.certification}</span> : null}
                {coach?.location || coach?.country ? (
                    <span className="coach-info-left__location">
                        {coach.location}
                        {coach.location && coach.country ? <>,&nbsp;</> : null}
                        {coach.country}
                    </span>
                ) : null}
                {actionBtn ? <div className="mt-3">{actionBtn}</div> : null}
            </div>
            <div className="coach-info-right">
                <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(coachText) }} data-testid="coach-bio-text" />
                {hasMoreText && isMobile && (
                    <a className="color-brand cursor-pointer" onClick={toggleShowMore}>
                        {showMore ? t("Hide") : t("Show More")}
                        <ArrowDown className={classNames("ml-8", { rotate: showMore })} />
                    </a>
                )}
            </div>
        </article>
    )
}

export default CoachBlock
