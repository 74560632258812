import { Status } from "@/constants"

import { ChapterComponent } from "Manager/chapter/models/types"

import { ISummaryApi, Summary } from "./summary"

export type ChapterStatus = Status.Open | Status.InProgress | Status.Completed

export interface IChapterApi {
    chapter_summary: ISummaryApi
    editable_after_finish: boolean
    enrollment: number
    phase_progress: number
    show_summary_after_finish: boolean
    uuid: string
    components: ChapterComponent[]
    status: ChapterStatus
    title: {
        en: string
    }
}

export default class Chapter {
    chapterSummary: Summary

    editableAfterFinish: boolean

    enrollment: number

    phaseProgress: number

    showSummaryAfterFinish: boolean

    uuid: string

    components: ChapterComponent[]

    status: ChapterStatus

    title: {
        en: string
    }

    constructor(data: IChapterApi) {
        this.chapterSummary = new Summary(data.chapter_summary)
        this.editableAfterFinish = data.editable_after_finish
        this.enrollment = data.enrollment
        this.phaseProgress = data.phase_progress
        this.showSummaryAfterFinish = data.show_summary_after_finish
        this.uuid = data.uuid
        this.components = data.components
        this.title = data.title
        this.status = data.status
    }
}
