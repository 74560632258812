import { useMutation } from "@tanstack/react-query"

import { http } from "$/http"

import { IChapterApi } from "@/models/chapter"

import Urls from "../urls"

export default function useChangeProfile() {
    return useMutation((profile: IChapterApi) => http.put(Urls.profileChange(), profile))
}
