import { type FC, type ReactElement } from "react"

import Spinner from "@/shared/spinner/Spinner"

const SurveysFallbackSpinner: FC = (): ReactElement => (
    <div className="mx-auto my-3 w-min">
        <Spinner />
    </div>
)

export { SurveysFallbackSpinner }
