import { ECoachTableType, type TDataStateForTable } from "./types"

function getDataStateForTable({
    sessionsData,
    participantsData,
    searchValue,
    isSessionsDataLoading
}): TDataStateForTable {
    const noSearchResults: boolean = sessionsData?.length === 0 && searchValue?.length > 0
    const noSessionsInCohort: boolean = sessionsData?.length === 0 && !isSessionsDataLoading
    const noSessionsData: boolean =
        (sessionsData?.length === 0 && !isSessionsDataLoading && !noSearchResults && !noSessionsInCohort) ||
        sessionsData?.length === 0
    const noParticipantsData: boolean = participantsData?.length === 0 && participantsData?.length > 0

    return { noSearchResults, noSessionsData, noParticipantsData, noSessionsInCohort }
}

function getTableStatusText({
    tableType,
    noSearchResults = undefined,
    noSessionsData = undefined,
    noParticipantsData = undefined,
    noSessionsInCohort = undefined
}): string {
    let statusText = ""

    if (tableType === ECoachTableType.Groups || tableType === ECoachTableType.Sessions) {
        if (noSearchResults) {
            statusText = "We couldn’t find any results"
            return statusText
        }
        if (noSessionsInCohort) {
            statusText = "You have no upcoming or past coaching sessions scheduled with participants in this cohort"
            return statusText
        }
        if (noSessionsData) {
            statusText = "You have no upcoming or past coaching sessions scheduled for groups"
            return statusText
        }
    } else if (tableType === ECoachTableType.Participants) {
        if (noSearchResults) {
            statusText = "We couldn’t find any results"
            return statusText
        }
        if (noParticipantsData) {
            statusText = "No participants"
            return statusText
        }
    }

    return statusText
}

export { getTableStatusText, getDataStateForTable }
