import { useCallback, useEffect, useMemo, useRef } from "react"

import classNames from "classnames"

import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { useAuthContext } from "@/context"
import { useSimulabContext } from "@/entities/simulab"
import { SIMULAB_CONVERSATION_STATUS } from "@/entities/simulab/constants"
import { scrollToBottom } from "@/utils/common"

import { SimulabChatFeedStage } from "./SimulabChatFeedStage"

export const SimulabChatFeed = () => {
    const endOfChatBox = useRef(null)
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const { user } = useAuthContext()
    const {
        conversation,
        simulabMessages,
        selectedMessage,
        hasError,
        badMessage,
        isMessageUnselected,
        unselectMessage,
        setReportData,
        selectMessage
    } = useSimulabContext()
    const messages = simulabMessages?.messages ?? []

    const currentStage = useMemo(() => {
        return conversation?.stages?.find(stage => stage.current)
    }, [conversation?.stages])

    const onClickMessageRow = useCallback(
        (messageId: number) => {
            if (selectedMessage === messageId) {
                selectMessage(null)
                unselectMessage(true)
                return
            }

            if (selectedMessage !== messageId) {
                selectMessage(messageId)
                unselectMessage(false)
            }
        },
        [selectMessage, selectedMessage, isMessageUnselected]
    )

    useEffect(() => {
        if (isMobile) {
            scrollToBottom(endOfChatBox, { behavior: "smooth" })
        }
    }, [simulabMessages?.messages, currentStage, hasError, badMessage, isMobile])

    const isConversationEnded =
        conversation?.status === SIMULAB_CONVERSATION_STATUS.COMPLETED && !!conversation?.feedback

    return (
        <div
            className={classNames("simulab-chat-feed ", {
                "custom-scrollbar": !isConversationEnded,
                "has-final-feedback": isConversationEnded
            })}
        >
            {conversation?.stages.map((stage, index) => (
                <SimulabChatFeedStage
                    onFeedbackReportClick={setReportData}
                    hasBadMessage={badMessage}
                    stage={stage}
                    stageIndex={index}
                    isUnselectedMessage={isMessageUnselected}
                    isError={hasError}
                    messages={messages}
                    targetMessageId={selectedMessage}
                    currentStage={currentStage}
                    onClickMessageRow={onClickMessageRow}
                    coachee={conversation?.coachee}
                    user={user}
                    key={stage.id}
                />
            ))}
            {isMobile && <div ref={endOfChatBox} />}
        </div>
    )
}
