import { DotLottieReact, DotLottieReactProps } from "@lottiefiles/dotlottie-react"

type Props = { className?: string } & DotLottieReactProps

export const AnimationPlayer = ({ className = "", ...rest }: Props) => {
    return (
        <div className={className}>
            <DotLottieReact {...rest} />
        </div>
    )
}
