import { useLocation } from "react-router-dom"

import useUpdateEffect from "./use-update-effect"

function useOnRouteChange(effect: () => void) {
    const location = useLocation()

    useUpdateEffect(() => {
        effect()
    }, [location])
}

export default useOnRouteChange
