import { type FC, type ReactElement, Suspense, useMemo } from "react"

import { type IAuthContext, useAuthContext } from "@/context"
import { TUseSubUserList, type TUseSubUserListAction, useSubUserList, useSubUserListAction } from "@/hooks"
import { TStoredSubUser } from "@/services"

import type { TCoachUserListItemToRender } from "./types"
import { type TCoachWelcomePageUtils as TUtils, coachWelcomePageUtils as utils } from "./utils"

import { CoachWelcomeLoadingFallback as Fallback, CoachWelcomeUserListItem as UserListItem } from "."

const { mapSubUserListAndCurrentUserToCoachListToRender: mapper }: TUtils = utils

const UserList: FC = (): ReactElement => {
    const { user: currentUser }: IAuthContext = useAuthContext()

    const { subUserList, isSubUserListLoading }: TUseSubUserList = useSubUserList.call({ msDebounce: 500 })

    const mappedList: TCoachUserListItemToRender[] = useMemo(
        (): TCoachUserListItemToRender[] =>
            isSubUserListLoading
                ? []
                : mapper({
                      subUserList: subUserList.filter((s: TStoredSubUser): boolean => s.id !== currentUser?.userId),
                      currentUser
                  }),
        [subUserList, currentUser, isSubUserListLoading]
    )

    const { handleAction }: TUseSubUserListAction = useSubUserListAction.call({ shouldNavigateToNewTab: true })

    return (
        <Suspense fallback={<Fallback />}>
            {isSubUserListLoading ? (
                <Fallback />
            ) : !isSubUserListLoading && mappedList?.length ? (
                <section className="coach-welcome__user-list__wrapper">
                    <ul
                        role="tablist"
                        className="coach-welcome__user-list"
                        onKeyDown={handleAction}
                        onClick={handleAction}
                    >
                        {mappedList.map(
                            (u: TCoachUserListItemToRender): ReactElement => (
                                <UserListItem {...u} key={`sub-user-${u.id}`} />
                            )
                        )}
                    </ul>
                </section>
            ) : null}
        </Suspense>
    )
}

export { UserList as default }
