import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import type { IParticipantModel } from "@/adapters"
import { CARE_TEAM_EMAIL } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import { type TUseLogoNavigation, useLogoNavigation } from "@/hooks"
import Sidebar from "@/shared/sidebar/Sidebar"
import WiserLogo from "@/svgs/WiserLogo"

import { dashboardURL } from "../onboarding/constants"

const MobileMenuBurger = () => {
    const { t } = useTranslation()
    const { logout, user }: IAuthContext = useAuthContext()

    const handleLogout = async e => {
        e.preventDefault()

        logout({ keepLocalStorageAuthTokens: (user as IParticipantModel)?.isFakeParticipant })
    }

    const { handleLogoNavigation }: TUseLogoNavigation = useLogoNavigation()

    return (
        <Sidebar
            className="d-md-none"
            sidebarLogo={<WiserLogo width={26} height={26} onClick={handleLogoNavigation} />}
        >
            <ul className="menu-capitalized">
                <li className="">
                    <Link to={dashboardURL}>
                        <span>{t("Dashboard")}</span>
                    </Link>
                </li>
                <li>
                    <a href={CARE_TEAM_EMAIL}>
                        <span>{t("Contact Care Team")}</span>
                    </a>
                </li>
                <li>
                    <a href="" onClick={handleLogout}>
                        <span>{t("Sign Out")}</span>
                    </a>
                </li>
            </ul>
        </Sidebar>
    )
}

export default MobileMenuBurger
