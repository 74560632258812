import { type FC, type ReactElement } from "react"

import { Spinner } from "@/shared/spinner"

const CoachRelinkAccountFallbackSpinner: FC = (): ReactElement => (
    <div className="spinner">
        <Spinner variant="accent" size="small" width={24} height={24} />
    </div>
)

export { CoachRelinkAccountFallbackSpinner }
