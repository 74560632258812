import { useCallback, useMemo } from "react"

import { QueryClient, type UseQueryOptions, type UseQueryResult, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { ServerStateKeys as EQueryKey } from "@/constants"

import { type TSubUserListQueryUtils as TUtils, subUserListQueryUtils as utils } from "./utils"

import { type ISubUserDto as IDto, type ISubUserModel as IModel } from "."

const { adaptSubUserList: adapter, fetchSubUserList: fetcher }: TUtils = utils

type TParams = UseQueryOptions
type TQuery = UseQueryResult<IModel[], AxiosError>
type TReturn = ReturnType<(params: TParams) => TQuery>

function useHook({ onSuccess, ...restParams }: TParams = {}): TReturn {
    const queryClient: QueryClient = useQueryClient()

    const handleSuccess: (data: IModel[]) => void = useCallback(
        (data: IModel[]): void => {
            queryClient.setQueryData([EQueryKey.SubUsers], (): IModel[] => data)
            onSuccess?.(data)
        },
        [onSuccess, queryClient]
    )

    return useQuery<IDto[], AxiosError, IModel[], Array<EQueryKey>>(
        useMemo((): [EQueryKey] => [EQueryKey.SubUsers], []),
        useCallback(fetcher, []),
        {
            // @ts-expect-error no error here, react-query types mess
            select: useCallback(adapter, []),
            onSuccess: handleSuccess,
            ...restParams
        }
    )
}

export { useHook as useSubUserListQuery, type TQuery as TSubUserListQuery }
