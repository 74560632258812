import "./styles.scss"

const WaveLoader = () => (
    <div className="wave-loader">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
    </div>
)

export default WaveLoader
