import { FC, Fragment, useCallback, useState } from "react"

import classNames from "classnames"
import { Trans, useTranslation } from "react-i18next"
import sanitizeHtml from "sanitize-html"

import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import Button from "@/shared/button/Button"
import QuestionMark from "@/svgs/QuestionMark"
import useRoles from "@/utils/hooks/use-roles"

import ResultsBar from "./ResultsBar"
import { SummaryChevron } from "./SummaryChevron"

import "./styles.scss"

interface IProps {
    summary: any
    className?: string
    selectedUserFirstName?: string
    showQuestionTooltip?: boolean
    onTooltipClick?: (category) => void
}

const SummaryResult: FC<IProps> = ({
    summary,
    className = "",
    selectedUserFirstName = "",
    showQuestionTooltip = false,
    onTooltipClick
}) => {
    const { t } = useTranslation()
    const { isCoaches } = useRoles()
    const [collapsedModules, setCollapsedModules] = useState<number[]>([])

    const onClickResults = useCallback(
        index => {
            setCollapsedModules(active => {
                if (active.includes(index)) {
                    return active.filter(item => item !== index)
                }
                return [...active, index]
            })
        },
        [collapsedModules]
    )

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    return (
        <div className={classNames("summary-result", { [className]: className })} data-testid="summary-result">
            <div className="d-flex mb-1 align-items-center">
                <p className="font-extrabold m-0 mr-1">
                    <Trans i18nKey="<0>Skills for </0><1>{categoryName}</1>">
                        <span>Skills for </span>
                        <span className="color-brand">
                            {/* @ts-expect-error wtf is below */}
                            {{ categoryName: getValueFromTranslatedObject(summary.category.name) }}
                        </span>
                    </Trans>
                </p>
                {showQuestionTooltip && (
                    <Button
                        variant="default"
                        className="p-1 d-flex align-items-center justify-content-center"
                        data-testid="question-tooltip-button"
                        onClick={() => onTooltipClick && onTooltipClick(summary.category)}
                        aria-label={getValueFromTranslatedObject(summary.category.name)}
                    >
                        <QuestionMark />
                    </Button>
                )}
            </div>
            <p className="mb-3">
                <span>{isCoaches ? selectedUserFirstName : t("My confidence levels")}:</span>
            </p>

            {summary.modules.map((module, index) => (
                <Fragment key={module.id}>
                    <div
                        role="button"
                        className="d-flex align-items-center mb-2 results-btn"
                        onClick={() => onClickResults(index)}
                        data-testid={`results-bar-${index}`}
                    >
                        <ResultsBar
                            max={module.max_rank}
                            current={module.rank}
                            title={getValueFromTranslatedObject(module.title)}
                            key={module.id}
                            summaryValue={getValueFromTranslatedObject(module.summary.short_summary)}
                            active={collapsedModules.includes(index)}
                            className="mr-2 flex-1"
                        />
                        <Button variant="default" className="p-0 chevron-btn">
                            <SummaryChevron active={collapsedModules.includes(index)} />
                        </Button>
                    </div>

                    {collapsedModules.includes(index) ? (
                        <div className="summary-result__module-descr mt-4" data-testid="summary-collapsed-text">
                            <h5 className="fs-14 font-extrabold">{getValueFromTranslatedObject(module.title)}</h5>
                            <div
                                className="fs-14"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(getValueFromTranslatedObject(module.long_description), {
                                        allowedTags: []
                                    })
                                }}
                            />
                        </div>
                    ) : null}
                </Fragment>
            ))}
        </div>
    )
}

export default SummaryResult
