import { useEffect, useRef } from "react"

import { useTranslation } from "react-i18next"

import Heading from "$/components/Heading/Heading"

import { DEFAULT_TRANSITION_TIME } from "@/constants"
import { useSimulabContext } from "@/entities/simulab"
import { WiserLogo } from "@/svgs"
import { scrollToBottom } from "@/utils/common"

import { StageItem } from "./Stage"

import "./styles.scss"

export const SimulabStages = () => {
    const endOfMessagesRef = useRef(null)
    const { t } = useTranslation()
    const { conversation, simulabMessages } = useSimulabContext()

    useEffect(() => {
        const timeout = setTimeout(() => {
            scrollToBottom(endOfMessagesRef, { block: "start" })
        }, DEFAULT_TRANSITION_TIME)

        return () => clearTimeout(timeout)
    }, [simulabMessages?.messages, conversation])

    const hasMessages = simulabMessages?.messages?.length > 0

    return (
        <aside className="model-stages ">
            <div className="model-stages__header">
                <Heading tag="h2" fontSize={24} className="m-0" textAlign="center">
                    {t("Owl’s Feedback")}
                </Heading>
            </div>

            <div className="model-stages__wrapper custom-scrollbar">
                {!hasMessages && (
                    <p className="m-0 color-gray fs-14 mt-auto text-center">
                        {t("Ask {{coacheeName}} your first question to begin", {
                            coacheeName: conversation?.coachee?.name
                        })}
                        .
                    </p>
                )}

                {conversation?.stages?.map(
                    stage =>
                        (stage.completed || stage.current) && hasMessages && <StageItem key={stage.id} stage={stage} />
                )}
                <div ref={endOfMessagesRef} />
            </div>
            <div className="d-flex justify-content-center">
                <WiserLogo width={30} height={40} />
            </div>
        </aside>
    )
}
