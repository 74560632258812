import { FC, ReactNode, memo } from "react"

import classNames from "classnames"

import type { IParticipantModel } from "@/adapters"
import { type IAuthContext, type IStoreContext, useAuthContext, useStoreContext } from "@/context"
import ParticipantLayoutHeader from "@/elements/participant-layout-header/ParticipantLayoutHeader"
import { FullSpinner } from "@/shared/spinner"

import { ViewAsBanner } from "../components"
import "../styles.scss"

interface IProps {
    leftSidebar?: JSX.Element | null
    header?: JSX.Element | ReactNode | null
    closeMenu?: boolean
    className?: string
    isLoading?: boolean
    showLogoOnMobile?: boolean
    noPadding?: boolean
    title?: ReactNode
    children?: ReactNode
    tag?: "section" | "main"
}

const ParticipantLayout: FC<IProps> = ({
    children,
    leftSidebar = null,
    header = null,
    closeMenu = false,
    isLoading = false,
    noPadding = false,
    showLogoOnMobile = true,
    title = null,
    className = "",
    tag: Tag = "section"
}) => {
    const { isOpenBurgerMenu, isOpenSidebar }: IStoreContext = useStoreContext()

    const { user }: IAuthContext = useAuthContext()

    return (
        <>
            {leftSidebar}
            <Tag
                className={classNames("participant-layout", className, {
                    "show-spacing": !noPadding && !!header,
                    "gray-bg": isOpenBurgerMenu,
                    "closed-sidebar": closeMenu || !isOpenSidebar
                })}
            >
                <ParticipantLayoutHeader header={header} showLogo={showLogoOnMobile} />
                {(user as IParticipantModel)?.isFakeParticipant && <ViewAsBanner />}
                {title && (
                    <div
                        className={classNames("participant-layout-page-title", {
                            "fake-mode": (user as IParticipantModel)?.isFakeParticipant
                        })}
                    >
                        {title}
                    </div>
                )}
                {isLoading ? <FullSpinner /> : children}
            </Tag>
            {/* <div className="registration-bottom registration-bottom__visible"></div> */}
        </>
    )
}

export default memo(ParticipantLayout)
