import { useCallback, useMemo } from "react"

import { useNavigate } from "react-router-dom"

import { coachCalendarSetupRoutes } from "@/routes"

export function useOrderNavigationCalendar(order: number) {
    const navigate = useNavigate()

    const nextOrderStep = useMemo(() => {
        if (order !== coachCalendarSetupRoutes.length) {
            return coachCalendarSetupRoutes[order].path
        }
        return "/"
    }, [order, coachCalendarSetupRoutes])

    const navigateByOrder = useCallback(() => {
        navigate(nextOrderStep)
    }, [nextOrderStep])

    return { navigateByOrder, nextOrderStep }
}
