import { CalendarAccountApi, ProfileCalendarApi } from "../api/types"
import { CalendarStatus } from "../types"

export class ProfileCalendar {
    calendarId: string

    calendarName: string

    isCalendarPrimary: boolean

    isCoachCalendarAvailable: boolean

    constructor(data: ProfileCalendarApi) {
        this.calendarId = data.calendar_id || ""
        this.calendarName = data.calendar_name || ""
        this.isCalendarPrimary = data.calendar_primary || false
        this.isCoachCalendarAvailable = data.coach_calendar_available || false
    }
}

export class CalendarAccount {
    profileId: string

    profileName: string

    providerName: string

    providerService: string

    profileCalendars: ProfileCalendar[]

    coachHasPrimaryCalendarProfile: boolean

    status: CalendarStatus

    constructor(data: CalendarAccountApi) {
        this.profileId = data.profile_id || ""
        this.profileName = data.profile_name || ""
        this.providerName = data.provider_name || ""
        this.providerService = data.provider_service || ""
        this.profileCalendars = data.profile_calendars.map(calendar => new ProfileCalendar(calendar)) || []
        this.coachHasPrimaryCalendarProfile = data.coach_primary_calendar_profile || false
        this.status = data.status
    }
}
