import type { FC, ReactElement, ReactNode } from "react"

import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import { type IUseTranslation, useTranslation } from "@/hooks"
import { capitalized } from "@/utils/common"

import "./ViewAsBanner.styles.scss"

const ViewAsBanner: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const { user }: IAuthContext = useAuthContext()

    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    function renderUserInfo(): ReactNode {
        return (
            <>
                <p className="mb-0 text-center">
                    <span>{capitalized(user?.productType)}</span>
                    &nbsp;
                    {/* "User" should not be translated coz is viewed only from coach-like side, which is English only */}
                    <span>User</span>
                    &nbsp;
                </p>
                <span>&#40;{user?.firstName}</span>
                &nbsp;
                <span>{user?.lastName}&#41;</span>
            </>
        )
    }

    return (
        <article className="view-as-banner">
            <p className="font-extrabold label">
                {t("participantSide.fakeParticipantMode.banner.headingLabel")}&#58;&nbsp;
            </p>
            {isMobile ? <div>{renderUserInfo()}</div> : <>{renderUserInfo()}</>}
        </article>
    )
}

export { ViewAsBanner }
