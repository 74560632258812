import ParticipantWrapper from "@/elements/participant-wrapper"
import { StakeHolderSurvey } from "@/features/stake-holder-survey"
import { SimpleLayout } from "@/layouts"

const StakeHolderSurveyPage = () => {
    return (
        <SimpleLayout withProfileHeader withoutHeader isSimpleHeader withFooter={false}>
            <ParticipantWrapper size="max-md">
                <StakeHolderSurvey />
            </ParticipantWrapper>
        </SimpleLayout>
    )
}

export default StakeHolderSurveyPage
