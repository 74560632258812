import { useEffect, useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"

import { isEmptyString } from "$/utils/gates"
import { getErrorMessages } from "$/utils/get-error-messages"

import { useSetManager } from "../api/use-set-manager"
import { ThreeWayManager } from "../model/three-way"
import { validationSchema } from "../validation"

type Options = {
    threeWayManager: ThreeWayManager | null
    sessionId: number
    setShowFields: (value: boolean) => void
}

export function useThreeWayForm({ threeWayManager, sessionId, setShowFields }: Options) {
    const [isUpdating, setIsUpdating] = useState(false)
    const [error, setError] = useState(null)
    const submitManagerInfo = useSetManager()

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors, isDirty, isSubmitSuccessful },
        setValue,
        watch
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            email: threeWayManager?.managerEmail ?? "",
            first_name: threeWayManager?.managerFirstName ?? "",
            last_name: threeWayManager?.managerLastName ?? ""
        }
    })

    const fieldValues = watch()

    const areAllFieldsEmpty = useMemo(
        () => isDirty && Object.values(fieldValues).every(isEmptyString),
        [isDirty, fieldValues]
    )

    const noValues = Object.values(fieldValues).every((value: string) => isEmptyString(value))

    useEffect(() => {
        if (threeWayManager && !isDirty) {
            setNewManagerValues()
        }
        if (!threeWayManager) {
            setShowFields(true)
        }
    }, [threeWayManager, isDirty, areAllFieldsEmpty])

    const setNewManagerValues = () => {
        setValue("email", threeWayManager.managerEmail)
        setValue("first_name", threeWayManager.managerFirstName)
        setValue("last_name", threeWayManager.managerLastName)
    }

    const onUpdateFields = formValues => {
        setIsUpdating(true)
        submitManagerInfo.mutate(
            { ...formValues, sessionId },
            {
                onSuccess: () => {
                    setShowFields(false)
                    setIsUpdating(false)
                },
                onError(error) {
                    setError(getErrorMessages(error))
                }
            }
        )
    }

    return {
        formErrors,
        isUpdating,
        isDirty,
        error,
        register,
        onUpdateFields: handleSubmit(onUpdateFields),
        fieldValues,
        noValues,
        isSubmitSuccessful
    }
}
