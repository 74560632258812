import {
    type Dispatch,
    type FC,
    type MutableRefObject,
    type ReactElement,
    type SetStateAction,
    useCallback,
    useRef,
    useState
} from "react"

import classNames from "classnames"

import Heading from "$/components/Heading/Heading"
import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { type IUseTranslation, useTranslation } from "@/hooks"
import Coach from "@/models/coach"
import type { IAvailableGuestSessionModel } from "@/pages/swap-session/api"
import Avatar from "@/shared/avatar/Avatar"
import Button from "@/shared/button/Button"
import type { TEmptyCallback } from "@/shared/types/functions"

import { type IAvailableSessionDataGroupedByDay } from "./types"

import { SwapSessionActionArrow as ActionArrow, SwapSessionSessionTime as SessionTimeBlock } from "."

type TSwapSessionAccordionProps = {
    session: IAvailableSessionDataGroupedByDay
    coach?: Coach
    handleSetSuggestedSession(session: IAvailableGuestSessionModel): void
    handleOpenSwapSessionModal: TEmptyCallback
}

const SwapSessionAccordion: FC<TSwapSessionAccordionProps> = ({
    session,
    coach,
    handleSetSuggestedSession,
    handleOpenSwapSessionModal
}: TSwapSessionAccordionProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const [isExpanded, setIsExpanded]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

    const handleSessionItemSelect: (session: IAvailableGuestSessionModel) => void = useCallback(
        (session: IAvailableGuestSessionModel) => (handleOpenSwapSessionModal(), handleSetSuggestedSession(session)),
        [handleOpenSwapSessionModal, handleSetSuggestedSession]
    )

    const contentRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const avatarSize: number = isMobile ? 35 : 40

    const handleAccordionClick: TEmptyCallback = useCallback(
        () => session.sessions.length != 0 && setIsExpanded(prev => !prev),
        [session]
    )

    return (
        <div
            title={t("participantSide.swapSessionsScreen.sessionAccordion.tooltip")}
            aria-label={t("participantSide.swapSessionsScreen.sessionAccordion.tooltip")}
            className={classNames("swap-session__accordion__item", {
                "not-available": session.sessions.length == 0,
                expanded: isExpanded
            })}
            onClick={handleAccordionClick}
        >
            <Heading
                fontSize={isMobile ? 16 : 24}
                fontWeight={session?.sessions?.length == 0 ? 300 : 800}
                tag="h6"
                className="m-0 swap-session__accordion__item__day"
            >
                {session?.weekDay}
            </Heading>

            <span
                className={classNames("swap-session__accordion__item__counter", {
                    hidden: isExpanded || !session.sessions.length,
                    "font-sm": isMobile
                })}
            >
                {t("participantSide.swapSessionsScreen.sessionAccordion.availabilityCounter", {
                    amount: session?.sessions.length
                })}
            </span>

            <span
                className={classNames("swap-session__accordion__item__na font-sm", {
                    "d-none": session.sessions.length
                })}
            >
                {isMobile
                    ? t("participantSide.swapSessionsScreen.sessionAccordion.noAvailabilityMobile")
                    : t("participantSide.swapSessionsScreen.sessionAccordion.noAvailability")}
            </span>

            <div
                ref={contentRef}
                className={classNames("swap-session__accordion__item__body", { expanded: isExpanded })}
                style={isExpanded ? { height: `${contentRef?.current?.scrollHeight}px` } : { height: "0px" }}
            >
                {session?.sessions?.map((session: IAvailableGuestSessionModel) => (
                    <div
                        className="swap-session__accordion__item__session"
                        key={`swap-session-accordion-item-${session.id}`}
                    >
                        <Avatar width={avatarSize} height={avatarSize} url={coach?.photo} className="mr-2" />
                        <div className="swap-session__accordion__item__session__info">
                            <SessionTimeBlock
                                direction="row"
                                withTimezone
                                withTimeExtrabold
                                withTimezoneExtrabold
                                startTime={session.startTime}
                                endTime={session.endTime}
                            />
                            <p className="font-sm">
                                <span>{t("common.with")}</span>
                                &nbsp;
                                <span className="name">
                                    {coach?.firstName}&nbsp;{coach?.lastName}
                                </span>
                            </p>
                        </div>
                        <Button
                            onClick={() => handleSessionItemSelect(session)}
                            variant="outline-secondary"
                            className="ml-auto"
                        >
                            {isMobile ? (
                                <div className="swap-session__accordion__item__session__info__arrow-wrapper">
                                    <ActionArrow color="primary" />
                                </div>
                            ) : (
                                <span>{t("common.select")}</span>
                            )}
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export { SwapSessionAccordion, type TSwapSessionAccordionProps }
