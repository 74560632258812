import { useTranslation } from "react-i18next"

import { ThreeWayAvatar } from "@/entities/three-way"
import Avatar from "@/shared/avatar/Avatar"
import { formatShortMonthDay } from "@/utils/date"

import "./styles.scss"

type Props = {
    session: any
    sessionCount?: number
    onSessionClick: (session) => void
}

const SessionCardItem = ({ session, sessionCount, onSessionClick }: Props) => {
    const { t } = useTranslation()

    const isThreeWay = session?.is_three_way_session

    return (
        <div>
            <div className="schedule-card-item" onClick={() => onSessionClick(session)}>
                <div className="schedule-card-item__image">
                    {isThreeWay ? (
                        <ThreeWayAvatar url={session?.coach?.photo} alt="coach photo" width={40} height={40} />
                    ) : (
                        <Avatar url={session?.coach?.photo} width={40} height={40} alt="coach photo" />
                    )}
                </div>
                <div className="schedule-card-item__info">
                    <span className="d-block color-gray fs-14">
                        {session?.session_type?.name} {sessionCount > 1 && `(${sessionCount})`}
                    </span>
                    <span className="color-gray fs-14">
                        {session?.session_type?.duration} {t("min")}
                        {isThreeWay && ", "}
                    </span>
                    <span className="color-gray fs-14 d-block d-md-inline">
                        {isThreeWay ? `${t("three-way")}` : ""}
                    </span>
                </div>
                <div className="schedule-card-item__time">
                    <span className="color-gray fs-14">
                        {t("Expires:")} {formatShortMonthDay(session?.scheduling_window_end)}
                    </span>
                </div>
            </div>
            {sessionCount > 1 && (
                <div className="schedule-card-item-stack">
                    <div />
                    <div />
                    <div />
                </div>
            )}
        </div>
    )
}

export default SessionCardItem
