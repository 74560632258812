import { type NamedExoticComponent, type ReactElement, memo } from "react"

import classNames from "classnames"

import { type IAuthContext, useAuthContext } from "@/context"
import {
    type IUseTranslation,
    type TUseSubUserList,
    type TUseSubUserListAction,
    useSubUserList,
    useSubUserListAction,
    useTranslation
} from "@/hooks"
import { CoachWelcomeUserListItemImage } from "@/pages/coach-welcome/ui"
import type { TStoredSubUser } from "@/services"
import Avatar from "@/shared/avatar/Avatar"
import { Spinner } from "@/shared/spinner"
import useRoles, { type TUseRoles } from "@/utils/hooks/use-roles"

import "./HeaderDropdownProfileList.styles.scss"

const avatarDimension: number = 19

const spinnerDimension: string = "20px"

const MemoizedProfileList: NamedExoticComponent = memo((): ReactElement => {
    const { subUserList, isSubUserListLoading }: TUseSubUserList = useSubUserList.call({ msDebounce: 1000 })

    const { t }: IUseTranslation = useTranslation()

    const {
        user: { userId: currentUserId, id: currentId }
    }: IAuthContext = useAuthContext()

    const { isCoach }: TUseRoles = useRoles()

    const { handleAction }: TUseSubUserListAction = useSubUserListAction.call({
        shouldNavigateToNewTab: isCoach
    })

    return isSubUserListLoading ? (
        <Spinner width={spinnerDimension} height={spinnerDimension} className="mt-1" />
    ) : (
        <div className="dropdown-profile-list">
            <p className="mb-0 font-extrabold">{t("shared.headerDropdown.switchProfile.label")}&#58;</p>
            <ul
                className={classNames({ "scrollable custom-scrollbar": subUserList.length > 4 })}
                role="menu"
                onKeyDown={handleAction}
                onClick={handleAction}
            >
                {subUserList.map(
                    ({ role, avatarColor, photo, id }: TStoredSubUser): ReactElement => (
                        <li
                            key={`sub-profile-${id}`}
                            data-index={String(id)}
                            tabIndex={0}
                            role="menuitem"
                            className={classNames({ current: id === (currentUserId ?? currentId) })}
                        >
                            {!avatarColor ? (
                                <Avatar
                                    url={photo}
                                    height={avatarDimension}
                                    width={avatarDimension}
                                    alt="coach-avatar"
                                />
                            ) : (
                                <div>
                                    <CoachWelcomeUserListItemImage fillerColor={avatarColor} width={19} height={19} />
                                </div>
                            )}
                            <span>{role}</span>
                        </li>
                    )
                )}
            </ul>
        </div>
    )
})

MemoizedProfileList.displayName = "HeaderDropdownProfileList"

export { MemoizedProfileList as HeaderDropdownProfileList }
