import { type FC, type ReactElement, startTransition, useLayoutEffect, useMemo } from "react"

import { type NavigateFunction, useNavigate } from "react-router-dom"

import { INDEX_URL } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import { CoachLayout as Layout } from "@/layouts"
import PageLoader from "@/shared/spinner/PageLoader"

import { CoachWelcomeContainer as Container } from "./CoachWelcomeContainer"

import "./CoachWelcome.styles.scss"

function renderPageContent(shouldFallbackBeRendered: boolean): ReactElement {
    return !shouldFallbackBeRendered ? (
        <Layout showLogo={false} withoutFooter withAccessibleDropdown>
            <Container />
        </Layout>
    ) : (
        <PageLoader />
    )
}

const Page: FC = (): ReactElement => {
    const { user }: IAuthContext = useAuthContext()

    const navigate: NavigateFunction = useNavigate()

    useLayoutEffect(
        (): void => (!user?.hasSubUsers && startTransition((): void => navigate(INDEX_URL, { replace: true })), void 0),
        [user, navigate]
    )

    return useMemo((): ReactElement => renderPageContent(!user?.hasSubUsers), [user?.hasSubUsers])
}

export { Page as CoachWelcomePage }
