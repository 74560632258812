import classNames from "classnames"

import "./styles.scss"

type Props = {
    className?: string
}

export const SimulabTipBadge = ({ className = "" }: Props) => {
    return <span className={classNames("simulab-tip-badge", className)}>TIP</span>
}
