import classNames from "classnames"

import { FlagIconSvg } from "@/svgs/FlagIcon"

import Button from "./Button"

import "./styles.scss"

type Props = {
    className?: string
    onFlagClick?: () => void
}

export const FlagReportBtn = ({ className = "", onFlagClick }: Props) => {
    return (
        <Button
            variant="default"
            className={classNames("p-0 flag-report-btn", className)}
            onClick={onFlagClick}
            data-testid="flag-report-btn"
        >
            <FlagIconSvg />
        </Button>
    )
}
