import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "$/http"

import { Role } from "@/constants"
import { SimulabMessagesDataApi } from "@/entities/simulab"

import { NEW_MESSAGES_REFETCH_INTERVAL, QueryKey } from "../../constants"
import { SimulabPostMessageBody } from "../../models"
import { Urls } from "../urls"
import { getConversation, getNewMessages } from "../use-simulab-conversation"

type ApiResponse = {
    messageId: number
}

export type SimulabSendMessageMutation = UseMutationResult<ApiResponse, AxiosError, SimulabPostMessageBody>

type Options = {
    onSuccess?: () => void
    onError?: (err: AxiosError) => void
    onSettled?: () => void
}

export function useSendSimulabMessage(conversationId: string, options?: Options): SimulabSendMessageMutation {
    const { onSettled, onError } = options ?? {}
    const queryClient = useQueryClient()
    const queryKey = [QueryKey.SimulabMessages, { id: conversationId }]

    return useMutation(
        (body: SimulabPostMessageBody) => http.post(Urls.sendMessage(conversationId), { message: body.message }),
        {
            onMutate: async newMessage => {
                await queryClient.cancelQueries({
                    queryKey
                })

                const previousMessages = queryClient.getQueryData(queryKey)

                queryClient.setQueryData(queryKey, (old: SimulabMessagesDataApi) => ({
                    bad_rating_feedbacks: old.bad_rating_feedbacks,
                    messages: [
                        ...old.messages,
                        {
                            message: newMessage.message,
                            stage_id: newMessage.stage,
                            conversation_id: +conversationId,
                            id: Date.now(),
                            author_role: Role.Participant,
                            owl_feedback: null
                        }
                    ]
                }))

                queryClient.setQueryData([QueryKey.LasSentMessageParticipant], () => newMessage.message)

                return { previousMessages }
            },
            onSuccess() {
                fetchNewMessages()
                let intervalId
                intervalId = setInterval(fetchNewMessages, NEW_MESSAGES_REFETCH_INTERVAL)
                async function fetchNewMessages() {
                    try {
                        const data = await queryClient.fetchQuery({
                            queryKey: [QueryKey.SimulabNewMessages, { id: conversationId }],
                            queryFn: getNewMessages,
                            staleTime: 0
                        })
                        await queryClient.fetchQuery({
                            queryKey: [QueryKey.SimulabConversation, { id: conversationId }],
                            queryFn: getConversation,
                            staleTime: 0
                        })

                        if (
                            data.messages.length ||
                            data.bad_rating_feedbacks.length ||
                            data.errors.length ||
                            data.is_make_plan_message
                        ) {
                            queryClient.invalidateQueries([QueryKey.SimulabMessages, { id: conversationId }])
                            queryClient.invalidateQueries([QueryKey.SimulabConversation, { id: conversationId }])
                            clearInterval(intervalId)
                        }
                    } catch (error) {
                        clearInterval(intervalId)
                        console.error("Error fetching data:", error)
                    }
                }
            },
            onError: (err: AxiosError, newMessage, context) => {
                queryClient.setQueryData(queryKey, context.previousMessages)
                onError?.(err)
            },
            onSettled: async () => {
                onSettled?.()
            }
        }
    )
}
