import { RefObject } from "react"

import { matchPath } from "react-router-dom"
import sanitizeHtml from "sanitize-html"

import { Option } from "$/models/option"
import { pluralize } from "$/utils/functions"

import { Role, Status, userRoutesPath } from "@/constants"
import { ParticipantData } from "@/models/participant-data-coach"

export const defineUser = (data: any) => {
    const user = Role.Participant.toLowerCase() in data ? data.participant : data.coach
    const role = Role.Participant.toLowerCase() in data ? Role.Participant : Role.Coach

    return {
        user,
        role
    }
}

export const uniqueObjectsArray = (array: any[], key: string) => {
    return [...new Map(array?.map(item => [item[key], item])).values()]
}

export const sanitizeContent = (content: string, attributes?: sanitizeHtml.IOptions) =>
    sanitizeHtml(content, {
        allowedAttributes: {
            "*": ["style"]
        },
        ...attributes
    })

export const getStatusText = (status: Status) => {
    const statuses = {
        in_progress: "In Progress",
        complete: "Completed",
        queued: "Queued",
        open: "Open"
    }

    return statuses[status]
}

export const isEmptyObject = (obj: any) => Object.keys(obj).length === 0

export const getYears = (days: number) => Math.floor(days / 365)

export const containsHTML = str => /<[a-z][\s\S]*>/i.test(str)
export const getTimeInRoleDuration = ({
    participant,
    capitalized = false
}: {
    participant: ParticipantData
    capitalized?: boolean
}) => {
    const { yearsInCurrentRole, monthsInCurrentRole } = participant

    const year = capitalized ? "Year" : "year"
    const month = capitalized ? "Month" : "month"

    let duration = ""

    if (yearsInCurrentRole) {
        duration += `${yearsInCurrentRole} ${pluralize(yearsInCurrentRole, year)}`
    }
    if (monthsInCurrentRole) {
        if (duration.length > 0) {
            duration += " "
        }
        duration += `${monthsInCurrentRole} ${pluralize(monthsInCurrentRole, month)}`
    }

    if (duration.length === 0) {
        duration = `0 ${month}`
    }

    return duration
}

export const capitalized = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)

export const getLanguageOptions = (languages: { [langKey: string]: string }) => {
    const defaultLanguage = [new Option({ value: "en", label: "English" })]

    const langs = Object.entries<string>(languages).map(([langKey, label]) => new Option({ value: langKey, label }))

    if (!langs.length) {
        return defaultLanguage
    }

    return langs
}

export const joinDigitsWithDot = inputStr => {
    const digits = inputStr.match(/\d+/g)

    return digits ? digits.join(".") : ""
}

export const scrollToBottom = (ref: RefObject<HTMLElement | null>, options: ScrollIntoViewOptions = {}) => {
    ref.current?.scrollIntoView({ behavior: "auto", ...options })
}

export const matchingRouteByRole = (role: Role, url: string) =>
    userRoutesPath[role].some(route => Boolean(matchPath(route, url)))
