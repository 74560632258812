import { useCallback } from "react"

import Heading from "$/components/Heading/Heading"
import { isEmptyString } from "$/utils/gates"

import { useZoom } from "@/entities/coach-calendar/api/use-zoom"
import { CoachLayout } from "@/layouts"
import DateWidget from "@/shared/DateWidget"
import Button from "@/shared/button/Button"
import Card from "@/shared/card/Card"
import Spinner from "@/shared/spinner/Spinner"
import { TEmptyCallback, emptyCallback } from "@/shared/types/functions"
import ActiveCheckMarkSvg from "@/svgs/ActiveCheckMarkSvg"

import LeftSidebar from "./ui/LeftSidebar"

import "./styles.scss"

const CoachZoomAccount = () => {
    const { data, isFetching } = useZoom()

    const hasUrl: boolean = !isEmptyString(data?.url)

    const handleSwitchAccountsButtonClick: TEmptyCallback = useCallback(
        (): void => (data?.url ? (window.open(data?.url, "_blank", "noopener,noreferrer"), void 0) : emptyCallback()),
        [data]
    )

    return (
        <CoachLayout
            leftSidebar={<LeftSidebar />}
            headerTitle={<DateWidget className="coach-date-title" />}
            withContainer={false}
            fullWidth
            align="left"
        >
            <div className="coach-calendars-wrapper coach-zoom-account">
                {isFetching ? (
                    <Spinner />
                ) : (
                    <>
                        <Heading tag="h1" className="mb-2" fontSize={36}>
                            My Zoom Account
                        </Heading>
                        <p className="color-gray fs-14 mb-0">
                            The Zoom account associated with your Wiser profile is shown below. <br />
                            This is the account you will use to access your video coaching sessions.
                        </p>
                        <Card className="mt-60">
                            <div className="d-flex align-items-center">
                                {hasUrl ? (
                                    <p className="text-center m-0 color-gray font-italic">No accounts linked yet.</p>
                                ) : (
                                    <>
                                        <div className="flex-1">
                                            <span>{data?.profile_name}</span>
                                        </div>
                                        {data?.profile_name && (
                                            <div className="d-flex align-items-center">
                                                <span className="color-brand font-extrabold mr-2">Success</span>
                                                <ActiveCheckMarkSvg />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </Card>
                        {hasUrl && (
                            <Button variant="brand" className="mt-60" onClick={handleSwitchAccountsButtonClick}>
                                <span className="font-extrabold">Link Zoom Account</span>
                            </Button>
                        )}
                    </>
                )}
            </div>
        </CoachLayout>
    )
}

export default CoachZoomAccount
