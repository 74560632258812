import useWaitingListText from "@/api/use-waiting-list-text"

import "./styles.scss"

const WaitingListHeader = () => {
    const { data } = useWaitingListText()

    return (
        <a href={data?.websiteLink} target="_blank" className="text-center m-0" rel="noreferrer">
            <span className="waiting-list__btn btn btn--brand-hover">{data?.textInfoButtonData}</span>
        </a>
    )
}

export default WaitingListHeader
