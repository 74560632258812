import { FC } from "react"

import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"

import { ITrackPractice } from "Manager/chapter/models/types"

import TrackPracticeColumns from "./TrackPractice/TrackPracticeColumns"
import TrackPracticeColumnsMobile from "./TrackPractice/TrackPracticeColumnsMobile"
import TrackPracticeDescription from "./TrackPractice/TrackPracticeDescription"

interface IProps {
    component: ITrackPractice
    participantName: string
}

const TrackPracticeResponses: FC<IProps> = ({ component, participantName }) => {
    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    return (
        <div className="component-item component-container">
            {participantName && (
                <h1 className="question-responses-text text-center">{participantName}&apos;s Practice Tracker</h1>
            )}
            {matches && <h6 className="text-center">{getValueFromTranslatedObject(component?.title)}</h6>}
            <TrackPracticeDescription description={getValueFromTranslatedObject(component?.assignment_description)} />
            {matches ? (
                <TrackPracticeColumnsMobile columns={component?.columns} rows={component?.answer_data?.rows} />
            ) : (
                <TrackPracticeColumns columns={component.columns} rows={component?.answer_data?.rows} />
            )}
        </div>
    )
}

export default TrackPracticeResponses
