import { useEffect, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { matchPath, useLocation } from "react-router-dom"

import { isNullOrUndefined } from "$/utils/gates"

import { StepUrls, onboardingProfileSetupURL } from "@/components/onboarding/constants"
import { AuthUrls, ParticipantUrls, ServerStateKeys } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"

import ReflectionJourneyModal from "./ReflectionJourneyModal"

import "./styles.scss"

type Props = {
    show?: boolean
    moduleId?: number
    sessionId?: number
    showOnlyOnTrigger?: boolean
    onClose?: () => void
}

const PostReflectionModalWrapper = ({
    show = false,
    moduleId,
    sessionId,
    showOnlyOnTrigger = false,
    onClose
}: Props) => {
    const location = useLocation()
    const queryClient = useQueryClient()
    const { user, refetchUser }: IAuthContext = useAuthContext()
    const [showJourneyReflectionModal, setShowJourneyReflectionModal] = useState(show)

    const activatePostModuleReflection = () => {
        const showModal = !isNullOrUndefined(user?.reflectionPopupData)

        const dontShowModalPage = [
            onboardingProfileSetupURL,
            ...Object.values(StepUrls),
            ParticipantUrls.OFFBOARDING,
            ParticipantUrls.END_OF_JOURNEY_PAGE,
            ParticipantUrls.WAITING_LIST,
            AuthUrls.LOGIN
        ].some(url => matchPath(url, location.pathname))

        if (dontShowModalPage) {
            return
        }

        setShowJourneyReflectionModal((showModal && !showOnlyOnTrigger) || show)
    }

    useEffect(() => {
        activatePostModuleReflection()
    }, [user, location.pathname, show])

    const onCloseJourneyReflection = () => {
        setShowJourneyReflectionModal(false)
        queryClient.invalidateQueries([ServerStateKeys.CoachingSession])
        refetchUser()
        onClose?.()
    }

    return (
        <>
            {showJourneyReflectionModal && (
                <ReflectionJourneyModal
                    show={showJourneyReflectionModal}
                    moduleId={user?.reflectionPopupData?.moduleId || moduleId}
                    sessionId={user?.reflectionPopupData?.coachingSessionId || sessionId}
                    onClose={onCloseJourneyReflection}
                />
            )}
        </>
    )
}

export default PostReflectionModalWrapper
