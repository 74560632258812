import classNames from "classnames"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Heading from "$/components/Heading/Heading"

import { ELocalStorageServiceItemKey, StorageService } from "@/services"
import Button from "@/shared/button/Button"
import Card from "@/shared/card/Card"
import CheckBox from "@/shared/checkbox/CheckBoxControlled"
import Modal from "@/shared/modal"
import AttentionNotificationBox from "@/shared/notification/AttentionNotificationBox"

import { isEnabledArchiveModal } from "../utils/functions"

import "./styles.scss"

type Props = {
    isOpen: boolean
    archiveText: string
    archiveTitle: string
    onClose: () => void
    onArchive: () => void
}

const storageService: StorageService = new StorageService()

export const ArchiveModal = ({ isOpen, archiveText, archiveTitle, onClose, onArchive }: Props) => {
    const { t } = useTranslation()
    const { control, watch } = useForm()

    const popUpCheckBox = watch("show-popup-checkbox")

    const onArhiveClick = () => {
        if (popUpCheckBox) {
            storageService.setItem<string>(ELocalStorageServiceItemKey.ArchiveModalVisible, "show")
        }

        onArchive()
    }

    return (
        <Modal
            onClose={onClose}
            show={isOpen && isEnabledArchiveModal(storageService)}
            headerContent={
                <Heading className="text-center m-0 p-3" fontSize={24}>
                    {t("Archive Entry")}
                </Heading>
            }
            modalBodyClass="archive-modal"
            enableOutSideClick={false}
        >
            <div className={classNames("archive-modal__body position-relative")}>
                <AttentionNotificationBox className="mb-40 attention-banner">
                    <p className="m-0">
                        {t("Are you sure you want to archive this {{archiveTitle}} ?", { archiveTitle })}
                    </p>
                </AttentionNotificationBox>

                <div className="card-example mb-40">
                    <Card readOnly>
                        <p className="m-0">{archiveText}</p>
                    </Card>
                </div>

                <p className="mb-40 text-center color-gray font-italic">
                    {t(
                        "Once this entry is archived it will be hidden from the default view, but you will be able to access it and / or unarchive it as needed it by using the “View” dropdown."
                    )}
                </p>

                <div className="text-center mt-40 mb-3">
                    <Button onClick={onArhiveClick} className="mr-0 mb-2 mr-md-3 mb-md-0">
                        {t("Archive")}
                    </Button>
                    <Button variant="outline" onClick={onClose}>
                        {t("Cancel")}
                    </Button>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <CheckBox
                        control={control}
                        name="show-popup-checkbox"
                        label={t("Don’t show me this pop-up again.")}
                    />
                </div>
            </div>
        </Modal>
    )
}
