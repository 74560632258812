import { MouseEvent, forwardRef, useEffect, useState } from "react"

import classNames from "classnames"

import { FeedbackTypes, SimulabOwlFeedback, SimulabReport, SimulabTipBadge } from "@/entities/simulab"
import Accordion from "@/shared/accordion/Accordion"
import Button from "@/shared/button/Button"
import { FlagReportBtn } from "@/shared/button/FlagReportBtn"
import ChevronToggle from "@/shared/chevron-toggle/ChevronToggle"

import "./styles.scss"

type Props = {
    feedback: SimulabOwlFeedback
    isRecent: boolean
    isLastFeedback: boolean
    selectedMessage: number
    showBubbleArrow: boolean
    onSelectMessage: (id: number) => void
    onReportClick: (report: SimulabReport) => void
    onToggle?: (e: MouseEvent<HTMLDivElement> & { messageId: number }) => void
}

export const StageFeedbackAccordion = forwardRef<HTMLDivElement, Props>(
    (
        {
            feedback,
            isRecent,
            selectedMessage,
            isLastFeedback,
            showBubbleArrow,
            onSelectMessage,
            onReportClick,
            onToggle
        }: Props,
        ref
    ) => {
        const [active, setActive] = useState(false)

        useEffect(() => {
            if (isLastFeedback && isRecent) {
                setActive(true)
                return
            }
            setActive(selectedMessage === feedback.messageId)
        }, [feedback, selectedMessage, isLastFeedback, isRecent])

        const onClickFeedback = () => {
            onSelectMessage(feedback.messageId)
            setActive(toggle => !toggle)
            if (isLastFeedback) {
                onToggle?.({ messageId: feedback.messageId } as any)
            }
        }

        const handleToggle = e => {
            e.stopPropagation()

            setActive(toggle => !toggle)
            if (isLastFeedback) {
                onToggle?.({ ...e, messageId: feedback.messageId })
            }
        }

        const onFlagClick = () => {
            onReportClick({
                type: FeedbackTypes.PRACTICE_LAB_OWL_FEEDBACK,
                title: feedback.feedbackData.feedbackTitle,
                emoji: feedback.feedbackData.feedbackEmoji,
                text: feedback.feedbackData.feedbackSummary,
                tip: feedback.feedbackData.feedbackTip,
                message_id: feedback.messageId
            })
        }

        const activeFeedback = isRecent

        return (
            <div className={classNames("stage-feedback-accordion", { is_last: isLastFeedback })} ref={ref}>
                <div
                    className={classNames("stage-feedback-header", { is_recent: activeFeedback, is_active: active })}
                    onClick={onClickFeedback}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="stage-feedback-header__title">
                            {feedback.feedbackData.feedbackEmoji} {feedback.feedbackData.feedbackTitle}
                        </span>
                        <Button variant="default" className="p-0" onClick={handleToggle}>
                            <ChevronToggle
                                variant={activeFeedback ? "light" : "accent"}
                                active={active}
                                width={13}
                                height={13}
                            />
                        </Button>
                    </div>
                </div>
                <Accordion withTransition={!(isLastFeedback && isRecent)} active={active}>
                    <div
                        className={classNames("stage-feedback-accordion__body fs-14", {
                            is_recent: activeFeedback,
                            is_last: isLastFeedback,
                            active
                        })}
                    >
                        <span className={feedback?.feedbackData?.feedbackTip ? "mb-10" : ""}>
                            {feedback.feedbackData.feedbackSummary}
                        </span>
                        {feedback.feedbackData.feedbackTip && (
                            <div>
                                <SimulabTipBadge />{" "}
                                <span className="color-gray">{feedback.feedbackData.feedbackTip}</span>
                            </div>
                        )}
                        <FlagReportBtn className="stage-feedback-accordion__flag" onFlagClick={onFlagClick} />
                        {isLastFeedback && active && showBubbleArrow && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="16"
                                viewBox="0 0 44 16"
                                className="stage-feedback-accordion__body-bubble-arrow"
                                fill="none"
                            >
                                <path
                                    d="M11.5471 0H32.8834C33.9588 0 34.2762 1.46535 33.2972 1.91037L27.9109 4.35869C27.0032 4.77129 26.2823 5.50802 25.8895 6.42448L25.4847 7.369C24.129 10.5324 19.6764 10.6204 18.1967 7.51305L17.5717 6.2006C17.2006 5.42117 16.5872 4.78274 15.8233 4.38066L11.0813 1.88492C10.1575 1.39869 10.5031 0 11.5471 0Z"
                                    fill="white"
                                />
                                <path
                                    d="M43 2.5H33.4077C30.5389 2.5 27.8421 3.86759 26.1469 6.18194L23.6135 9.64085C22.8144 10.7318 21.1856 10.7318 20.3865 9.64085L17.8531 6.18194C16.1579 3.8676 13.4611 2.5 10.5923 2.5H0.5"
                                    stroke={isRecent ? "#0404CB" : "#e3e3e3"}
                                    strokeLinecap="round"
                                />
                            </svg>
                        )}
                    </div>
                </Accordion>
            </div>
        )
    }
)
