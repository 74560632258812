import type { FC, ReactElement } from "react"

import Spinner from "@/shared/spinner/Spinner"

const LoadingFallback: FC = (): ReactElement => (
    <div className="mx-auto my-3 w-min">
        <Spinner />
    </div>
)

export { LoadingFallback as CoachWelcomeLoadingFallback }
