import { memo, useMemo } from "react"

import { Status } from "@/constants"
import CheckMarkModuleIcon from "@/svgs/CheckMarkModule"
import LockedModule from "@/svgs/LockedModule"
import ProgressModuleDot from "@/svgs/ProgressModuleDot"

type Props = {
    status: Status
    isActive: boolean
}

export const ModuleStatus = memo(({ status, isActive }: Props) => {
    const Icon = useMemo(() => {
        const inProgressOrOpen = status === Status.InProgress || status === Status.Open
        const isQueued = status === Status.Queued
        const isCompleted = status === Status.Completed

        if (isQueued) {
            return <LockedModule isActive={isActive} />
        }

        if (isCompleted) {
            return <CheckMarkModuleIcon isActive={isActive} />
        }

        if (inProgressOrOpen) {
            return <ProgressModuleDot isActive={isActive} />
        }
    }, [status, isActive])

    return <div className="module-navigation__item_status">{Icon}</div>
})
