import { Coachee } from "./coachee"
import { SimulabMessage } from "./simulab-message"

export enum FeedbackTypes {
    PRACTICE_LAB_GENERAL = "practice_lab_general",
    PRACTICE_LAB_OWL_FEEDBACK = "practice_lab_owl_feedback",
    PRACTICE_LAB_COACHEE_MESSAGE = "practice_lab_coachee_message",
    PRACTICE_LAB_REJECTED_QUESTION = "practice_lab_rejected_question"
}

export enum FeedbackSeverity {
    MILD = "mild",
    MEDIUM = "medium",
    SEVERE = "severe"
}

export type SimulabFeedbackReport = {
    type: FeedbackTypes.PRACTICE_LAB_OWL_FEEDBACK
    title: string
    emoji: string
    text: string
    tip: string
    message_id: number
}

export type CoacheeReport = {
    type: FeedbackTypes.PRACTICE_LAB_COACHEE_MESSAGE
    coachee: Coachee
    message: SimulabMessage
    message_id: number
}

export type GeneralReport = {
    type: FeedbackTypes.PRACTICE_LAB_GENERAL
}

export type RejectedMessageReport = {
    type: FeedbackTypes.PRACTICE_LAB_REJECTED_QUESTION
    message: string
    message_id: number
}

export type SimulabReport = SimulabFeedbackReport | CoacheeReport | GeneralReport | RejectedMessageReport

export type SimulabFeedback = {
    text: string
    message_id?: number
    context: FeedbackTypes
    severity: FeedbackSeverity
}
