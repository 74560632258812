import { FC } from "react"

import { ParticipantData } from "@/models/participant-data-coach"
import Avatar from "@/shared/avatar/Avatar"

interface IProps {
    profile: ParticipantData
    onClick: () => void
}

const ProfileCard: FC<IProps> = ({ profile, onClick }) => {
    return (
        <div className="profile-card" onClick={onClick}>
            <Avatar url={profile?.photo} />

            <div className="profile-card__left">
                <p className="m-0 mb-1 font-bold">{profile?.firstName}</p>
                <span>view profile</span>
            </div>
        </div>
    )
}

export default ProfileCard
