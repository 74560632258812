import { type MutableRefObject, useCallback, useEffect, useRef } from "react"

import { USER_ACTIVITY_DEBOUNCE_TIME, USER_ACTIVITY_EXPIRE_TIME } from "@/constants"
import { type TEmptyCallback, emptyCallback } from "@/shared/types/functions"

type Options = {
    onInactivity: TEmptyCallback
    onActivity?: TEmptyCallback
    inactivityTime?: number
    enabled?: boolean
    debounceTime?: number
}

const events: string[] = ["click", "mousemove", "keypress", "scroll", "touchstart"]

const useUserActivity = ({
    onInactivity,
    onActivity = emptyCallback,
    enabled = true,
    inactivityTime = USER_ACTIVITY_EXPIRE_TIME,
    debounceTime = USER_ACTIVITY_DEBOUNCE_TIME
}: Options): void => {
    const inactivityTimerRef: MutableRefObject<ReturnType<typeof setTimeout>> =
        useRef<ReturnType<typeof setTimeout>>(null)
    const activityDebounceTimerRef: MutableRefObject<ReturnType<typeof setTimeout>> =
        useRef<ReturnType<typeof setTimeout>>(null)

    const resetInactivityTimer: TEmptyCallback = useCallback((): void => {
        clearTimeout(inactivityTimerRef.current)
        inactivityTimerRef.current = setTimeout(onInactivity, inactivityTime)
    }, [inactivityTime, onInactivity])

    const handleActivity: TEmptyCallback = useCallback((): void => {
        clearTimeout(activityDebounceTimerRef.current)
        activityDebounceTimerRef.current = setTimeout(onActivity, debounceTime)
    }, [debounceTime, onActivity])

    useEffect((): TEmptyCallback => {
        const handleUserActivity = () => {
            resetInactivityTimer()
            handleActivity()
        }

        if (!Number.isNaN(inactivityTime) && inactivityTime > 0 && enabled) {
            events.forEach((e: string): void => window.addEventListener(e, handleUserActivity))
            resetInactivityTimer()
        }

        return (): void => {
            events.forEach((e: string): void => window.removeEventListener(e, handleUserActivity))
            clearTimeout(inactivityTimerRef.current)
            clearTimeout(activityDebounceTimerRef.current)
        }
    }, [onInactivity, onActivity, inactivityTime, debounceTime, enabled, resetInactivityTimer, handleActivity])

    return void 0
}

export { useUserActivity, Options as TUseUserActivityParams }
