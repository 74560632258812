import { useCallback } from "react"

import { type UseMutationResult, useMutation } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

import { http } from "$/http"

import Urls from "@/api/urls"

import type {
    ICoachAccountCalendarToRelinkModel,
    ICoachAccountsToRelinkModel
} from "./use-coach-accounts-to-relink-query"

interface ICoachAccountsRelinkingConfirmMutationProfileApiData {
    profile_id: string
    connected: boolean
}

interface ICoachAccountsRelinkingConfirmMutationApiData {
    calendar_profiles: ICoachAccountsRelinkingConfirmMutationProfileApiData[]
    zoom_profile: ICoachAccountsRelinkingConfirmMutationProfileApiData
}

type TUseCoachAccountsRelinkingConfirmMutation = UseMutationResult<
    AxiosResponse<ICoachAccountsRelinkingConfirmMutationApiData>,
    AxiosError
>

function coachAccountRelinkingConfirmAdapter(
    model: ICoachAccountsToRelinkModel
): ICoachAccountsRelinkingConfirmMutationApiData {
    return {
        zoom_profile: { profile_id: model.zoom.profileId, connected: true },
        calendar_profiles: model.calendars.map(
            (c: ICoachAccountCalendarToRelinkModel): ICoachAccountsRelinkingConfirmMutationProfileApiData => ({
                profile_id: c.profileId,
                connected: true
            })
        )
    }
}

async function coachAccountRelinkingConfirmFetcher(
    params: ICoachAccountsRelinkingConfirmMutationApiData
): Promise<AxiosResponse> {
    return http.post(Urls.postCoachAccountsRelinkingConfirm(), params)
}

function useCoachAccountsRelinkingConfirmMutation(): ReturnType<() => TUseCoachAccountsRelinkingConfirmMutation> {
    return useMutation(
        useCallback(
            (params: ICoachAccountsToRelinkModel) =>
                coachAccountRelinkingConfirmFetcher(coachAccountRelinkingConfirmAdapter(params)),
            []
        )
    )
}

export {
    useCoachAccountsRelinkingConfirmMutation,
    type TUseCoachAccountsRelinkingConfirmMutation,
    type ICoachAccountsRelinkingConfirmMutationApiData,
    type ICoachAccountsRelinkingConfirmMutationProfileApiData
}
