import classNames from "classnames"

import "./styles.scss"

type Props = {
    className?: string
    size?: "s" | "m" | "l"
    variant?: "primary" | "outlined"
}

export const BetaStatusTag = ({ className = "", size = "m", variant = "primary" }: Props) => {
    const sizes = {
        s: {
            width: 34,
            height: 18
        },
        m: {
            width: 46,
            height: 28
        }
    }

    return (
        <div className={classNames("beta-status-tag", className, size, variant)} style={sizes[size]}>
            <span>beta</span>
        </div>
    )
}
