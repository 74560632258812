import { StrictMode } from "react"

import * as Sentry from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

import useInitDataDog from "$/hooks/use-init-datadog"
import "$/i18n/i18n"

import { OnboardingStoreProvider } from "@/components/onboarding/onboardingStore"
import { ROOT_ELEMENT_SELECTOR } from "@/constants"
import { AuthContextProvider, GlobalStoreContexProvider } from "@/context"

import Main from "./components/Main"
import { queryClient } from "./query-client"
import { ErrorBoundary } from "./shared/error-boundary/component"

const pageContainer: HTMLDivElement = document.getElementById(ROOT_ELEMENT_SELECTOR) as HTMLDivElement
const root = createRoot(pageContainer)

const MainContainerWithProfiler = Sentry.withProfiler(Main)

const MainApp = () => {
    useInitDataDog()

    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <ErrorBoundary>
                    <GlobalStoreContexProvider>
                        <AuthContextProvider>
                            <OnboardingStoreProvider>
                                <MainContainerWithProfiler />
                            </OnboardingStoreProvider>
                        </AuthContextProvider>
                    </GlobalStoreContexProvider>
                </ErrorBoundary>
            </QueryClientProvider>
        </BrowserRouter>
    )
}

if (pageContainer) {
    root.render(
        <StrictMode>
            <MainApp />
        </StrictMode>
    )
}
