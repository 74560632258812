import { useCallback } from "react"

import { GoalsSortType } from "@/shared/types/sort"

import { CoachingGoalModel } from "../model/types/coaching-goal"

import { CoachingGoalCard } from "./CoachingGoalCard"

type Props = {
    goals: CoachingGoalModel[]
    archivedGoals: number[]
    sort: GoalsSortType
    onArchiveClick: (index: number, id: number) => void
    onBlur: (index: number) => void
}

export const CoachingGoalList = ({ goals, archivedGoals, sort, onArchiveClick, onBlur }: Props) => {
    const isArchived = useCallback(
        id => {
            return archivedGoals?.includes(id)
        },
        [archivedGoals]
    )

    return (
        <>
            {goals.map((goal, index) => (
                <div className="mb-2" key={goal.id}>
                    <CoachingGoalCard
                        // @ts-expect-error legacy prop here
                        sort={sort}
                        goalIndex={index}
                        isArchived={isArchived(goal.id)}
                        initialValue={goal.description}
                        onBlur={onBlur}
                        onArchiveClick={onArchiveClick}
                    />
                </div>
            ))}
        </>
    )
}
