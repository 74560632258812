export interface ISummaryApi {
    summary: {
        en: string
    }
    summary_subtitle: {
        en: string
    }
    summary_title?: {
        en: string
    }
    short_summary?: {
        en: string
    }
}

export interface ISummaryLikertGroup {
    rank_from: string
    rank_to: string
    summary_title?: {
        en: string
    }
    summary_subtitle: {
        en: string
    }
    summary: {
        en: string
    }
    short_summary?: {
        en: string
    }
    uuid: string
}

export class Summary {
    summary: { en: string }

    summarySubtitle: { en: string }

    summaryTitle: { en: string }

    constructor(data: ISummaryApi) {
        this.summary = data.summary
        this.summarySubtitle = data.summary_subtitle
        this.summaryTitle = data?.summary_title ?? data?.short_summary
    }
}

export class SummaryLikert extends Summary {
    rankFrom: string

    rankTo: string

    constructor(data: ISummaryLikertGroup) {
        super(data)
        this.rankFrom = data.rank_from
        this.rankTo = data.rank_to
    }
}
