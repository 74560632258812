import { OnboardingSteps } from "@/components/onboarding/constants"
import { ThreeWayManagerApi } from "@/entities/three-way"

export class OnboardingStep {
    availableSteps: OnboardingSteps[]

    previousSteps: OnboardingSteps[]

    session?: {
        id: number
        duration: number
        name: string
        session_type?: string
        enrollment_rank?: number
        is_three_way_session: boolean
        skip_coach_matching?: boolean
        three_way_session_manager: null | ThreeWayManagerApi
    }

    constructor(data) {
        this.availableSteps = data.available_steps
        this.previousSteps = data.previous_steps
        this.session = data.session
    }
}
