import { type FC, type ReactElement } from "react"

import classNames from "classnames"

import { type IUseTranslation, useTranslation } from "@/hooks"

type TSwapSessionBadgeProps = {
    isVisible: boolean
}

const SwapSessionBadge: FC<TSwapSessionBadgeProps> = ({ isVisible }: TSwapSessionBadgeProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className={classNames("swap-session__accordion__badge", { hidden: !isVisible })}>
            <span className="font-sm">
                {t("participantSide.swapSessionsScreen.sessionAccordion.preferredWeekBadge.label")}
            </span>
        </div>
    )
}

export { SwapSessionBadge }
