import { useCallback } from "react"

import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "$/http"

import { ServerStateKeys } from "../constants"
import { PairingQuestion } from "../models"
import { PairingQuestionApi } from "../types"

import { Urls } from "./urls"

const getPairingQuestion = async () => {
    const { data } = await http.get(Urls.pairingQuestions())
    return data.results
}

export function usePairingQuestions() {
    return useQuery<PairingQuestionApi[], AxiosError, PairingQuestion[]>(
        [ServerStateKeys.PairingQuestion],
        getPairingQuestion,
        {
            select: useCallback(data => data.map(item => new PairingQuestion(item)), [])
        }
    )
}

export function useSubmitPairingQuestion() {
    return useMutation((values: any) => http.post(Urls.submitPairingQuestion(), values))
}
