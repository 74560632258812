import { ReactNode } from "react"

import { useTranslation } from "react-i18next"

import useSpotCoaching from "@/api/use-spot-coaching"
import { CARE_TEAM_EMAIL, ERROR_TEXT } from "@/constants"
import { SimpleLayout } from "@/layouts"
import ErrorTooltip from "@/shared/error-page/ErrorTooltip"
import OwlErrorLogo from "@/svgs/OwlErrorLogo"

type Props = {
    errorContent: ReactNode
}

export const ErrorPage = ({ errorContent = ERROR_TEXT }: Props) => {
    const { t } = useTranslation()
    const { data: productType, isLoading, isError } = useSpotCoaching()

    return (
        <SimpleLayout withProfileHeader>
            <div className="d-flex justify-content-center align-items-center">
                <div className="mt-4">
                    <ErrorTooltip>{errorContent}</ErrorTooltip>
                    <div className="mt-5 d-flex justify-content-center">
                        <OwlErrorLogo />
                    </div>
                </div>
                <div className="help-text-bottom w-100 text-center">
                    <span className="color-gray">{t("Need help?")} </span>
                    {(!isLoading && !productType) || isError ? (
                        <a href={CARE_TEAM_EMAIL} className="color-brand font-bold">
                            {t("Contact Wiser Care Team")}
                        </a>
                    ) : (
                        <a href={`mailto:${productType?.supportEmail}`} className="color-brand font-bold">
                            {t("Contact")} {productType?.supportTeamName}
                        </a>
                    )}
                </div>
            </div>
        </SimpleLayout>
    )
}
