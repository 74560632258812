import { useCallback, useMemo } from "react"

import { type UseQueryOptions, type UseQueryResult, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { ServerStateKeys } from "@/constants"

import { type TCoachDashboardApiUtils, coachDashboardApiUtils } from "./utils"

import type {
    ICoachSessionWithoutAttendanceMarksDto as IDto,
    ICoachSessionWithoutAttendanceMarksModel as IModel
} from "."

type TQuery = UseQueryResult<IModel[], AxiosError>
type TQueryParams = UseQueryOptions
type TReturn = ReturnType<(params: TQueryParams) => TQuery>

const {
    adaptCoachSessionsWithoutAttendanceMarks: adapter,
    fetchCoachSessionsWithoutAttendanceMarks: fetcher
}: TCoachDashboardApiUtils = coachDashboardApiUtils

function useHook(params: TQueryParams = {}): TReturn {
    return useQuery<IDto[], AxiosError, IModel[], ServerStateKeys[]>(
        useMemo((): [ServerStateKeys] => [ServerStateKeys.CoachSessionWithoutAttendanceMarks], []),
        useCallback(fetcher, []),
        {
            // @ts-expect-error no error here, react-query types mess
            select: useCallback(adapter, []),
            ...params
        }
    )
}

export {
    useHook as useCoachSessionsWithoutAttendanceMarksQuery,
    type TQuery as TCoachSessionWithoutAttendanceMarksQuery
}
