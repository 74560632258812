import { Navigate } from "react-router-dom"

import { ParticipantUrls } from "@/constants"
import { useAuthContext } from "@/context"
import { SimpleLayout } from "@/layouts"
import { FullSpinner } from "@/shared/spinner"

const PostJourneyRedirect = () => {
    const { user, isLoading } = useAuthContext()

    return (
        <SimpleLayout withProfileHeader>
            {isLoading && !user ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                    <FullSpinner />
                </div>
            ) : (
                <Navigate to={ParticipantUrls.END_OF_JOURNEY_PAGE} replace />
            )}
        </SimpleLayout>
    )
}

export default PostJourneyRedirect
