import { Fragment, useCallback, useMemo, useState } from "react"

import { matchPath, useLocation, useParams } from "react-router-dom"

import { isNullOrUndefined } from "$/utils/gates"

import { dashboardURL } from "@/components/onboarding/constants"
import { ParticipantUrls, Status } from "@/constants"
import User, { UserEnrollModule } from "@/models/user"

import { ModuleItem } from "./ModuleItem"

type Props = {
    user: User
    collapseModules: boolean
    onModuleClick: (module: UserEnrollModule, moduleIndex: number) => void
    onCollapseUnassignModulesClick: () => void
}

export const ModuleList = ({ user, collapseModules, onModuleClick, onCollapseUnassignModulesClick }: Props) => {
    const location = useLocation()
    const params = useParams()
    const [removeBorderIndex, setRemoveBorderIndex] = useState(null)

    const isDashboardPage = useMemo(
        () => Boolean(matchPath(ParticipantUrls.DASHBOARD_MODULE, location.pathname)),
        [location.pathname]
    )

    const hasUnassignedModules = useMemo(
        () => user?.journeyLength !== user?.enrolledModules?.length,
        [user?.journeyLength, user?.enrolledModules?.length]
    )
    const unassignedModuleCount = useMemo(
        () => user?.journeyLength - user?.enrolledModules?.length,
        [user?.journeyLength, user?.enrolledModules?.length]
    )

    const findByRank = useCallback(
        (index: number) => user?.enrolledModules?.find(module => module.rank === index) ?? null,
        [user?.enrolledModules]
    )

    const modulesList = useMemo(
        () => Array.from({ length: user?.journeyLength }, (_, i) => findByRank(i + 1)),
        [findByRank, user?.journeyLength]
    )

    const lastEnrolledModule = useMemo(
        // @ts-expect-error jest doesn't get findLast, causes compilation error
        () => user?.enrolledModules?.findLast(module => module.status !== Status.Unassigned),
        [user?.enrolledModules]
    )

    const canNavigate = useCallback(
        (rank: number) => {
            return rank <= lastEnrolledModule?.rank
        },
        [lastEnrolledModule?.rank]
    )

    const onHover = useCallback((index: number) => {
        setRemoveBorderIndex(index)
    }, [])

    return (
        <ol className={`module-navigation ${location.pathname.includes(dashboardURL) ? "active" : ""}`}>
            {modulesList?.map((module, index) => (
                <Fragment key={module?.rank ?? index + 1}>
                    {(!isNullOrUndefined(module) || collapseModules) && (
                        <ModuleItem
                            isActive={+params?.moduleId === index + 1 && isDashboardPage}
                            removedHoverIndex={removeBorderIndex}
                            onHover={onHover}
                            isLastItem={modulesList.length - 1 === index}
                            itemIndex={index}
                            module={module}
                            order={index + 1}
                            onClick={onModuleClick}
                            canClick={isNullOrUndefined(module) ? canNavigate(index + 1) : true}
                            isCollapsed={false}
                            unAssignCount={0}
                        />
                    )}
                </Fragment>
            ))}
            {hasUnassignedModules && !collapseModules && (
                <ModuleItem
                    isActive={false}
                    itemIndex={1}
                    isLastItem
                    removedHoverIndex={removeBorderIndex}
                    onHover={onHover}
                    module={null}
                    onClick={onModuleClick}
                    isCollapsed={collapseModules}
                    unAssignCount={unassignedModuleCount}
                    onUnassignedClick={onCollapseUnassignModulesClick}
                />
            )}
        </ol>
    )
}
