import { type FC, type ReactElement } from "react"

import classNames from "classnames"

import { getTimezoneAbbreviation } from "@/utils/date"

import { getFormattedGuestSessionDate } from "./utils"

type TSwapSessionSessionTimeProps = {
    startTime: string
    endTime?: string
    withDays?: boolean
    withTimezone?: boolean
    withTimezoneExtrabold?: boolean
    withTimeExtrabold?: boolean
    direction?: "row" | "column"
}

const SwapSessionSessionTime: FC<TSwapSessionSessionTimeProps> = ({
    startTime,
    endTime,
    withDays = false,
    withTimezone = false,
    withTimezoneExtrabold = false,
    withTimeExtrabold = false,
    direction = "column"
}: TSwapSessionSessionTimeProps): ReactElement => (
    <p className={classNames("swap-session__session-block__date", `flex-${direction}`)}>
        <span className={classNames({ "font-extrabold": withTimeExtrabold })}>
            {getFormattedGuestSessionDate(startTime, withDays)}
            {endTime && (
                <>
                    <TimeSpacing />
                    {getFormattedGuestSessionDate(endTime, withDays)}
                </>
            )}
        </span>
        {direction === "row" && <>&nbsp;</>}
        {withTimezone && (
            <span className={classNames("color-gray font-sm", { "font-extrabold": withTimezoneExtrabold })}>
                {getTimezoneAbbreviation()}
            </span>
        )}
    </p>
)

const TimeSpacing: FC = (): ReactElement => <>&nbsp;-&nbsp;</>

export { SwapSessionSessionTime, type TSwapSessionSessionTimeProps }
