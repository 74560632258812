import { useEffect } from "react"

import { initDatadog } from "$/utils/init-datadog"

function useInitDataDog() {
    useEffect(() => {
        initDatadog()
    }, [])
}

export default useInitDataDog
