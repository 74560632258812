import { FC } from "react"

interface IProps {
    heigth?: number
    width?: number
    className?: string
}

const AccordionSvg: FC<IProps> = ({ heigth = 14, width = 14, className = "" }) => {
    return (
        <svg
            width={width}
            height={heigth}
            className={className}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.59163 8.24V13.256H5.47163V8.24H0.359625V5.216H5.44763V0.199999H8.56763V5.216H13.6796V8.24H8.59163Z"
                fill="#FD4D00"
            />
        </svg>
    )
}

export default AccordionSvg
