import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"
import { ServerStateKeys } from "@/constants"
import { Participant, ParticipantApi } from "@/models/participants"

interface Query {
    search: string
    enabled: boolean
    page: number
}

const getParticipants = async ({ search, page }: Query) => {
    const { data } = await http.get(Urls.participants({ search, cohort: String(), page }))
    return data
}

export default function useParticipants(query: Query) {
    return useQuery(
        [ServerStateKeys.Participants, query],
        useCallback(() => getParticipants(query), [query]),
        {
            select: useCallback(
                data => ({
                    participants:
                        data?.results?.map((participant: ParticipantApi) => new Participant(participant)) ?? [],
                    totalParticipantsCount: data?.count ?? 0
                }),
                []
            ),
            enabled: query.enabled,
            keepPreviousData: true
        }
    )
}
