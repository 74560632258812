import { forwardRef } from "react"

import classNames from "classnames"

import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA, Role } from "@/constants"
import { FeedbackTypes, SimulabCoachee, SimulabMessage, SimulabReport } from "@/entities/simulab"
import User from "@/models/user"
import Avatar from "@/shared/avatar/Avatar"
import { FlagReportBtn } from "@/shared/button/FlagReportBtn"

import "../styles.scss"

import { SimulabChatMessage } from "./SimulabChatMessage"

type Props = {
    message: SimulabMessage
    isMyMessage: boolean
    isActiveMessage: boolean
    coachee: SimulabCoachee
    user: User
    onClickMessageRow: (messageId: number) => void
    onFeedbackFlagClick?: (report: SimulabReport) => void
}

export const SimulabChatMessageRow = forwardRef<HTMLDivElement, Props>(
    ({ message, isMyMessage, isActiveMessage, coachee, user, onClickMessageRow, onFeedbackFlagClick }, ref) => {
        const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

        const messageVariant = () => {
            if (isActiveMessage) {
                return "active"
            }

            if (isMyMessage) {
                return "system"
            }

            return "other"
        }

        const onFlagClick = () => {
            onFeedbackFlagClick?.({
                type: FeedbackTypes.PRACTICE_LAB_COACHEE_MESSAGE,
                message,
                coachee,
                message_id: message.id
            })
        }

        return (
            <div
                className={classNames("simulab-message-row", { is_my_message: isMyMessage })}
                ref={ref}
                data-testid="simulab-message-row"
            >
                <div className="simulab-message-box-wrapper">
                    <div className="simulab-message-box">
                        <Avatar
                            url={isMyMessage ? user?.photo : coachee?.photo}
                            width={isMobile ? 25 : 30}
                            height={isMobile ? 25 : 30}
                            className={classNames("simulab-message-box__avatar", { is_my_message: isMyMessage })}
                        />
                        <SimulabChatMessage
                            variant={messageVariant()}
                            message={message.message}
                            onClickMessage={() =>
                                message?.authorRole === Role.Participant && onClickMessageRow(message.id)
                            }
                        />
                        {!isMyMessage && <FlagReportBtn onFlagClick={onFlagClick} />}
                    </div>
                </div>
            </div>
        )
    }
)
