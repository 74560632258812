import { FC } from "react"

import classNames from "classnames"
import { DateTime } from "luxon"

interface IProps {
    className?: string
}

const DateWidget: FC<IProps> = ({ className }) => {
    return (
        <p className={classNames({ [className]: className, "fs-14-regular": !className })}>
            {DateTime.now().toFormat("EEEE, MMMM dd")}
        </p>
    )
}

export default DateWidget
