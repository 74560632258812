import { FC } from "react"

import classNames from "classnames"

import { ComponentType } from "@/constants"

import QuestionFreeResponses from "./QuestionFreeResponses"
import QuestionLikertResponses from "./QuestionLikertResponses"
import TrackPracticeResponses from "./TrackPracticeResponses"

interface IProps {
    component: any
}

const renderComponent = props => {
    return {
        [ComponentType["Question: Free Response"]]: <QuestionFreeResponses {...props} />,
        [ComponentType["Question: Likert Group"]]: <QuestionLikertResponses {...props} />,
        [ComponentType["Track Practice"]]: <TrackPracticeResponses {...props} />
    }
}

const MobileResponseItem: FC<IProps> = ({ component }) => {
    return (
        <div className={classNames("responses-body")}>
            {component && renderComponent({ component })[component?.type]}
        </div>
    )
}

export default MobileResponseItem
