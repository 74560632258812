import { useMemo } from "react"

import { userRoles } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import { isCoach, isParticipant, isProgramManager } from "@/utils/gates/user"

type TUseRoles = { isCoach: boolean; isParticipant: boolean; isProgramManager: boolean; isCoaches: boolean }

function useRoles(): ReturnType<() => TUseRoles> {
    const { user }: IAuthContext = useAuthContext()

    return useMemo(
        () => ({
            isCoach: isCoach(user),
            isParticipant: isParticipant(user),
            isProgramManager: isProgramManager(user),
            isCoaches: userRoles.coach.includes(user?.role) // TODO find better name for this
        }),
        [user]
    )
}

export { useRoles as default, type TUseRoles }
