import { FC } from "react"

import TrackPracticeMobileBoxes from "./TrackPracticeMobileBoxes"

interface IProps {
    rowIndex: number
}

const TrackPracticeMobileEdit: FC<IProps> = ({ rowIndex }) => {
    return (
        <div className="track-practice-mobile-edit-column">
            <TrackPracticeMobileBoxes rowIndex={rowIndex} />
        </div>
    )
}

export default TrackPracticeMobileEdit
