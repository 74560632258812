import { ReactNode, useEffect, useRef, useState } from "react"

import { Transition } from "react-transition-group"

export const DynamicHeightTransition = ({ children }: { children: ReactNode }) => {
    const [contentHeight, setContentHeight] = useState(0)
    const contentRef = useRef(null)

    const updateContentHeight = () => {
        setContentHeight(contentRef.current?.getBoundingClientRect().height ?? 0)
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            entries.forEach(() => updateContentHeight())
        })

        if (contentRef.current) {
            resizeObserver.observe(contentRef.current)
        }

        return () => {
            if (contentRef.current) {
                resizeObserver.unobserve(contentRef.current)
            }
        }
    }, [contentRef.current])

    const defaultStyle = {
        transition: "height 300ms ease-in-out",
        overflow: "hidden"
    }

    const transitionStyles = {
        entering: { height: `${contentHeight}px` },
        entered: { height: `${contentHeight}px` },
        exiting: { height: 0 },
        exited: { height: 0 }
    }

    return (
        <Transition in timeout={300} appear onEnter={updateContentHeight}>
            {state => (
                <div
                    style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}
                >
                    <div ref={contentRef}>{children}</div>
                </div>
            )}
        </Transition>
    )
}
