import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"

import { QueryKey } from "../constants"

const getAvailability = async () => {
    const { data } = await http.get(Urls.avRulesAttrs())
    return data
}

export function useAvailability() {
    return useQuery([QueryKey.AvRulesAttrs], getAvailability, {
        refetchOnMount: "always"
    })
}
