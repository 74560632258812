import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"
import { CoachRatingSource, ServerStateKeys } from "@/constants"
import { useAuthContext } from "@/context"
import { CoachRating } from "@/models/coach-rating"

const getCoachRating = async (source: CoachRatingSource) => {
    const { data } = await http.get(Urls.coachRating(source))
    return data
}

export default function useCoachRating(source: CoachRatingSource) {
    const { user } = useAuthContext()

    return useQuery([ServerStateKeys.CoachRating, { source }], () => getCoachRating(source), {
        select: useCallback(data => {
            return new CoachRating(data)
        }, []),
        enabled: !!user
    })
}
