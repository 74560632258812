import { useEffect } from "react"

import { FALLBACK_LANG } from "$/constants"
import { i18n } from "$/i18n"

import type { IParticipantModel } from "@/adapters"
import User from "@/models/user"

export function useLanguage(user: User) {
    useEffect(() => {
        if (user) {
            if ((user as IParticipantModel)?.isFakeParticipant) {
                i18n.changeLanguage(FALLBACK_LANG)
                return
            }
            const availableLanguage =
                user?.cohortAvailableLanguages && user.lang in user.cohortAvailableLanguages ? user.lang : FALLBACK_LANG
            i18n.changeLanguage(availableLanguage)
        }
    }, [user])
}
