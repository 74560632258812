import ContentLoader from "react-content-loader"
import { useTranslation } from "react-i18next"

import useCoach from "@/api/use-coach"

import CoachBlock from "./CoachBlock"

import "./styles.scss"

const CoachInfo = () => {
    const { t } = useTranslation()
    const { data: coach, isLoading } = useCoach()

    if (isLoading) {
        return (
            <div className="w-100 mb-50" data-testid="content-loader" style={{ height: 350 }}>
                <ContentLoader speed={3} height={350} width="100%" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
                    <rect x="7" y="14" rx="0" ry="0" width="100%" height="100%" />
                    <rect x="122" y="71" rx="0" ry="0" width="0" height="1" />
                </ContentLoader>
            </div>
        )
    }

    return (
        <>
            {coach && (
                <div className="w-100 p-0 mb-50 mt-50 mt-60-mobile" data-testid="coach-info">
                    <h2 className="h2 text-center font-extrabold mb-3">{t("My Coach")}</h2>
                    <CoachBlock coach={coach} />
                </div>
            )}
        </>
    )
}

export default CoachInfo
