import { type MouseEvent } from "react"

import classNames from "classnames"

import { type IUseTranslation, useTranslation } from "@/hooks"
import { ERichTextRendererMode, RichTextRenderer } from "@/shared/rich-text-renderer"

import { ITrackPractice } from "Manager/chapter/models/types"

import "./styles.scss"

type Props = {
    component: ITrackPractice
    showButton?: boolean
    size?: "sm" | "md" | "lg"
    onOpenModal?: (e: MouseEvent<HTMLAnchorElement>) => void
    isPracticeTitleVisible?: boolean
}

const TrackPracticeDescriptionBlock = ({
    component,
    onOpenModal,
    showButton = true,
    size = "md",
    isPracticeTitleVisible = false
}: Props) => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className={classNames("track-practice-description-block", size)}>
            {isPracticeTitleVisible ? (
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <h4 className="h4 m-0 font-extrabold">{t("chapter.trackPractice.practiceBlock.title")}</h4>
                    <span className="counter font-sm">
                        {t("chapter.trackPractice.trackPracticePanel.trackCounter", {
                            trackedCount: component?.tracked
                        })}
                    </span>
                </div>
            ) : null}

            <RichTextRenderer content={component?.assignment_description} mode={ERichTextRendererMode.View} />

            {showButton && (
                <div className="d-flex justify-content-start mt-30">
                    <a className="underlined-link" href="#" onClick={onOpenModal}>
                        {t("Track Practice")}
                    </a>
                </div>
            )}
        </div>
    )
}

export default TrackPracticeDescriptionBlock
