import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "$/http"

import { QueryKey } from "../constants"
import { Coachee, CoacheeApi } from "../models/coachee"

import { Urls } from "./urls"

const getSimulabCoachees = async () => {
    const { data } = await http.get(Urls.coacheesList())
    return data.results
}

export function useSimulabCoachees() {
    return useQuery<CoacheeApi[], AxiosError, Coachee[]>([QueryKey.Coachees], getSimulabCoachees, {
        select: useCallback((data: CoacheeApi[]) => data.map(coachee => new Coachee(coachee)), [])
    })
}
