import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { PairingLikertComponent } from "@/entities/pairing-question"
import Button from "@/shared/button/Button"

import "./styles.scss"

type Props = {
    questions: any
    onSubmit: (values) => void
}

const PairingQuestionView = ({ questions, onSubmit }: Props) => {
    const { t } = useTranslation()
    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm()

    return (
        <>
            {questions?.map((item, index) => (
                <PairingLikertComponent
                    control={control}
                    register={register}
                    item={item}
                    questionNumber={index + 1}
                    name={item.uuid}
                    errors={errors}
                    questionStyle="one-line"
                    color="blank"
                    likertType={item.component_type}
                />
            ))}
            <div className="text-center mt-50">
                <Button onClick={handleSubmit(onSubmit)} className="pairing-question-submit-btn font-weight-800">
                    {t("Submit")}
                </Button>
            </div>
        </>
    )
}

export default PairingQuestionView
