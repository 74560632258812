import { ProductType as ProductTypeName } from "@/constants"

export interface ProductTypeApi {
    logo: string | null
    support_email: string
    support_team_name_data: string
    title_data: string
    configs: any[]
    session_page_name_data: string
    session_page_name_pref_lang: string
    support_team_name_pref_lang: string
    title_pref_lang: string
    internal_name: ProductTypeName
}

export class ProductType {
    logo: string | null

    supportEmail: string

    supportTeamName: string

    titleData: string

    configs: any[]

    sessionPageName: string

    internalName: ProductTypeName

    constructor(data: ProductTypeApi) {
        this.logo = data.logo
        this.supportEmail = data.support_email
        this.supportTeamName = data.support_team_name_pref_lang || data.support_team_name_data
        this.titleData = data.title_pref_lang || data.title_data
        this.configs = data.configs
        this.sessionPageName = data.session_page_name_pref_lang || data.session_page_name_data
        this.internalName = data.internal_name
    }
}
