import { useMemo } from "react"

import { Role } from "@/constants"
import { useStoreContext } from "@/context"
import { useSimulabContext } from "@/entities/simulab"
import Button from "@/shared/button/Button"

import "../styles.scss"

type Props = {
    onClickFeedback: () => void
}

export const SimulabFeedbackMobile = ({ onClickFeedback }: Props) => {
    const { simulabMessages, selectedMessage } = useSimulabContext()
    const { isOpenBurgerMenu } = useStoreContext()

    const currentFeedback = useMemo(() => {
        const message = simulabMessages?.messages?.find(message => message?.id === selectedMessage)?.owlFeedback

        if (!message && !selectedMessage) {
            const recentMessage = simulabMessages?.messages
                ?.slice(-3)
                .find(message => message.authorRole === Role.Participant)
            return recentMessage?.owlFeedback
        }

        return message
    }, [simulabMessages, selectedMessage])

    return (
        !isOpenBurgerMenu &&
        simulabMessages?.messages?.length > 0 && (
            <Button className="p-0 simulab-feedback-mobile" variant="default" onClick={onClickFeedback}>
                <div className="simulab-feedback-mobile__body">
                    <p className="m-0 fs-14">{currentFeedback?.feedbackData?.feedbackSummary ?? ""}</p>
                </div>
            </Button>
        )
    )
}
