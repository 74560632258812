import { useMutation } from "@tanstack/react-query"
import { AxiosPromise } from "axios"

import { http } from "$/http"

import { SsoOrganization } from "@/models/api/sso"

import Urls from "../urls"

export default function useSsoEmail() {
    return useMutation((email: string): AxiosPromise<SsoOrganization | ""> => http.post(Urls.ssoEmail(), { email }))
}
