import { FC } from "react"

import { useFormContext } from "react-hook-form"

import Skeleton from "$/components/Skeleton/Skeleton"

import { ReflectionComponentType } from "@/constants"
import DiagnosticFreeResponseOffboarding from "@/elements/diagnostic-question/DiagnosticFreeResponseOffboarding"
import DiagnosticQuestionOffboarding from "@/elements/diagnostic-question/DiagnosticQuestionOffboarding"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { isFreeResponse, isLikertComponent } from "@/utils/gates/utilts"

interface IProps {
    diagnosticQuestions: any[]
    errors: any
    name: string
    isLoading?: boolean
}

const DiagnosticQuestionsList: FC<IProps> = ({ diagnosticQuestions, errors, name, isLoading = true }) => {
    const { register, control } = useFormContext()

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    const likertType = type => {
        return type === ReflectionComponentType.COLORED_LIKERT_GROUP ? "colored" : "brand"
    }

    const isLikertDefaultComponent = item => {
        return "options" in item
    }

    if (isLoading) {
        return <Skeleton rows={3} height={100} className="mb-3" />
    }

    return (
        <>
            {diagnosticQuestions?.map((item, index) => (
                <>
                    {(isLikertComponent(item.component_type) || isLikertDefaultComponent(item)) && (
                        <DiagnosticQuestionOffboarding
                            key={item.uuid}
                            control={control}
                            questionOrder={index + 1}
                            item={item}
                            error={errors?.diagnostic?.[item.uuid]?.message}
                            name={`${name}.${item.uuid}`}
                            register={register}
                            previosAnswer={item.reflection_objective}
                            type={likertType(item.component_type)}
                        />
                    )}
                    {isFreeResponse(item.component_type) && (
                        <DiagnosticFreeResponseOffboarding
                            name={`${name}.${item.uuid}`}
                            register={register}
                            previosAnswer={item.reflection_objective}
                            error={errors?.diagnostic?.[item.uuid]?.message}
                            isRequired={item.response_required}
                            className="mb-5 "
                            question={getValueFromTranslatedObject(item.question)}
                            order={index + 1}
                            placeholder={getValueFromTranslatedObject(item.prompt)}
                        />
                    )}
                </>
            ))}
        </>
    )
}

export default DiagnosticQuestionsList
