import { FC } from "react"

import ContentLoader from "react-content-loader"
import { useTranslation } from "react-i18next"

import useCoachingSessions from "@/api/use-coaching-sessions"
import GroupParticipants from "@/components/group-info/GroupParticipants"

interface IProps {}

const MyGroups: FC<IProps> = () => {
    const { t } = useTranslation()
    const { data, isLoading } = useCoachingSessions()

    if (isLoading && !data) {
        return (
            <div className="w-100 mb-50" data-testid="content-loader" style={{ height: 294 }}>
                <ContentLoader speed={3} height={294} width="100%" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
                    <rect x="7" y="14" rx="0" ry="0" width="100%" height="100%" />
                    <rect x="122" y="71" rx="0" ry="0" width="0" height="1" />
                </ContentLoader>
            </div>
        )
    }

    return (
        <>
            {data?.group_info?.participants_info.length > 0 ? (
                <div className="w-100 p-0 mb-50 mt-50 mt-60-mobile" data-testid="my-groups">
                    <h2 className="h2 text-center font-extrabold mb-3">{t("My Group")}</h2>
                    <GroupParticipants participants={data?.group_info?.participants_info} />
                </div>
            ) : null}
        </>
    )
}

export default MyGroups
