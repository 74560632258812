import { type FC, type ReactElement, startTransition, useLayoutEffect } from "react"

import { type NavigateFunction, useNavigate } from "react-router-dom"

import Heading from "$/components/Heading/Heading"
import { getIndexPage } from "$/utils/get-index-page"

import LeftSidebar from "@/components/dashboard/LeftSidebar"
import { type IAuthContext, useAuthContext } from "@/context"
import { type IUseTranslation, useTranslation } from "@/hooks"
import { ParticipantLayout } from "@/layouts"
import PageLoader from "@/shared/spinner/PageLoader"
import type { TEmptyCallback } from "@/shared/types/functions"

import { SurveysContainer } from "."

import "./Surveys.styles.scss"

const Surveys: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const { user }: IAuthContext = useAuthContext()

    const navigate: NavigateFunction = useNavigate()

    useLayoutEffect(
        (): TEmptyCallback => (
            !user?.hasSurveys && startTransition((): void => navigate(getIndexPage(user, true))), void 0
        ),
        [user, navigate]
    )

    return user?.hasSurveys ? (
        <ParticipantLayout
            tag="main"
            leftSidebar={<LeftSidebar tag="aside" />}
            title={
                <div className="surveys__heading">
                    <Heading textAlign="center" className="m-0 fs-36">
                        {t("participantSide.surveysPage.heading")}
                    </Heading>
                </div>
            }
        >
            <SurveysContainer />
        </ParticipantLayout>
    ) : (
        <PageLoader />
    )
}

export { Surveys }
