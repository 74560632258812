import { useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import AccordionCard from "@/shared/accordion/AccordionCard"
import Button from "@/shared/button/Button"
import { sanitizeContent } from "@/utils/common"

import { Module } from "../models/module"

import "./styles.scss"

type Props = {
    module: Module
    className?: string
    viewOnly?: boolean
    onSelectModule?: (module: Module) => void
}

export const ModuleAccordion = ({ module, className = "", viewOnly = true, onSelectModule }: Props) => {
    const { t } = useTranslation()
    const [toggled, setToggled] = useState(false)

    const onToggle = () => {
        setToggled(prev => !prev)
    }

    const disabledModule = module.isAssigned || module.isCompleted

    return (
        <AccordionCard
            className={`module-accordion ${className}`}
            active={toggled}
            onToggle={onToggle}
            headerContent={
                <p
                    className={classNames("m-0 font-extrabold", {
                        "color-gray": disabledModule
                    })}
                >
                    {module.titlePrefLang}{" "}
                    {disabledModule && <span className="font-normal fs-14">{t("(Already Assigned)")}</span>}
                </p>
            }
        >
            <div
                className="module-accordion-description"
                dangerouslySetInnerHTML={{ __html: sanitizeContent(module.moduleDescriptionPrefLang) }}
            />
            {!disabledModule && !viewOnly && (
                <div className="text-center mt-30">
                    <Button onClick={() => onSelectModule?.(module)}>{t("Select")}</Button>
                </div>
            )}
        </AccordionCard>
    )
}
