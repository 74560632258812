import { type FC, type ReactElement } from "react"

import { WEEK_RANGE_SEPARATOR } from "./utils"

import { SwapSessionBadge as Badge } from "."

type TSwapSessionAccordionHeadingProps = {
    title: string
    isPreferredWeek: boolean
}

const SwapSessionAccordionHeading: FC<TSwapSessionAccordionHeadingProps> = ({
    title,
    isPreferredWeek
}: TSwapSessionAccordionHeadingProps): ReactElement => (
    <div className="swap-session__accordion__heading">
        <p className="m-0 font-extrabold">
            <span className="swap-session__accordion__title">{title.split(WEEK_RANGE_SEPARATOR)?.[0]}</span>
            {WEEK_RANGE_SEPARATOR}
            <span className="swap-session__accordion__title">{title.split(WEEK_RANGE_SEPARATOR)?.[1]}</span>
        </p>
        <Badge isVisible={isPreferredWeek} />
    </div>
)

export { SwapSessionAccordionHeading, type TSwapSessionAccordionHeadingProps }
