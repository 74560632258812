import { type FunctionComponent, type ReactElement, memo, useCallback, useState } from "react"

import classNames from "classnames"
import { useNavigate } from "react-router-dom"

import Skeleton from "$/components/Skeleton/Skeleton"
import { HTTPStatus } from "$/constants"
import useIsMobileLandscape from "$/hooks/use-is-mobile-landscape"
import useMediaQuery from "$/hooks/use-media-query"
import { isNullOrUndefined } from "$/utils/gates"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { useStoreContext } from "@/context"
import { useSimulabContext } from "@/entities/simulab"
import { SimulabChat, SimulabReportModal } from "@/features/simulab-chat"
import { SimulabEndConversationModal } from "@/features/simulab-chat/ui/end-conversation/SimulabEndConvesationModal"
import { SimulabMobileStagesModal, SimulabStages } from "@/features/simulab-stages"
import type { IButtonProps } from "@/shared/button/Button"

import "./styles.scss"

export enum ESimulabBuiltInMode {
    Standalone,
    Integrated
}

type TProps = {
    mode?: ESimulabBuiltInMode
    chatEndConversationButtonProps?: IButtonProps
}

const SimulabComponent: FunctionComponent<TProps> = ({
    mode = ESimulabBuiltInMode.Standalone,
    chatEndConversationButtonProps = {}
}: TProps): ReactElement => {
    const navigate = useNavigate()
    const { isOpenSidebar } = useStoreContext()
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const isLandScape = useIsMobileLandscape()

    const { isLoading, apiError, hasError, report } = useSimulabContext()
    const [showMobileStageModal, setShowMobileStageModal] = useState(false)

    if (
        hasError &&
        !isLoading &&
        (apiError?.response?.status === HTTPStatus.NOT_FOUND || apiError?.response?.status === HTTPStatus.FORBIDDEN)
    ) {
        navigate("/404", { replace: true })
    }

    const onCloseMobileStageModal = useCallback(() => {
        setShowMobileStageModal(false)
    }, [])

    const onClickMobileFeedback = useCallback(() => {
        setShowMobileStageModal(true)
    }, [])

    return (
        <section className={classNames("simulab", { landscape: isLandScape, "open-sidebar": isOpenSidebar })}>
            {isLoading ? (
                isMobile ? (
                    <>
                        <Skeleton rows={1} height={550} />
                        <Skeleton rows={1} height={200} />
                    </>
                ) : (
                    <>
                        <Skeleton rows={1} height={750} />
                        <Skeleton rows={1} height={750} />
                    </>
                )
            ) : (
                <>
                    <SimulabChat
                        mode={mode}
                        onClickMobileFeedback={onClickMobileFeedback}
                        chatEndConversationButtonProps={chatEndConversationButtonProps}
                    />
                    <SimulabStages />
                </>
            )}

            <SimulabMobileStagesModal show={showMobileStageModal} onClose={onCloseMobileStageModal} />
            <SimulabEndConversationModal />
            {!isNullOrUndefined(report) && <SimulabReportModal />}
        </section>
    )
}

export const Simulab = memo(SimulabComponent)
