import PageLoader from "@/shared/spinner/PageLoader"

import OnBoardingContainer from "./OnBoardingContainer"

const OnBoardingRedirect = () => {
    return (
        <OnBoardingContainer>
            <PageLoader />
        </OnBoardingContainer>
    )
}

export default OnBoardingRedirect
