export type SimulabPostMessageBody = {
    message: string
    stage?: number
}

export interface SimulabMessageApi {
    id: number
    conversation_id: number
    stage_id: number
    message: string
    author_role: string
    owl_feedback: OwlFeedbackApi
}

export interface FeedbackDataApi {
    feedback_title: string
    feedback_summary: string
    rating: string
    feedback_emoji: string
    feedback_verbose: string
    feedback_tip: string
}

export interface OwlFeedbackApi {
    conversation_id: number
    message_id: number
    feedback_data: FeedbackDataApi
}
export interface BadRatingMessageApi {
    conversation_id: number
    message_id: number
    feedback_data: FeedbackDataApi
}

export interface SimulabMessageErrorApi {
    message_id: number
    completion_type: string
}

export interface SimulabMessagesDataApi {
    bad_rating_feedbacks: BadRatingMessageApi[]
    messages: SimulabMessageApi[]
    errors: SimulabMessageErrorApi[]
    is_make_plan_message: boolean | null
}

export class SimulabMessageError {
    messageId: number

    completionType: string

    constructor(data: SimulabMessageErrorApi) {
        this.messageId = data.message_id
        this.completionType = data.completion_type || ""
    }
}

export class SimulabMessageData {
    badRatingFeedbacks: BadRatingMessage[]

    messages: SimulabMessage[]

    errors: SimulabMessageError[]

    isMakePlanMessage: boolean

    constructor(data: SimulabMessagesDataApi) {
        this.badRatingFeedbacks = data?.bad_rating_feedbacks?.map(feedback => new BadRatingMessage(feedback)) || []
        this.messages = data?.messages?.map(message => new SimulabMessage(message)) || []
        this.errors = data?.errors?.map(error => new SimulabMessageError(error)) || []
        this.isMakePlanMessage = data?.is_make_plan_message || false
    }
}

export class SimulabMessage {
    id: number

    conversationId: number

    stageId: number

    message: string

    authorRole: string

    owlFeedback: SimulabOwlFeedback | null

    constructor(data: SimulabMessageApi) {
        this.id = data.id
        this.conversationId = data.conversation_id
        this.stageId = data.stage_id
        this.message = data.message || ""
        this.authorRole = data.author_role || ""
        this.owlFeedback = data.owl_feedback ? new SimulabOwlFeedback(data.owl_feedback) : null
    }
}

export class SimulabOwlFeedback {
    conversationId: number

    messageId: number

    feedbackData: FeedbackData

    constructor(data: OwlFeedbackApi) {
        this.conversationId = data.conversation_id
        this.messageId = data.message_id
        this.feedbackData = new FeedbackData(data.feedback_data)
    }
}

export class FeedbackData {
    feedbackTitle: string

    feedbackSummary: string

    feedbackEmoji: string

    feedbackTip: string

    rating: string

    constructor(data: FeedbackDataApi) {
        this.feedbackTitle = data.feedback_title || ""
        this.feedbackSummary = data.feedback_summary || ""
        this.feedbackEmoji = data.feedback_emoji || ""
        this.feedbackTip = data.feedback_tip || ""
        this.rating = data.rating || ""
    }
}

export class BadRatingMessage {
    conversationId: number

    messageId: number

    feedbackData: FeedbackData

    constructor(data: BadRatingMessageApi) {
        this.conversationId = data.conversation_id
        this.messageId = data.message_id
        this.feedbackData = new FeedbackData(data.feedback_data)
    }
}
