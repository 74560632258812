import { SIMULAB_CONVERSATION_STATUS } from "../constants"

import { Coachee, CoacheeApi } from "./coachee"
import { SimulabMessage, SimulabMessageApi } from "./simulab-message"
import { Stage, StageApi } from "./stages"

export type SimulabConversationStatus = (typeof SIMULAB_CONVERSATION_STATUS)[keyof typeof SIMULAB_CONVERSATION_STATUS]

export interface SimulabConversationFeedbackGrowthArea {
    title: string
    description: string
}

export interface SimulabConversationFeedbackApi {
    conversation_id: number
    impact: string
    strengths: string
    growth_areas: SimulabConversationFeedbackGrowthArea[]
}

export interface SimulabConversationApi {
    id: number
    messages: SimulabMessageApi[]
    coachee: CoacheeApi
    stages: StageApi[]
    feedback: SimulabConversationFeedbackApi | null
    status: SimulabConversationStatus
}

export class SimulaConversationFeedback {
    conversationId: number

    impact: string

    strengths: string

    growthAreas: SimulabConversationFeedbackGrowthArea[]

    constructor(data: SimulabConversationFeedbackApi) {
        this.conversationId = data.conversation_id
        this.impact = data.impact
        this.strengths = data.strengths
        this.growthAreas = data.growth_areas
    }
}

export class SimulabConversation {
    id: number

    messages: SimulabMessage[]

    coachee: Coachee | null

    stages: Stage[]

    feedback: SimulaConversationFeedback | null

    status: SimulabConversationStatus

    constructor(data: SimulabConversationApi) {
        this.id = data.id
        this.messages = data?.messages?.map(message => new SimulabMessage(message)) || []
        this.coachee = data?.coachee ? new Coachee(data.coachee) : null
        this.stages = data?.stages?.map(stage => new Stage(stage)) || []
        this.feedback = data?.feedback ? new SimulaConversationFeedback(data.feedback) : null
        this.status = data?.status
    }
}
