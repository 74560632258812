import { FC, ReactNode, useRef, useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { useOnClickOutside } from "$/hooks/use-outside-click"
import { Option } from "$/models/option"

import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import Spinner from "@/shared/spinner/Spinner"
import { LockSvg } from "@/svgs"
import Chevron from "@/svgs/Chevron"

import { emptyCallback } from "../types/functions"

import "./styles.scss"

interface IProps {
    optionsList?: Option[] | any[]
    loading?: boolean
    defaultOptionIndex?: number
    variant?: "default" | "secondary"
    backgroundWhite?: boolean
    className?: string
    disabled?: boolean
    error?: boolean
    showDisabledIcon?: boolean
    indexIncrease?: number
    children?: (props: {
        option: any
        selectedOption: number
        index: number
        onSelect: (index: number) => void
    }) => ReactNode
    onSelect: (option: any) => void
    withDisabledKeyboardNavigation?: boolean
    ariaLabelKey?: string
}

const Select: FC<IProps> = ({
    optionsList,
    onSelect,
    loading = false,
    disabled = false,
    backgroundWhite = false,
    indexIncrease = 0,
    defaultOptionIndex,
    showDisabledIcon = true,
    variant = "",
    error = false,
    className = "",
    withDisabledKeyboardNavigation = false,
    children: childrenOptions,
    ariaLabelKey = "Select preferred language"
}) => {
    const { t } = useTranslation()
    const [isOptionsOpen, setIsOptionsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(defaultOptionIndex ?? 0)
    const outsideRefClick = useRef<HTMLUListElement>()
    const buttonRef = useRef<HTMLButtonElement>()
    const contentRef = useRef<HTMLDivElement>()

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    const toggleOptions = () => {
        if (disabled) {
            return
        }
        setIsOptionsOpen(!isOptionsOpen)
    }

    const setSelectedThenCloseDropdown = index => {
        if (optionsList[index]?.disabled || disabled) {
            return
        }

        setSelectedOption(index)
        onSelect(optionsList[index])
        setIsOptionsOpen(false)
    }

    const handleKeyDown = index => e => {
        switch (e.key) {
            case " ":
            case "SpaceBar":
            case "Enter":
                e.preventDefault()
                setSelectedThenCloseDropdown(index)
                break
            default:
                break
        }
    }

    const handleListKeyDown = e => {
        switch (e.key) {
            case "Escape":
                e.preventDefault()
                setIsOptionsOpen(false)
                break
            case "ArrowUp":
                e.preventDefault()
                setSelectedOption(selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1)
                break
            case "ArrowDown":
                e.preventDefault()
                setSelectedOption(selectedOption == optionsList.length - 1 ? 0 : selectedOption + 1)
                break
            default:
                break
        }
    }

    const handleClickOutSide = e => {
        if (buttonRef?.current.contains(e.target)) {
            return
        }

        setIsOptionsOpen(false)
    }

    const selectVariants = {
        secondary: "secondary"
    }

    useOnClickOutside(outsideRefClick, handleClickOutSide)

    return (
        <div
            className={classNames("select-default", selectVariants[variant], {
                [className]: className,
                "background-white": backgroundWhite,
                disabled,
                error
            })}
        >
            <button
                type="button"
                aria-haspopup="listbox"
                aria-expanded={isOptionsOpen}
                className={isOptionsOpen ? "expanded" : ""}
                onClick={toggleOptions}
                ref={buttonRef}
                onKeyDown={withDisabledKeyboardNavigation ? emptyCallback : handleListKeyDown}
            >
                {loading && <Spinner width={20} height={20} />}
                <span>
                    {optionsList?.[selectedOption]?.label ?? (
                        <>
                            <span className="font-extrabold">{selectedOption + indexIncrease}: </span>
                            <span>
                                {optionsList?.[selectedOption]?.name
                                    ? getValueFromTranslatedObject(optionsList?.[selectedOption]?.name)
                                    : "Unassigned"}
                            </span>
                        </>
                    )}
                </span>
                {disabled && showDisabledIcon ? (
                    <span aria-hidden="true">
                        <LockSvg />
                    </span>
                ) : (
                    <span className="chevron-btn" aria-hidden="true">
                        <Chevron />
                    </span>
                )}
            </button>

            <div
                className="select-default-container"
                style={isOptionsOpen ? { height: `${contentRef.current?.scrollHeight}px` } : { height: "0px" }}
                ref={contentRef}
            >
                <ul
                    className="select-default-options"
                    role="listbox"
                    aria-label={t(ariaLabelKey)}
                    tabIndex={-1}
                    ref={outsideRefClick}
                    onKeyDown={withDisabledKeyboardNavigation ? emptyCallback : handleListKeyDown}
                >
                    {optionsList?.map((option, index) =>
                        childrenOptions ? (
                            childrenOptions({ option, index, selectedOption, onSelect: setSelectedThenCloseDropdown })
                        ) : (
                            <li
                                key={option.id ?? option.value}
                                role="option"
                                aria-selected={selectedOption == index}
                                className={option?.disabled ? "font-italic bg-gray-lighter" : ""}
                                data-disabled={option?.disabled}
                                tabIndex={isOptionsOpen ? 0 : -1}
                                onKeyDown={withDisabledKeyboardNavigation ? emptyCallback : handleKeyDown(index)}
                                onClick={() => {
                                    !option.disabled && setSelectedThenCloseDropdown(index)
                                }}
                            >
                                {option?.label}
                            </li>
                        )
                    )}
                </ul>
            </div>
        </div>
    )
}

export default Select
