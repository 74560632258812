import { ReactNode } from "react"

import classNames from "classnames"

type Props = {
    tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
    className?: string
    fontWeight?: 300 | 400 | 500 | 600 | 800
    fontSize?: 16 | 20 | 24 | 36 | 48
    textAlign?: "left" | "center" | "right"
    children: ReactNode
}

const Heading = ({
    tag: Tag = "h1",
    className = "",
    fontWeight = 800,
    fontSize = 36,
    textAlign = "left",
    children
}: Props) => {
    const fontWeightClasses = {
        300: "font-light",
        400: "font-normal",
        600: "font-bold",
        800: "font-extrabold"
    }

    const fontSizeClasess = {
        16: "fs-16",
        20: "fs-20",
        24: "fs-24",
        36: "fs-36",
        48: "fs-48"
    }

    const textAlignClasses = {
        left: "text-left",
        center: "text-center",
        right: "text-right"
    }

    return (
        <Tag
            className={classNames(
                className,
                Tag,
                fontWeightClasses[fontWeight],
                fontSizeClasess[fontSize],
                textAlignClasses[textAlign]
            )}
        >
            {children}
        </Tag>
    )
}

export default Heading
