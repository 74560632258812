import { SimulabContextType } from "../context/SimulabContextProvider"

export const QueryKey = {
    SimulabConversation: "simulabConversation",
    SimulabMessages: "simulabMessages",
    SimulabNewMessages: "simulabNewMessages",
    Coachees: "coachees",
    LasSentMessageParticipant: "lasSentMessageParticipant",
    ConversationFeedback: "conversationEndFeedback"
} as const

export const SIMULAB_CONVERSATION_STATUS = {
    IN_PROGRESS: "in_progress",
    COMPLETED: "completed",
    REVIEW_IN_PROGRESS: "review_in_progress"
} as const

export const COACHEE_CONVERSATION_COMPLETETION_STATUS = {
    IN_PROGRESS: "in_progress",
    COMPLETED: "completed",
    FAILES: "failed"
} as const

export const MESSAGES_REFETCH_INTERVAL = 5000
export const NEW_MESSAGES_REFETCH_INTERVAL = 2000

export const defaultContextValues: SimulabContextType = {
    conversationStarted: false,
    simulabMessages: {
        messages: [],
        errors: [],
        badRatingFeedbacks: [],
        isMakePlanMessage: false
    },
    showEndConversationModal: false,
    conversation: {
        stages: [],
        status: "in_progress",
        coachee: {
            id: null,
            name: "",
            summary: "",
            description: "",
            photo: null,
            conversations: null
        },
        feedback: null,
        messages: [],
        id: null
    },
    hasError: false,
    newMessages: {
        messages: [],
        isMakePlanMessage: false,
        errors: [],
        badRatingFeedbacks: []
    },
    sendMessage: null,
    isLoading: false,
    selectedMessage: null,
    error: null,
    apiError: null,
    badMessage: false,
    report: null,
    isMessageUnselected: false
}
