import { useTranslation as useTranslationReactI18Next } from "react-i18next"

import type { TTFunction, TTranslationKeys } from "$/i18n/i18n"

interface IUseTranslation {
    t: TTFunction
}

function useTranslation(): ReturnType<() => IUseTranslation> {
    const { t } = useTranslationReactI18Next()

    return { t }
}

export { useTranslation, type IUseTranslation, type TTranslationKeys, type TTFunction }
