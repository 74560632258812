import { useTranslation } from "react-i18next"

import Heading from "$/components/Heading/Heading"

import Modal from "@/shared/modal"

import SessionCardItem from "./SessionCardItem"

import "./styles.scss"

type Props = {
    show: boolean
    session?: any
    sessionCount?: number
    threeWaySession?: any
    threeWaySessionsCount?: number
    onClose: () => void
    onSessionClick: (session) => void
}

const MultiSessionScheduleModal = ({
    show,
    session,
    sessionCount,
    threeWaySession,
    threeWaySessionsCount,
    onClose,
    onSessionClick
}: Props) => {
    const { t } = useTranslation()

    return (
        <Modal
            show={show}
            onClose={onClose}
            modalBodyClass="session-schedule-modal"
            headerContent={
                <div className="px-5 px-md-3 py-3">
                    <Heading tag="h2" className="text-center m-0 session-schedule-modal__header-title" fontSize={24}>
                        {t("Schedule Now: Available Sessions")}
                    </Heading>
                </div>
            }
        >
            <div className="session-schedule-modal__body">
                <p className="mb-30 text-center">
                    {t(
                        "We recommend scheduling available sessions sooner than later, as coach schedules fill up fairly quickly. If you wait to book, it may be challenging to find a compatible window of time."
                    )}
                </p>
                {session && (
                    <>
                        <p className="font-extrabold fs-14 mb-2">{t("1:1 Sessions")}</p>
                        <div className="mb-30">
                            <SessionCardItem
                                session={session}
                                sessionCount={sessionCount}
                                onSessionClick={onSessionClick}
                            />
                        </div>
                    </>
                )}

                {threeWaySession ? (
                    <>
                        <p className="font-extrabold fs-14 mb-2">{t("Three-way Sessions")}</p>
                        <SessionCardItem
                            session={threeWaySession}
                            sessionCount={threeWaySessionsCount}
                            onSessionClick={onSessionClick}
                        />
                    </>
                ) : null}
            </div>
        </Modal>
    )
}

export default MultiSessionScheduleModal
