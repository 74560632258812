import {
    type Dispatch,
    type FC,
    type ReactElement,
    type SetStateAction,
    startTransition,
    useCallback,
    useEffect,
    useState
} from "react"

import { QueryClient, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { type NavigateFunction, useNavigate } from "react-router-dom"

import { ServerStateKeys } from "@/constants"
import { type IUseTranslation, useTranslation } from "@/hooks"
import { fetchAvailableGuestSessions } from "@/pages/swap-session/api"
import Button from "@/shared/button/Button"
import type { TEmptyAsyncCallback, TEmptyCallback } from "@/shared/types/functions"

import { shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime } from "./utils"

type TSwapSessionOpenerProps = { sessionTime: string; sessionId: number; shouldBeVisible: boolean }

const SwapSessionOpener: FC<TSwapSessionOpenerProps> = ({
    sessionTime,
    sessionId,
    shouldBeVisible
}: TSwapSessionOpenerProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const navigate: NavigateFunction = useNavigate()

    const queryClient: QueryClient = useQueryClient()

    const handleOpenerClick: TEmptyAsyncCallback = useCallback(
        async (): Promise<void> => (
            await queryClient.prefetchQuery(
                [ServerStateKeys.AvailableGuestSessions, { id: sessionId }],
                fetchAvailableGuestSessions
            ),
            navigate(`/swap-session/${sessionId}`)
        ),
        [navigate, queryClient, sessionId]
    )

    const [shouldOpenerBeVisibleBasedOnSessionDate, setShouldOpenerBeVisibleBasedOnSessionDate]: [
        boolean,
        Dispatch<SetStateAction<boolean>>
    ] = useState<boolean>(shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime(sessionTime))

    const handleOpenerVisibility: TEmptyCallback = (): void =>
        startTransition((): void =>
            setShouldOpenerBeVisibleBasedOnSessionDate(
                shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime(sessionTime)
            )
        )

    useEffect(
        (): TEmptyCallback => (
            setInterval(handleOpenerVisibility, 1000), () => clearInterval(setInterval(handleOpenerVisibility, 1000))
        )
    )

    return (
        <Button
            variant="outline-secondary"
            onClick={handleOpenerClick}
            className={classNames("swap-session__opener", {
                hidden: !shouldBeVisible || !shouldOpenerBeVisibleBasedOnSessionDate
            })}
        >
            {t("participantSide.coachingSessionsScreen.swapSessionOpener")}
        </Button>
    )
}

export { SwapSessionOpener, type TSwapSessionOpenerProps }
