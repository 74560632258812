import { StepUrls, dashboardURL } from "@/components/onboarding/constants"
import { CoachUrls, ParticipantUrls, userRoles } from "@/constants"

import { TUserModel } from "../../main-app/adapters"
import { ESessionStorageServiceItemKey, EStorageServiceType, StorageService } from "../../main-app/services"

const sessionStorageService: StorageService = new StorageService(EStorageServiceType.Session)

export const getIndexPage = (user: TUserModel, showDashboard) => {
    if (userRoles.coach.includes(user.role)) {
        if (
            sessionStorageService.getItem(ESessionStorageServiceItemKey.FakeParticipantsFeatureEnabled) ||
            user?.hasSubUsers
        ) {
            return CoachUrls.WELCOME
        }
        return CoachUrls.SESSIONS_LIST
    }

    if (!user.onboardingFinished) {
        return StepUrls.ONBOARDING_REDIRECT_PAGE
    }

    if (showDashboard) {
        return dashboardURL
    }

    return ParticipantUrls.SESSION_LIST
}
