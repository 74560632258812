import { useCallback, useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"

import useUpdateEffect from "$/hooks/use-update-effect"
import { http } from "$/http"

import Urls from "@/api/urls"
import { type IAuthContext, useAuthContext } from "@/context"
import Spinner from "@/shared/spinner/Spinner"
import useNavigationOnboarding from "@/utils/hooks/use-navigation-onboarding"

import { OnboardingSteps, dashboardURL, onboardingUrls } from "./constants"
import { useOnboardingStoreContext } from "./onboardingStore"

const OnBoardingContainer = ({ children }) => {
    const navigate = useNavigate()

    const { storeData, setStoreData } = useOnboardingStoreContext()
    const { refetchUser }: IAuthContext = useAuthContext()
    const [loading, setLoading] = useState(true)
    const { lastStep, currentStep } = useNavigationOnboarding()

    const handleProfileData = useCallback(async () => {
        try {
            const { data } = await http.get(Urls.participantMe())
            const { data: profileSetupDataModal } = await http.get(Urls.onboardingAboutManager())

            setStoreData(store => ({ ...store, currenUser: data, profileSetupDataModal }))
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }, [setStoreData])

    useEffect(() => {
        if (!storeData.currenUser) {
            handleProfileData()
        }
    }, [handleProfileData, storeData.currenUser])

    useEffect(() => {
        if (currentStep?.id === OnboardingSteps.FINISH || lastStep?.id === OnboardingSteps.FINISH) {
            refetchUser()
            navigate(dashboardURL)
        } else if (
            currentStep?.id === OnboardingSteps.PERSONAL_INFO_STEP ||
            lastStep?.id === OnboardingSteps.PERSONAL_INFO_STEP
        ) {
            navigate(onboardingUrls[0])
        }
    }, [navigate, currentStep, lastStep, refetchUser])

    useUpdateEffect(() => {
        if (!lastStep) {
            return
        }

        navigate(lastStep.url)
    }, [lastStep])

    return !storeData?.currenUser && loading ? (
        <div className="w-100 h-100 d-flex justify-content-center pt-5">
            <Spinner />
        </div>
    ) : (
        <>{children}</>
    )
}

export default OnBoardingContainer
