import { Component } from "react"

import * as Sentry from "@sentry/react"

import { initSentry } from "$/utils/init-centry"

import { InternalErrorPage } from "../error-page/InternalErrorPage"

type State = {
    hasError: boolean
}

export class ErrorBoundary extends Component<{}, State> {
    // eslint-disable-next-line react/state-in-constructor
    state: State = { hasError: false }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidMount() {
        initSentry()
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope(scope => {
            scope.setExtras({
                errorInfo
            })
            scope.setTag("build_config", process.env.NODE_ENV)
            Sentry.captureException(error)
        })
        console.error({ error }, { errorInfo })
    }

    render() {
        // @ts-expect-error legacy error boundary page
        return this.state.hasError ? <InternalErrorPage /> : this.props.children
    }
}
