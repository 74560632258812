import { Phase } from "./phase"
import User, { EnrolledModule, UserEnrollModule } from "./user"

export interface ParticipantDataApi {
    id: number
    first_name: string
    last_name: string
    email: string
    enrolled_modules: EnrolledModule[]
    onboarding_finished: null | boolean
    job_title: string
    department: string
    office_location: string
    years_in_current_role: number
    months_in_current_role: number
    direct_reports_number: number
    journey_length: number
    manager_email: string
    manager_first_name: string
    manager_last_name: string
    photo: null | string
    current_module: null | EnrolledModule
    coach_status: string
    phase: null | Phase
    time_in_current_role: null | string
    available_coaching_plan: boolean
}

export class ParticipantData {
    id: number

    firstName: string

    lastName: string

    email: string

    enrolledModules: UserEnrollModule[]

    onboardingFinished: null | boolean

    jobTitle: string

    department: string

    officeLocation: string

    yearsInCurrentRole: number

    monthsInCurrentRole: number

    directReportsNumber: number

    journeyLength: number

    managerEmail: string

    managerFirstName: string

    managerLastName: string

    photo: null | string

    currentModule: null | UserEnrollModule

    coachStatus: string

    phase: null | Phase

    availableCoachingPlan: boolean

    timeInCurrentRole: null | string

    constructor(data: ParticipantDataApi) {
        this.id = data.id
        this.firstName = data.first_name
        this.lastName = data.last_name
        this.email = data.email
        this.enrolledModules = data?.enrolled_modules?.map(module => new UserEnrollModule(module))
        this.onboardingFinished = data.onboarding_finished
        this.jobTitle = data.job_title
        this.department = data.department
        this.officeLocation = data.office_location
        this.yearsInCurrentRole = data.years_in_current_role
        this.monthsInCurrentRole = data.months_in_current_role
        this.directReportsNumber = data.direct_reports_number
        this.journeyLength = data.journey_length
        this.managerEmail = data.manager_email
        this.managerFirstName = data.manager_first_name
        this.managerLastName = data.manager_last_name
        this.photo = data.photo
        this.currentModule = User.defineUserModule(data?.enrolled_modules)
        this.coachStatus = data.coach_status
        this.timeInCurrentRole = data.time_in_current_role
        this.availableCoachingPlan = data.available_coaching_plan
    }
}
