import classNames from "classnames"

import useSpotCoaching, { type TSpotCoachingQuery } from "@/api/use-spot-coaching"
import { type IUseTranslation, useTranslation } from "@/hooks"
import { WiserLogo } from "@/svgs"

import { type TEmptyCallback, emptyCallback } from "./types/functions"

type Props = {
    className?: string
    width?: number
    heigth?: number
    handleLogoClick?: TEmptyCallback
}

const ProductTypeLogo = ({ className, heigth = 34, width = 26, handleLogoClick = emptyCallback }: Props) => {
    const { data: productType }: TSpotCoachingQuery = useSpotCoaching()
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className={classNames("cursor-pointer", { [className]: className })} onClick={handleLogoClick}>
            {productType?.logo ? (
                <img src={productType?.logo} width={width} height={heigth} alt={t("Wiser logo")} />
            ) : (
                <WiserLogo width={width} height={heigth} />
            )}
        </div>
    )
}

export default ProductTypeLogo
