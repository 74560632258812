import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"
import { ServerStateKeys } from "@/constants"
import { Resource } from "@/models/resource"

const getResources = async (id: number) => {
    const { data } = await http.get(Urls.resources(id))
    return data.results
}

export default function useResources(id: number) {
    return useQuery<Resource[]>([ServerStateKeys.Resources, id], () => getResources(id), {
        select: useCallback(data => {
            return data.map(resource => new Resource(resource))
        }, []),
        enabled: !Number.isNaN(id)
    })
}
