import { useRef, useState } from "react"

import classNames from "classnames"
import { useWatch } from "react-hook-form"

import useHover from "$/hooks/use-hover"
import useUpdateEffect from "$/hooks/use-update-effect"

import LikertLabel from "./LikertLabel"

interface IProps {
    register: any
    name: string
    id?: string
    value: string
    label: string
    color?: string
    checked?: string
    control: any
    isFirstOption: boolean
    isLastOption: boolean
    previousAnswer?: string
    showLabel?: boolean
    hoveredId?: string
    showHover?: boolean
    setHovered?: (id: string) => void
    onBlur?: (e) => void
    colored?: boolean
    dontSetNullOutOfHover?: boolean
}

const LikertRadio = ({
    id,
    value,
    register,
    name,
    label,
    color,
    control,
    isFirstOption,
    isLastOption,
    hoveredId,
    showHover = false,
    previousAnswer,
    showLabel = true,
    setHovered,
    colored,
    dontSetNullOutOfHover = true
}: IProps) => {
    const checked = useWatch({ control, name })
    const hoverRef = useRef(null)
    const isHovering = useHover(hoverRef)
    const [isFocusOnLabel, setIsFocusOnLabel] = useState(false)
    const onFocus = () => setIsFocusOnLabel(true)
    const onBlur = () => setIsFocusOnLabel(false)

    useUpdateEffect(() => {
        if (isHovering) {
            setHovered?.(id)
        } else if (!dontSetNullOutOfHover) {
            setHovered?.(null)
        }
    }, [isHovering, dontSetNullOutOfHover])

    return (
        <div
            className={classNames("level-choice-radio", {
                unselected: checked && checked !== value,
                selected: checked && checked === value,
                unhovered: hoveredId && showHover,
                colored
            })}
            style={
                {
                    "--likert-choice-radio-selected-color": color,
                    "--likert-choice-bg-color": checked ? color : ""
                } as any
            }
        >
            <legend className="not-visible">{label?.trim()?.length ? label : "invisible"}</legend>
            <input
                type="radio"
                data-testid={id}
                name={name}
                {...(register && register(name))}
                onBlur={onBlur}
                onFocus={onFocus}
                value={value}
                id={id}
            />
            <LikertLabel
                isFirstOption={isFirstOption}
                isLastOption={isLastOption}
                label={label}
                id={id}
                previousAnswer={previousAnswer}
                control={control}
                name={name}
                ref={hoverRef}
                value={value}
                showLabel={showLabel}
                isFocusOnLabel={isFocusOnLabel}
            />
        </div>
    )
}

export default LikertRadio
