import { FC, useCallback, useState } from "react"

import { AxiosError } from "axios"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"

import { HTTPStatus } from "$/constants"
import useMediaQuery from "$/hooks/use-media-query"
import { getErrorMessages } from "$/utils/get-error-messages"

import "@/assets/onboarding.scss"
import SessionHoldItem from "@/components/onboarding/components/SessionHoldItem"
import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { useAuthContext } from "@/context"
import { CohortModalities } from "@/models/types"
import CareTeamLink from "@/shared/CareTeamLink"
import Button from "@/shared/button/Button"
import ErrorBox from "@/shared/error-box/ErrorBox"
import useNavigationOnboarding from "@/utils/hooks/use-navigation-onboarding"

import useNextStep from "./api/mutations/use-next-step"
import useSessionHolds from "./api/use-session-hold"
import { StepsNavigator } from "./components/StepsNavigator"
import { WarningMessage } from "./components/WarningMessage"
import { OnboardingSteps, StepUrls } from "./constants"
import OnboardingLayout from "./layout/OnboardingLayout"

interface IProps {}

const SessionHoldTimesStep: FC<IProps> = () => {
    const { t } = useTranslation()
    const { user } = useAuthContext()
    const { data } = useSessionHolds(user?.cohortModality === CohortModalities.Group)
    const handleNextStep = useNextStep()
    const [selectedHolds, setSelectedHolds] = useState([])
    const [isBusy, setIsBusy] = useState(false)
    const [errorsMsg, setErrorsMsg] = useState(null)
    const [hasSelectedMinHolds, setHasSelectedMinHolds] = useState(false)
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const { getNextStepUrl } = useNavigationOnboarding()

    const onHoldSelect = useCallback(
        (holdId: number) => {
            setSelectedHolds(prev => (prev.includes(holdId) ? prev.filter(hold => hold !== holdId) : [...prev, holdId]))
        },
        [setSelectedHolds]
    )

    const onNextClick = () => {
        if (selectedHolds.length < data?.min_coaching_sessions) {
            setHasSelectedMinHolds(true)
            return
        }

        setIsBusy(true)
        handleNextStep.mutate(
            {
                step: OnboardingSteps.SESSION_HOLD_TIMES,
                step_data: {
                    hold_series: selectedHolds
                }
            },
            {
                async onSuccess() {
                    await getNextStepUrl(OnboardingSteps.SESSION_HOLD_TIMES)
                },
                onError(error: AxiosError) {
                    const errorMessage = getErrorMessages(error)
                    if (error?.response?.status === HTTPStatus.BAD_REQUEST) {
                        setErrorsMsg(errorMessage)
                    }
                    console.log(errorMessage)
                },
                onSettled() {
                    setIsBusy(false)
                }
            }
        )
    }

    if (user?.cohortModality === CohortModalities.Individual) {
        return <Navigate to={StepUrls.COACH_SELECT_STEP} replace />
    }

    return (
        <OnboardingLayout header={<StepsNavigator activeStep={{ id: OnboardingSteps.SESSION_HOLD_TIMES, order: 6 }} />}>
            <section className="container container-max-md">
                <div className="mb-5 mt-6 text-center">
                    <WarningMessage message={errorsMsg} />
                    <h1 className="step5-title">{t("Availability for Initial Coaching Session")}</h1>
                    <p className="color-gray fs-14">
                        {t(
                            "Please select all times that work with your schedule (minimum {{sessionCount}}). By providing a wider range of availability, we will be able to better curate your group. This means we will be able to pair you with others who have compatible strengths, growth edges, and overall journey goals.",
                            { sessionCount: data?.min_coaching_sessions }
                        )}
                    </p>
                </div>
                {data?.hold_series?.map(series => (
                    <SessionHoldItem
                        key={series.id}
                        series={series}
                        selected={selectedHolds.includes(series.id)}
                        onClick={onHoldSelect}
                        isMobile={isMobile}
                    />
                ))}
                {hasSelectedMinHolds && (
                    <div className="mt-70 mb-3">
                        <ErrorBox className="mx-auto session-hold-error-box color-gray">
                            <span className="font-extrabold">
                                {t("You must select at least {{sessionCount}} options", {
                                    sessionCount: data?.min_coaching_sessions
                                })}
                                .
                            </span>{" "}
                            <span className="d-block-sm">{t("Just do your best!")}</span>
                        </ErrorBox>
                    </div>
                )}
                <div className={classNames("mb-40 text-center", hasSelectedMinHolds ? "mt-40" : "mt-70")}>
                    <Button
                        onClick={onNextClick}
                        type="submit"
                        variant={selectedHolds.length > 0 ? "brand" : "outline-blank"}
                        disabled={!selectedHolds.length || selectedHolds.length < data?.min_coaching_sessions}
                        isBusy={isBusy}
                        className="font-weight-800 btn-200"
                    >
                        {t("Next")}
                    </Button>
                </div>
                <CareTeamLink
                    text="Having trouble finding times that work?"
                    variant="brand"
                    className="text-center fs-14"
                />
            </section>
        </OnboardingLayout>
    )
}

export default SessionHoldTimesStep
