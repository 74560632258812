import { useCallback } from "react"

import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "$/http"

import Urls from "@/api/urls"
import { GoalsSortType } from "@/shared/types/sort"

import { QueryKey } from "../consts"
import { OldBelief, OldBeliefApi } from "../model/types/old-belief"

const getOldBelief = async ({ queryKey }): Promise<OldBeliefApi[]> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_key, { id, query }] = queryKey
    const { data } = await http.get(Urls.oldBelief(id, query))
    return data
}

export function useOldBeliefData(participantId: number, query: GoalsSortType) {
    return useQuery<OldBeliefApi[], AxiosError, OldBelief[]>(
        [QueryKey.OldBelief, { query, id: participantId }],
        getOldBelief,
        {
            select: useCallback(data => data.map(item => new OldBelief(item)), [])
        }
    )
}

export function useArchiveOldBeliefData(participantId: number) {
    return useQuery<OldBeliefApi[], AxiosError, number[]>(
        [QueryKey.OldBelief, { query: "archived", id: participantId }],
        getOldBelief,
        {
            select: useCallback(data => data.map(item => item.id), [])
        }
    )
}

export function useCreateOldBelief(participantId: number) {
    return useMutation((oldBelief: Pick<OldBeliefApi, "description" | "order" | "title">) =>
        http.post<{ id: number }>(Urls.createOldBelief(participantId), oldBelief)
    )
}

export function useEditOldBelief(participantId: number) {
    return useMutation((oldBelief: Pick<OldBeliefApi, "description" | "order" | "id" | "title">) => {
        const { id, ...data } = oldBelief
        return http.put(Urls.editOldBelief(participantId, id), data)
    })
}

export function useArhiveOldBelief(participantId: number) {
    return useMutation((oldBeliefId: number) => http.post(Urls.archiveOldBelief(participantId, oldBeliefId)))
}

export function useUnarciveOldBelief(participantId: number) {
    return useMutation((oldBeliefId: number) => http.post(Urls.unarchiveOldBelief(participantId, oldBeliefId)))
}

export function useSortOldBelief(participantId: number) {
    return useMutation((sort: { ids: number[] }) => http.post(Urls.sortOldBelief(participantId), sort))
}
