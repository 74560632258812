import type { FC, ReactElement } from "react"

type TProps = { fillerColor: string; className?: string; width?: number; height?: number }

const ListItemImage: FC<TProps> = ({ fillerColor, className, width = 85, height = 85 }: TProps): ReactElement => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 86 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <g clipPath="url(#clip0_2469_19)">
            <path
                d="M85.6667 42.5C85.6667 19.0279 66.6388 0 43.1667 0C19.6946 0 0.666664 19.0279 0.666664 42.5C0.666664 65.9721 19.6946 85 43.1667 85C66.6388 85 85.6667 65.9721 85.6667 42.5Z"
                fill={fillerColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.3095 39.4887C62.5403 40.6012 71.6851 46.9604 72.6171 44.2539C75.1575 36.8761 71.2336 28.835 63.8529 26.2937C56.4722 23.7523 48.4296 27.6729 45.8892 35.0507C43.3489 42.4286 56.0786 38.3762 59.3095 39.4887Z"
                fill="#E5F7FF"
            />
            <path
                opacity="0.9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.3754 39.6951C63.1226 40.9853 65.7228 43.3019 66.1602 42.0313C67.45 38.2856 65.4578 34.2033 61.7108 32.9131C57.9636 31.6229 53.8805 33.6133 52.5907 37.3589C51.301 41.1046 55.6283 38.4049 59.3754 39.6951Z"
                fill="#0404CB"
            />
            <path
                d="M55.6761 35.0755C56.8115 35.4665 58.0488 34.8633 58.4398 33.7282C58.8306 32.5932 58.2269 31.3561 57.0914 30.9652C55.9559 30.5742 54.7185 31.1773 54.3277 32.3124C53.9369 33.4475 54.5406 34.6845 55.6761 35.0755Z"
                fill="#E5F7FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.0238 39.486C23.7929 40.5985 14.6482 46.9577 13.7162 44.2512C11.1759 36.8734 15.0997 28.8323 22.4803 26.291C29.861 23.7496 37.9037 27.6703 40.444 35.0481C42.9844 42.4259 30.2547 38.3736 27.0238 39.486Z"
                fill="#E5F7FF"
            />
            <path
                opacity="0.9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.9579 39.693C23.2108 40.9832 20.6107 43.2997 20.1732 42.0292C18.8834 38.2835 20.8755 34.2011 24.6227 32.9109C28.3698 31.6206 32.4529 33.6111 33.7426 37.3568C35.0323 41.1025 30.705 38.4028 26.9579 39.693Z"
                fill="#0404CB"
            />
            <path
                d="M22.5169 36.6232C21.3814 37.0142 20.144 36.411 19.7532 35.276C19.3624 34.141 19.9661 32.9038 21.1015 32.5129C22.237 32.1219 23.4743 32.725 23.8652 33.8602C24.2561 34.9952 23.6524 36.2322 22.5169 36.6232Z"
                fill="#E5F7FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.0069 60.271L51.5495 56.6172C52.3285 55.8136 52.3118 54.5316 51.5122 53.7486L50.4391 52.698C50.2 52.4639 49.8788 52.3329 49.5442 52.3329H37.9897C37.6476 52.3329 37.3197 52.4698 37.0794 52.7133L36.0574 53.7486C35.2765 54.5395 35.2593 55.8057 36.0184 56.6175L39.3609 60.1926C41.6125 62.6008 45.3899 62.7277 47.798 60.4761C47.8693 60.4095 47.939 60.3411 48.0069 60.271Z"
                fill="#FD4D00"
                fillOpacity="0.6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.7881 48.8749C39.8009 48.8545 39.814 48.8338 39.8269 48.813C41.1628 46.6629 43.9888 46.003 46.1387 47.3389C46.7576 47.7235 47.2759 48.25 47.6506 48.8749C48.2098 49.8075 49.0327 50.7267 50.1193 51.6327C50.4813 51.9344 50.5301 52.4726 50.2283 52.8346C50.1012 52.9869 49.9251 53.0904 49.7301 53.1269C48.465 53.364 47.2275 53.7301 46.037 54.2195L44.3287 54.9217C43.9512 55.0769 43.5277 55.0769 43.1502 54.9217L41.4419 54.2195C40.2514 53.7301 39.0139 53.364 37.7489 53.1269C37.2995 53.0427 37.0036 52.6102 37.0877 52.1608C37.1228 51.9738 37.2212 51.8047 37.3664 51.6817C38.2928 50.8971 39.1 49.9615 39.7881 48.8749Z"
                fill="#FFEDE5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.0759 60.5124C48.9861 60.6175 48.8947 60.7295 48.8018 60.8485C46.629 63.6334 42.6099 64.1295 39.8251 61.9567C39.3741 61.6048 38.9723 61.194 38.6305 60.7352C38.5711 60.6555 38.5136 60.5812 38.4579 60.5124C37.9558 59.8924 36.9852 58.9657 35.546 57.7323C35.2806 57.5048 35.2498 57.1051 35.4773 56.8396C35.6767 56.607 36.0138 56.5505 36.2781 56.7053L41.5842 59.8144C42.9154 60.5944 44.5641 60.5944 45.8953 59.8144L51.2013 56.7053C51.5056 56.5271 51.8968 56.6292 52.075 56.9334C52.2302 57.1983 52.1751 57.5359 51.9439 57.7375C50.6462 58.8691 49.6902 59.7941 49.0759 60.5124Z"
                fill="#FFEDE5"
            />
        </g>
        <defs>
            <clipPath id="clip0_2469_19">
                <rect width="85" height="85" fill="white" transform="translate(0.666664)" />
            </clipPath>
        </defs>
    </svg>
)

export { ListItemImage as CoachWelcomeUserListItemImage }
