import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"
import { isEmptyObject } from "$/utils/gates"

import { ServerStateKeys } from "@/constants"
import { uniqueObjectsArray } from "@/utils/common"

import Urls from "./urls"

const getReflectionsQuestions = async (id: number) => {
    const { data } = await http.get(Urls.moduleReflectionQuestions(id))
    return data
}

export default function useReflectionQuestions(id: number) {
    return useQuery<any>([ServerStateKeys.ReflectionQuestions], () => getReflectionsQuestions(id), {
        select: useCallback(data => {
            const diagnosticQuestions = !isEmptyObject(data?.self_assessment)
                ? uniqueObjectsArray(data?.self_assessment?.question_list, "uuid")
                : null

            const activeDiagnosticAnswers = data?.self_assessment?.answer_data?.filter(answer => answer.is_active) || []
            return { diagnosticQuestions, activeDiagnosticAnswers, reflection: data }
        }, []),
        refetchOnMount: true
    })
}
