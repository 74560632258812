import { FC, ReactNode } from "react"

import classNames from "classnames"

import type { IParticipantModel } from "@/adapters"
import { type IAuthContext, useAuthContext } from "@/context"

import "./styles.scss"

interface IProps {
    size?: "sm" | "md" | "max-md" | "default"
    children?: ReactNode
}

const ParticipantWrapper: FC<IProps> = ({ children, size = "sm" }) => {
    const { user }: IAuthContext = useAuthContext()

    return (
        <div
            className={classNames("participant-wrapper", {
                "participant-wrapper-md": size === "md",
                "participant-wrapper-sm": size === "default",
                "participant-wrapper-max-md": size === "max-md",
                "fake-mode": (user as IParticipantModel)?.isFakeParticipant
            })}
        >
            {children}
        </div>
    )
}

export default ParticipantWrapper
