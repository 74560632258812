import { FC } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import RedCheckMark from "@/svgs/RedCheckMark"
import { formatHoldDate, getTimezoneAbbreviation } from "@/utils/date"

interface IProps {
    series: any
    selected: boolean
    onClick: (holdId: number) => void
    isMobile?: boolean
}

const SessionHoldItem: FC<IProps> = ({ selected, series, onClick, isMobile }) => {
    const { t } = useTranslation()

    const onClickSession = (holdId: number) => {
        onClick(holdId)
    }

    return (
        <div
            className={classNames("session-hold", { selected })}
            key={series.id}
            onClick={() => onClickSession(series.id)}
        >
            <div className="session-hold__box">
                <div className="session-hold__box-header">
                    <span className="session-hold__box-header-text">
                        {t("Initial Session")} ({getTimezoneAbbreviation()}):{" "}
                    </span>
                    <span className="session-hold__box-header-time-text">
                        {series?.hold_events?.[0]?.hold_datetime
                            ? formatHoldDate(
                                  series?.hold_events?.[0]?.hold_datetime,
                                  series?.hold_events?.[0]?.duration_minutes
                              )
                            : "TBD"}
                    </span>
                    <div className="session-hold__line" />
                </div>
                <div className="session-hold__box-container">
                    {series.hold_events.length > 1 ? (
                        <div>
                            <p className="session-hold__box-session_info">
                                {t("Subsequent sessions are {{sessionDuration}} minutes", {
                                    sessionDuration: series?.hold_events?.[1]?.duration_minutes ?? 90
                                })}
                                :
                            </p>
                            <div className="session-hold__box-list-wrapper">
                                <ul className="session-hold__box-list">
                                    {series?.hold_events?.map((event, index) =>
                                        index === 0 ? null : (
                                            <li
                                                className="session-hold__box-list-item"
                                                key={event.hold_datetime || index + 1}
                                            >
                                                {event.hold_datetime
                                                    ? formatHoldDate(event.hold_datetime, event.duration_minutes)
                                                    : "TBD"}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="session-hold-selected-icon">
                <RedCheckMark size={isMobile ? "sm" : "default"} />
            </div>
        </div>
    )
}

export default SessionHoldItem
