import { type MutableRefObject, useCallback, useRef, useState } from "react"

import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { LikertColorType } from "@/models/types"
import LikertRadio from "@/shared/likert/LikertRadio"
import type { TEmptyCallback } from "@/shared/types/functions"

import { PairingQuestionAnswerOption } from "../models"

type Props = {
    options: PairingQuestionAnswerOption[]
    likertColorType: LikertColorType
    register: any
    control: any
    name: any
    className?: string
    showOddLabels?: boolean
}

const handleFieldsetAddChildrenHover: (ref: MutableRefObject<HTMLFieldSetElement>) => void = (
    parentRef: MutableRefObject<HTMLFieldSetElement>
): void => {
    // <LikertRadio /> hover state handle is unreadable and unmanageable. This is written on top of it to cover WISER-3591 && WISER=3772
    // DON'T REMOVE UNLESS REQUESTED
    const children: HTMLCollection = parentRef?.current?.children

    if (children) {
        for (let i: number = 0; i < children.length; i += 1) {
            children[i].classList.add("unhovered")
        }
    }
}

const handleFieldsetRemoveChildrenHover: (ref: MutableRefObject<HTMLFieldSetElement>) => void = (
    parentRef: MutableRefObject<HTMLFieldSetElement>
): void => {
    // <LikertRadio /> hover state handle is unreadable and unmanageable. This is written on top of it to cover WISER-3591 && WISER=3772
    // DON'T REMOVE UNLESS REQUESTED
    const children: HTMLCollection = parentRef?.current?.children

    if (children) {
        for (let i: number = 0; i < children.length; i += 1) {
            children[i].classList.remove("unhovered")
        }
    }
}

export const PairingQuestionLikertRow = ({
    options,
    likertColorType,
    register,
    control,
    name,
    className = "",
    showOddLabels = false
}: Props) => {
    const [hoveredId, setHoveredId] = useState<string | null>(null)

    const fieldsetRef: MutableRefObject<HTMLFieldSetElement> = useRef<HTMLFieldSetElement>(null)

    const handleMouseEnter: TEmptyCallback = useCallback((): void => handleFieldsetAddChildrenHover(fieldsetRef), [])
    const handleMouseLeave: TEmptyCallback = useCallback((): void => handleFieldsetRemoveChildrenHover(fieldsetRef), [])

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    return (
        <fieldset
            className={`mb-4 level-choice level-choice--${likertColorType} ${className} d-flex justify-content-center`}
            ref={fieldsetRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {options?.map((checkbox, index, options) => (
                <LikertRadio
                    name={name}
                    register={register}
                    hoveredId={hoveredId}
                    setHovered={setHoveredId}
                    showHover
                    control={control}
                    label={getValueFromTranslatedObject(checkbox.optionText)}
                    id={`${checkbox.uuid}`}
                    value={`${checkbox.uuid}`}
                    isFirstOption={index === 0}
                    key={`${checkbox.uuid}`}
                    color={likertColorType === "colored" ? checkbox.color : undefined}
                    showLabel={showOddLabels && options?.length % 2 !== 0 ? (index + 1) % 2 !== 0 : true}
                    isLastOption={index === options.length - 1}
                />
            ))}
        </fieldset>
    )
}
