import { SVGProps } from "react"

interface Props extends SVGProps<SVGSVGElement> {}

const HappyOwlSvg = ({ width = 154, height = 154, ...rest }: Props) => (
    <svg width={width} height={height} viewBox="0 0 154 154" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M89.2897 50.4669L93.0065 71.2115C94.1675 77.6915 100.362 82.0034 106.842 80.8423C107.667 80.6945 108.475 80.4599 109.251 80.1425L108.006 80.6514C117.408 76.807 124.199 68.4313 126.016 58.4376C128.3 45.8813 129.216 38.3569 128.765 35.8644C128.475 34.2621 128.16 32.6554 127.821 31.0444C127.375 28.9224 125.293 27.5641 123.171 28.0105C122.708 28.1078 122.267 28.2879 121.868 28.542L89.9913 48.8657C89.4546 49.2079 89.1775 49.8403 89.2897 50.4669Z"
            fill="#DA4200"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.7074 72.1948L60.7818 72.6221C67.3636 72.7556 72.5911 78.1994 72.4576 84.7813C72.4406 85.6193 72.3353 86.4532 72.1433 87.2692L68.7681 101.617C47.3734 109.593 34.9381 113.542 31.4622 113.465C29.8338 113.429 28.1973 113.369 26.5527 113.287C24.387 113.177 22.7198 111.333 22.829 109.168C22.8527 108.696 22.9616 108.232 23.1502 107.798L38.2355 73.1383C38.4895 72.5545 39.0709 72.1819 39.7074 72.1948Z"
            fill="#DA4200"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M69.2014 125.52L79.3731 139.777C79.8769 140.484 80.8577 140.648 81.5638 140.144C81.9494 139.869 82.1903 139.434 82.2193 138.961L82.619 132.433L82.9544 126.957L83.1221 124.218L76.2456 123.5L69.2014 125.52Z"
            fill="#FEA57F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.4431 121.259L95.0364 123.493L98.2231 127.96L102.021 133.284C102.525 133.99 103.506 134.154 104.212 133.65C104.598 133.375 104.839 132.941 104.868 132.468L105.938 114.986L98.8939 117.006L93.4431 121.259Z"
            fill="#FEA57F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8065 28.6893L49.0023 31.1187C57.4105 31.8183 65.784 29.4173 72.5438 24.3683L96.1319 6.74994C97.0006 6.10112 98.2307 6.27934 98.8796 7.14801C99.1758 7.54457 99.3112 8.03839 99.2588 8.53058L98.8446 12.4225C98.337 17.1916 98.7556 22.0139 100.078 26.6241L102.079 33.6047L110.086 61.5269L115.654 80.945C121.423 101.063 109.79 122.048 89.6727 127.816C69.5551 133.585 48.5701 121.953 42.8014 101.835L37.2334 82.417L29.2268 54.4948L27.2252 47.5142C25.9047 42.9093 23.6551 38.6237 20.6149 34.9217L18.1265 31.8916C17.4385 31.0537 17.5599 29.8167 18.3978 29.1286C18.7924 28.8045 19.2977 28.647 19.8065 28.6893Z"
            fill="#FE4D00"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.277 80.4939L76.8029 80.0564C88.1602 76.7997 100.007 83.3666 103.264 94.724C106.521 106.081 99.9537 117.928 88.5963 121.185L87.0704 121.623C75.7131 124.879 63.866 118.312 60.6094 106.955C57.3527 95.5976 63.9196 83.7506 75.277 80.4939Z"
            fill="#FEA57F"
            stroke="#FEA57F"
            strokeWidth={3.14106}
        />
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82.6813 50.9705C85.7624 51.132 95.2826 54.3756 95.4179 51.7944C95.7866 44.7584 90.3795 38.7556 83.3407 38.3867C76.302 38.0178 70.297 43.4226 69.9283 50.4586C69.5595 57.4946 79.6001 50.809 82.6813 50.9705Z"
                fill="#FFDCCE"
            />
            <path
                opacity={0.9}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82.7894 51.1332C86.3629 51.3205 89.1964 52.684 89.2599 51.4723C89.4471 47.9002 86.7019 44.8526 83.1284 44.6653C79.5549 44.478 76.5062 47.222 76.319 50.7941C76.1318 54.3662 79.2159 50.9459 82.7894 51.1332Z"
                fill="#071C41"
            />
            <path
                d="M78.4291 48.0442C79.512 48.1009 80.4359 47.2694 80.4926 46.187C80.5493 45.1045 79.7175 44.181 78.6346 44.1242C77.5517 44.0675 76.6278 44.899 76.5711 45.9815C76.5144 47.0639 77.3462 47.9874 78.4291 48.0442Z"
                fill="#FFDCCE"
            />
        </g>
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.656 59.0056C52.1286 60.7754 45.7739 68.571 44.2914 66.4538C40.2501 60.6823 41.6546 52.7262 47.4283 48.6834C53.202 44.6406 61.1586 46.042 65.1999 51.8135C69.2411 57.5849 57.1834 57.2359 54.656 59.0056Z"
                fill="#FFDCCE"
            />
            <path
                opacity={0.9}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.6503 59.2015C51.719 61.2541 50.0387 63.9119 49.3428 62.9179C47.2911 59.9878 48.0041 55.9486 50.9354 53.8961C53.8667 51.8436 57.9062 52.555 59.9579 55.4852C62.0096 58.4153 57.5816 57.149 54.6503 59.2015Z"
                fill="#071C41"
            />
            <path
                d="M50.0318 57.6424C49.1435 58.2644 47.9194 58.0488 47.2977 57.1609C46.676 56.2729 46.8921 55.0489 47.7803 54.427C48.6686 53.805 49.8927 54.0206 50.5144 54.9085C51.1361 55.7964 50.9201 57.0204 50.0318 57.6424Z"
                fill="#FFDCCE"
            />
        </g>
        <g>
            <path
                d="M71.139 108.059L77.5841 111.28C78.1676 111.571 78.8772 111.368 79.2176 110.811L82.3748 105.648C82.7152 105.092 83.4248 104.888 84.0083 105.18L89.4217 107.885C90.0052 108.177 90.7148 107.973 91.0552 107.417L94.8142 101.27"
                stroke="#FE4D00"
                strokeWidth={3.14106}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <g>
            <path
                d="M68.2176 97.8713L74.6627 101.092C75.2462 101.384 75.9559 101.18 76.2962 100.624L79.4534 95.4609C79.7938 94.9044 80.5034 94.7009 81.087 94.9925L86.5003 97.6976C87.0838 97.9892 87.7934 97.7858 88.1338 97.2292L91.8928 91.0825"
                stroke="#FE4D00"
                strokeWidth={3.14106}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.5071 68.7537L78.6725 64.7007C79.1487 63.8093 78.8152 62.7006 77.9261 62.22L76.7333 61.5752C76.4675 61.4315 76.1561 61.3977 75.8657 61.481L65.8366 64.3568C65.5397 64.4419 65.2893 64.6425 65.1412 64.9136L64.5118 66.0665C64.0309 66.9474 64.3311 68.0507 65.192 68.5664L68.983 70.8376C71.5367 72.3674 74.847 71.5375 76.3768 68.9838C76.4221 68.9082 76.4655 68.8315 76.5071 68.7537Z"
                fill="#1F2E4F"
                fillOpacity={0.260899}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.5377 60.9077C66.5438 60.8868 66.5499 60.8656 66.556 60.8442C67.1804 58.6456 69.469 57.3694 71.6676 57.9938C72.3005 58.1736 72.8814 58.5016 73.3622 58.9508C74.0796 59.621 75.0227 60.2141 76.1913 60.73C76.5806 60.9019 76.7569 61.3568 76.5851 61.7461C76.5127 61.91 76.3855 62.0436 76.2253 62.1239C75.1863 62.6445 74.2033 63.2703 73.2918 63.9914L71.9838 65.0261C71.6948 65.2547 71.3272 65.3601 70.9609 65.3194L69.3034 65.135C68.1483 65.0066 66.983 64.9968 65.8259 65.1058C65.415 65.1446 65.0504 64.8428 65.0117 64.4319C64.9956 64.2608 65.0389 64.0895 65.1343 63.9467C65.7431 63.0351 66.2109 62.0221 66.5377 60.9077Z"
                fill="#FFDCCE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M77.4952 68.6973C77.4434 68.8108 77.392 68.9308 77.341 69.0572C76.1481 72.0152 72.7832 73.4461 69.8252 72.2533C69.3462 72.0601 68.8952 71.8035 68.4843 71.4904C68.4129 71.4359 68.3445 71.3858 68.2791 71.34C67.6889 70.9267 66.6158 70.364 65.0597 69.6516C64.7726 69.5202 64.6464 69.181 64.7779 68.8939C64.893 68.6424 65.1716 68.5094 65.4395 68.578L70.8189 69.956C72.1685 70.3017 73.5995 69.8913 74.5608 68.883L78.3925 64.8638C78.6123 64.6333 78.9772 64.6246 79.2077 64.8443C79.4082 65.0355 79.4444 65.3423 79.2939 65.5749C78.4492 66.8801 77.8497 67.9208 77.4952 68.6973Z"
                fill="#FFDCCE"
            />
        </g>
    </svg>
)

export { HappyOwlSvg }
