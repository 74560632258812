import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"

import { ServerStateOnbordingKeys } from "../constants"

const getReflectionObjective = async () => {
    const { data } = await http.get(Urls.onboardingStep5())
    return data
}

export default function useReflectionObjective() {
    return useQuery([ServerStateOnbordingKeys.ReflectionObjective], getReflectionObjective, {
        select: useCallback(data => {
            return data
        }, [])
    })
}
