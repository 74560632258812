import { SVGProps, memo } from "react"

import "./styles.scss"

interface Props extends SVGProps<SVGSVGElement> {}

const ChatMessageLoaderSvg = (props: Props) => {
    const { width = 102, height = 43, className = "" } = props

    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 102 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0.5 5C0.5 2.23858 2.73858 0 5.5 0H43.5V10H0.5V5Z" fill="#FD4D00" />
            <path d="M1 9.5H43V42.5H5.5C3.01472 42.5 1 40.4853 1 38V9.5Z" fill="white" />
            <path d="M1 9.5H43V42.5H5.5C3.01472 42.5 1 40.4853 1 38V9.5Z" stroke="#E3E3E3" />
            <path d="M7.5 21H36.5" stroke="#B0B0B0" strokeLinecap="round" />
            <path d="M7.5 26H36.5" stroke="#B0B0B0" strokeLinecap="round" />
            <path d="M7.5 31H36.5" stroke="#B0B0B0" strokeLinecap="round" />
            <rect width="30" height="10" transform="translate(42.5)" fill="#02B1D8" />
            <path d="M43 9.5H72V42.5H43V9.5Z" fill="white" />
            <path d="M43 9.5H72V42.5H43V9.5Z" stroke="#E3E3E3" />
            <path d="M49.5 21H65.5" stroke="#B0B0B0" strokeLinecap="round" />
            <path d="M49.5 26H65.5" stroke="#B0B0B0" strokeLinecap="round" />
            <path d="M49.5 31H65.5" stroke="#B0B0B0" strokeLinecap="round" />
            <path d="M71.5 0H96.5C99.2614 0 101.5 2.23858 101.5 5V10H71.5V0Z" fill="#081C41" />
            <path d="M72 9.5H101V38C101 40.4853 98.9853 42.5 96.5 42.5H72V9.5Z" fill="white" />
            <path d="M72 9.5H101V38C101 40.4853 98.9853 42.5 96.5 42.5H72V9.5Z" stroke="#E3E3E3" />
            <path d="M78.5 21H94.5" stroke="#B0B0B0" strokeLinecap="round" />
            <path d="M78.5 26H94.5" stroke="#B0B0B0" strokeLinecap="round" />
            <path d="M78.5 31H94.5" stroke="#B0B0B0" strokeLinecap="round" />
        </svg>
    )
}

export default memo(ChatMessageLoaderSvg)
