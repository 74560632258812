import classNames from "classnames"

import "./styles.scss"

type Props = {
    active: boolean
}

export const SummaryChevron = ({ active }: Props) => {
    return (
        <svg
            width="16"
            height="11"
            viewBox="0 0 16 11"
            fill="none"
            className={classNames("summary-chevron", { active })}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M14.9999 9.5L7.99988 2.5L0.999878 9.5" strokeWidth="2.5" />
        </svg>
    )
}
