import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"

import { useTranslation } from "react-i18next"

import { CalendarAccount } from "@/entities/coach-calendar"
import Accordion from "@/shared/accordion/Accordion"
import Card from "@/shared/card/Card"
import ChevronToggle from "@/shared/chevron-toggle/ChevronToggle"

import { CalendarPrimaryItem } from "./CalenadarPrimaryItem"

import "./styles.scss"

type Props = {
    data: CalendarAccount[]
}

export const CalendarPrimary = ({ data }: Props) => {
    const [active, setActive] = useState(true)

    const [accordionTitle, setAccordionTitle]: [string, Dispatch<SetStateAction<string>>] = useState<string>(String())

    const { t } = useTranslation()

    const primaryProfile = useMemo(() => data?.find(calendar => calendar.coachHasPrimaryCalendarProfile), [data])

    const primaryCalendar = useMemo(
        () => primaryProfile?.profileCalendars?.find(calendar => calendar.isCalendarPrimary),
        [primaryProfile]
    )

    const handleClick = useCallback((title: string) => (setActive(false), setAccordionTitle(title)), [])

    return (
        <Card className="calendar-primary text-left">
            <div className="calendar-primary__header cursor-pointer" onClick={() => setActive(!active)}>
                <div className="flex-1">
                    <span className="color-brand font-extrabold">
                        {primaryProfile
                            ? `${primaryProfile?.profileName}: `
                            : accordionTitle || t("coachSide.linkCalendar.selectCalendarBlock.formTitle")}
                    </span>
                    <span>{primaryCalendar?.calendarName}</span>
                </div>
                <div>
                    <ChevronToggle active={active} />
                </div>
            </div>
            <Accordion active={active}>
                {data?.map(calendar => <CalendarPrimaryItem onClick={handleClick} calendar={calendar} />)}
            </Accordion>
        </Card>
    )
}
