import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"

import { ServerStateOnbordingKeys } from "../constants"
import { PreprogramDiagnostic } from "../models/PreprogramDiagnostic"

const getPreprogramDiagnosticQuestions = async () => {
    const { data } = await http.get(Urls.onboardingStep3())
    return data
}

export default function usePreprogramQuestions() {
    return useQuery<PreprogramDiagnostic[]>([ServerStateOnbordingKeys.Preprogram], getPreprogramDiagnosticQuestions, {
        select: useCallback(data => {
            return data.map(item => new PreprogramDiagnostic(item))
        }, [])
    })
}
