import { type FC, useEffect, useMemo, useState } from "react"

import { useTranslation } from "react-i18next"

import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { ERichTextRendererMode, RichTextRenderer } from "@/shared/rich-text-renderer"
import AttentionSvg from "@/svgs/AttentionSvg"

import { ChapterComponent, IQuestionFreeResponse } from "Manager/chapter/models/types"

import NextButton from "./NextButton"
import ResultsFreeResponse from "./ResultsFreeResponse"

interface IProps {
    component: IQuestionFreeResponse
    onNext: (component: ChapterComponent, answer?: string) => void
    isEditable: boolean
    isLast: boolean
    disabledBtn: boolean
}

const QuestionFreeResponse: FC<IProps> = ({ component, onNext, isLast = false, isEditable, disabledBtn }) => {
    const { t } = useTranslation()
    const [text, setText] = useState("")
    const [toggleSummary, setToggleSummary] = useState(false)
    const [hasError, setHasError] = useState(false)

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    const content = useMemo(() => {
        return component?.answer_data?.answer
    }, [component])

    useEffect(() => {
        if (component?.answer_data) {
            setText(component?.answer_data?.answer ?? "")

            if (component.show_summary_after_finish && !isEditable) {
                setToggleSummary(true)
            }
        }
    }, [component])

    const onClick = () => {
        if (content && !isEditable) {
            onNext(component)
            return
        }
        if (component?.show_summary_after_finish && !toggleSummary) {
            if (!content && !text) {
                setHasError(true)
                return
            }
            setToggleSummary(true)
            return
        }

        if (!text) {
            setHasError(true)
            return
        }

        if (hasError) {
            setHasError(false)
        }

        onNext(component, text)
        setText("")
    }

    return (
        <>
            {toggleSummary ? (
                <ResultsFreeResponse
                    component={component}
                    disabledBtn={disabledBtn}
                    onNext={onClick}
                    isLast={isLast}
                    answer={!isEditable && content ? content : text}
                />
            ) : (
                <div className="px-3 text-center">
                    <div className="container--phase text-center">
                        <div className="px-3">
                            <h1 className="h2 font-extrabold font-italic">
                                {getValueFromTranslatedObject(component?.title)}
                            </h1>
                            <h2 className="h5 color-brand text-uppercase font-extrabold">
                                {getValueFromTranslatedObject(component?.question)}
                            </h2>
                            <div className="text-container text-container--sm">
                                <RichTextRenderer content={component?.content} mode={ERichTextRendererMode.View} />
                            </div>
                        </div>
                        <textarea
                            className="participant-textarea mb-6"
                            disabled={!isEditable && !!content}
                            placeholder={t("Care to elaborate?")}
                            value={text}
                            onChange={e => setText(e.target.value)}
                        />
                        {hasError && (
                            <div className="input-error">
                                <AttentionSvg />
                                <span>{t("Answer is required")}</span>
                            </div>
                        )}
                        <NextButton isLast={isLast} className="mt-3 mb-6" onClick={onClick} disabled={disabledBtn} />
                    </div>
                </div>
            )}
        </>
    )
}

export default QuestionFreeResponse
