enum ESurveyStatus {
    Completed,
    InCompleted,
    Expired
}

type TParticipantSurveyStatusDto = "expired" | "incomplete" | "completed"

interface IParticipantSurveyDto {
    id: number
    status: TParticipantSurveyStatusDto
    title: string
    close_date: string
    access_code: string
}

interface IParticipantSurveyModel {
    id: number
    status: ESurveyStatus
    title: string
    closeDate: string
    accessCode: string
}

interface ISurveysDto {
    count: number
    next: null | string
    previous: null | string
    results: IParticipantSurveyDto[]
}

interface ISurveysModel {
    count: number
    next: null | string
    previous: null | string
    results: IParticipantSurveyModel[]
}

export {
    type IParticipantSurveyDto,
    type IParticipantSurveyModel,
    type ISurveysDto,
    type ISurveysModel,
    type TParticipantSurveyStatusDto,
    ESurveyStatus
}
