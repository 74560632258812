import { useCallback, useState } from "react"

import { IConversationScenario, ITrackPractice } from "Manager/chapter/models/types"

type THandleCompletedActivitiesCountFunction = (rows: any[], scenarios?: IConversationScenario[]) => void

type TUsePracticeActivities = ReturnType<
    () => {
        totalActivitiesCount: 2
        handleCompletedActivitiesCount: THandleCompletedActivitiesCountFunction
        completedActivitiesCount: number
    }
>

function usePracticeActivities({ component }: { component: ITrackPractice }): TUsePracticeActivities {
    const [completedActivitiesCount, setCompletedActivitiesCount] = useState<number>(
        getCompletedActivitiesCount(component?.answer_data?.rows, component?.practice_lab?.conversation_scenarios)
    )
    const handleCompletedActivitiesCount: THandleCompletedActivitiesCountFunction = useCallback(
        (rows: any[]) =>
            setCompletedActivitiesCount(
                getCompletedActivitiesCount(rows, component?.practice_lab?.conversation_scenarios)
            ),
        [component?.practice_lab?.conversation_scenarios]
    )

    return {
        totalActivitiesCount: 2,
        handleCompletedActivitiesCount,
        completedActivitiesCount
    }
}

export { usePracticeActivities, type TUsePracticeActivities, getCompletedActivitiesCount }

function getCompletedActivitiesCount(rows?: any[], scenarios?: IConversationScenario[]): number {
    return (
        Number(scenarios?.every((s: IConversationScenario) => s?.started && s?.completed) ?? 0) +
        Number(rows?.some(row => row?.columns?.some(col => col?.answer?.length > 0)) ?? 0)
    )
}
