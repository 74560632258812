import { useTranslation } from "react-i18next"

import Modal from "@/shared/modal"
import { sanitizeContent } from "@/utils/common"

import "./styles.scss"

type Props = {
    show: boolean
    categoryName: string
    categoryDescription: string
    onClose: () => void
}

const CategoryModal = ({ show, onClose, categoryName, categoryDescription }: Props) => {
    const { t } = useTranslation()

    return (
        <Modal
            show={show}
            onClose={onClose}
            modalBodyClass="category-modal"
            modalOpenBtnLabel={categoryName}
            headerContent={
                <div className="p-3">
                    <h4 className="h4 font-extrabold m-0 text-center">
                        {t("What is {{categoryName}}", { categoryName: `"${categoryName}"` })}
                    </h4>
                </div>
            }
        >
            <div className="category-modal__body">
                <p
                    className="m-0 text-center"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeContent(categoryDescription, {
                            allowedTags: []
                        })
                    }}
                />
            </div>
        </Modal>
    )
}

export default CategoryModal
