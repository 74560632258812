import React, { type FC, type ReactElement } from "react"

import Heading from "$/components/Heading/Heading"

import { type IUseTranslation, useTranslation } from "@/hooks"

const SwapSessionHeading: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <Heading textAlign="center" tag="h1" className="swap-session__heading">
            {t("participantSide.swapSessionsScreen.title")}
        </Heading>
    )
}

export { SwapSessionHeading }
