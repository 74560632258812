import Heading from "$/components/Heading/Heading"

import { SimulabCoachee } from "@/entities/simulab"
import Avatar from "@/shared/avatar/Avatar"

import { SimulabCoachingIntroCard } from "./SimulabCoachingIntroCard"

type Props = {
    coachee: SimulabCoachee
    showButton: boolean
    onButtonClick: () => void
}

export const SimulabChatHeader = ({ coachee, showButton, onButtonClick }: Props) => {
    return (
        <div className="simulab-chat-header">
            <div className="d-flex align-items-center mb-3">
                <Avatar url={coachee.photo} width={40} height={40} className="mr-3" />
                <Heading tag="h3" fontSize={24} className="mb-0">
                    {coachee.name}
                </Heading>
            </div>
            <SimulabCoachingIntroCard coachee={coachee} showButton={showButton} onButtonClick={onButtonClick} />
        </div>
    )
}
