const APOSTROPHE_CHAR = "’"

// TODO add more complex cases to cover all cases like irregular nouns like children or people
export const possessiveForm = (str: string): string => {
    if (!str) {
        return str
    }

    if (str.endsWith("s")) {
        return `${str}${APOSTROPHE_CHAR}`
    }

    return `${str}${APOSTROPHE_CHAR}s`
}

export const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
        return str
    }
    return `${str.slice(0, num)}...`
}
