import { useCallback } from "react"

import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "$/http"

import Urls from "@/api/urls"
import { CoachingGoalStatus } from "@/constants"

import { QueryKey } from "../consts"
import { BehavioralPractice, BehavioralPracticeApi } from "../model/behavioral-goal"

const getBehavioralPractice = async ({ queryKey }): Promise<BehavioralPracticeApi[]> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_key, { behavioralChangeId, participantId, query }] = queryKey
    const { data } = await http.get(Urls.behavioralPractice(participantId, behavioralChangeId, query))
    return data
}

export function useBehavioralPracticeData(
    participantId: number,
    behavioralChangeId: number,
    query: CoachingGoalStatus
) {
    return useQuery<BehavioralPracticeApi[], AxiosError, BehavioralPractice[]>(
        [QueryKey.BehavioralPractice, { participantId, behavioralChangeId, query }],
        getBehavioralPractice,
        {
            select: useCallback(data => data.map(item => new BehavioralPractice(item)), [])
        }
    )
}

export function useArchivedBehavioralPracticeData(participantId: number, behavioralChangeId: number) {
    return useQuery<BehavioralPracticeApi[], AxiosError, number[]>(
        [QueryKey.BehavioralPractice, { participantId, behavioralChangeId, query: "archived" }],
        getBehavioralPractice,
        {
            select: useCallback(data => data.map(item => item.id), [])
        }
    )
}

export function useCreateBehavioralPractice(participantId: number) {
    return useMutation(
        (behavioralPractice: Pick<BehavioralPractice, "description" | "order" | "status" | "behavioralChangeId">) => {
            const { behavioralChangeId, ...data } = behavioralPractice
            return http.post<{ id: number }>(Urls.createBehavioralPractice(participantId, behavioralChangeId), data)
        }
    )
}

export function useEditBehavioralPractice(participantId: number) {
    return useMutation(
        (
            behavioralPractice: Pick<
                BehavioralPractice,
                "description" | "order" | "status" | "behavioralChangeId" | "id"
            >
        ) => {
            const { behavioralChangeId, id, ...data } = behavioralPractice
            return http.put(Urls.editBehavioralPractice(participantId, behavioralChangeId, id), data)
        }
    )
}

export function useArchiveBehavioralPractice(participantId: number) {
    return useMutation(({ behavioralChangeId, practiceId }: { behavioralChangeId: number; practiceId: number }) =>
        http.post(Urls.archiveBehavioralPractice(participantId, behavioralChangeId, practiceId))
    )
}

export function useUnarchiveBehavioralPractice(participantId: number) {
    return useMutation(({ behavioralChangeId, practiceId }: { behavioralChangeId: number; practiceId: number }) =>
        http.post(Urls.unarchiveBehavioralPractice(participantId, behavioralChangeId, practiceId))
    )
}

export function useSortBehavioralPractice(participantId: number) {
    return useMutation((data: { ids: number[]; behavioralChangeId: number }) => {
        const { behavioralChangeId, ids } = data
        return http.post(Urls.sortBehavioralPractice(participantId, behavioralChangeId), { ids })
    })
}
