import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

type Props = {
    content: string
    className?: string
}

const MarkdownRenderer = ({ content, className = "" }: Props) => {
    return (
        <ReactMarkdown
            className={className}
            components={{ strong: ({ ...props }) => <strong style={{ fontWeight: 700 }} {...props} /> }}
            remarkPlugins={[remarkGfm]}
        >
            {content}
        </ReactMarkdown>
    )
}

export default MarkdownRenderer
