import { useCallback, useEffect, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import useMediaQuery from "$/hooks/use-media-query"
import { isNullOrUndefined } from "$/utils/gates"

import { dashboardURL } from "@/components/onboarding/constants"
import { MAX_WIDTH_MOBILE_MEDIA, ServerStateKeys } from "@/constants"
import { useAuthContext, useStoreContext } from "@/context"
import { UserEnrollModule } from "@/models/user"

import { ModuleList } from "./ModuleList"

import "./styles.scss"

type Props = {
    className?: string
}

export const ModuleNavigation = ({ className = "" }: Props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams()
    const { pathname } = useLocation()
    const queryClient = useQueryClient()
    const { user } = useAuthContext()
    const { toggleBurgerMenu, isOpenBurgerMenu } = useStoreContext()
    const isMobileView = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const [collapseModules, setCollapseModules] = useState(false)

    useEffect(() => {
        if (isNullOrUndefined(params.moduleId)) {
            return
        }

        const module = user?.enrolledModules.find(module => module.rank === +params.moduleId)

        if (isNullOrUndefined(module)) {
            setCollapseModules(true)
        }
    }, [user, params])

    const onModuleClick = useCallback(
        (module: UserEnrollModule, moduleIndex: number) => {
            if (isOpenBurgerMenu && isMobileView) {
                toggleBurgerMenu()
            }

            if (!module) {
                navigate(`${dashboardURL}/${moduleIndex}`)
                return
            }
            queryClient.refetchQueries([ServerStateKeys.Phases, { id: module.id }])
            navigate(`${dashboardURL}/${module.rank}`, { replace: true })
        },
        [user, isOpenBurgerMenu, isMobileView]
    )

    const onCollapseUnassignModulesClick = useCallback(() => {
        if (isOpenBurgerMenu && isMobileView && collapseModules) {
            toggleBurgerMenu()
        }
        setCollapseModules(true)
    }, [isOpenBurgerMenu, isMobileView, collapseModules])

    return (
        <section className={className}>
            <p
                className={classNames("module-navigation_title", {
                    "module-active": isMobileView,
                    "font-extrabold": pathname.includes("/dashboard")
                })}
            >
                {t("Modules")}
            </p>
            <ModuleList
                collapseModules={collapseModules}
                user={user}
                onModuleClick={onModuleClick}
                onCollapseUnassignModulesClick={onCollapseUnassignModulesClick}
            />
        </section>
    )
}
