import { useMutation } from "@tanstack/react-query"

import { http } from "$/http"

import { SimulabFeedback } from "../../models"
import { Urls } from "../urls"

export function useSendSimulabFeedback() {
    return useMutation((feedback: SimulabFeedback) => http.post(Urls.sendFeedback(), feedback))
}
