import * as yup from "yup"

import { i18n } from "$/i18n"

export const profileSetupValidation = yup.object({
    first_name: yup
        .string()
        .required(i18n.t("First name is required"))
        .max(60, i18n.t("participantSide.profile.editForm.firstName.message.validationLength")),
    last_name: yup
        .string()
        .required(i18n.t("Last name is required"))
        .max(60, i18n.t("participantSide.profile.editForm.lastName.message.validationLength"))
})
