import classNames from "classnames"

import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"

import AssessmentResponseItem from "./AssessmentResponseItem"

import "./styles.scss"

type diagnosticAnswer = {
    question: {
        en: string
    }
    answer: string
    [key: string]: any
}

type Props = {
    title: string
    diagnosticAnswers: diagnosticAnswer[]
    variant?: "offboarding" | ""
    className?: string
}

const AssessmentResponses = ({ title, diagnosticAnswers, variant = "", className = "" }: Props) => {
    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    return (
        <div className={classNames("assessment-responses", variant, { [className]: className })}>
            <div className="assessment-responses-header">{title}</div>
            {diagnosticAnswers?.map(answer => (
                <AssessmentResponseItem
                    question={getValueFromTranslatedObject(answer.question)}
                    answer={answer.answer}
                />
            ))}
        </div>
    )
}

export default AssessmentResponses
