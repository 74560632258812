import { useCoachAccountsRelinkingConfirmMutation } from "./use-coach-accounts-relinking-confirm-mutation"
import { useCoachAccountsRelinkingDisconnectMutation } from "./use-coach-accounts-relinking-disconnect-mutation"
import { useCoachAccountsToRelinkQuery } from "./use-coach-accounts-to-relink-query"

const coachRelinkAccountsApiHooks = {
    useCoachAccountsRelinkingConfirmMutation,
    useCoachAccountsRelinkingDisconnectMutation,
    useCoachAccountsToRelinkQuery
}

export type {
    ICoachAccountsRelinkingConfirmMutationApiData,
    TUseCoachAccountsRelinkingConfirmMutation,
    ICoachAccountsRelinkingConfirmMutationProfileApiData
} from "./use-coach-accounts-relinking-confirm-mutation"
export type {
    ICoachAccountsRelinkingDisconnectApiData,
    TUseCoachAccountsRelinkingDisconnectMutation
} from "./use-coach-accounts-relinking-disconnect-mutation"
export type {
    ICoachAccountsToRelinkModel,
    ICoachAccountZoomToRelinkModel,
    ICoachAccountCalendarToRelinkModel,
    TUseCoachAccountsToRelinkQuery
} from "./use-coach-accounts-to-relink-query"
export { coachRelinkAccountsApiHooks }
