import { FC, ReactElement } from "react"

import { type IUseTranslation, useTranslation } from "@/hooks"
import Avatar from "@/shared/avatar/Avatar"
import Button from "@/shared/button/Button"
import GroupIcon from "@/svgs/GroupIcon"
import { formatDateSessions, getTimezoneAbbreviation } from "@/utils/date"

import type { ICoachSessionWithoutAttendanceMarksModel as IModel } from "../api"

type TProps = {
    session: IModel
    isIndividualSession: boolean
    onOpenAttendanceModal?: (sessionId: number) => void
}

const CoachMarkAttendanceSessionListItem: FC<TProps> = ({
    onOpenAttendanceModal,
    isIndividualSession,
    session
}: TProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className="mark-attendance-box__session-list__item">
            <div className="wrapper">
                <div className="mr-5">
                    {isIndividualSession ? (
                        <Avatar url={session.photo} width={50} height={50} alt="coach photo" />
                    ) : (
                        <GroupIcon width={50} heigth={50} />
                    )}
                </div>
                <div className="column">
                    <span className="font-extrabold">{session.title}</span>
                    <span className="modality color-gray fs-14">{session?.sessionModality}</span>
                </div>
                <div className="column">
                    <span className="font-extrabold">{session.sessionType}</span>
                    <span className="color-gray fs-14">
                        {session?.duration}&nbsp;{t("min")}
                    </span>
                </div>
                <div className="column">
                    <span className="d-block">{formatDateSessions(session?.sessionTime)}</span>
                    <span className="color-gray fs-14">{getTimezoneAbbreviation()}</span>
                </div>
            </div>
            <Button variant="brand" className="p-2" onClick={() => onOpenAttendanceModal(session?.sessionId)}>
                <span className="font-extrabold font-sm">{t("Mark Attendance")}</span>
            </Button>
        </div>
    )
}

export { CoachMarkAttendanceSessionListItem }
