import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"
import { ServerStateKeys } from "@/constants"
import { useAuthContext } from "@/context"

export const getCoachingSessions = async ({ queryKey }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_key, { id }] = queryKey
    const { data } = await http.get(Urls.coachingSessions(id))
    return data
}

type QueryOptions = {
    id?: number
    enabled?: boolean
}

export default function useCoachingSessions({ id, enabled = true }: QueryOptions = {}) {
    const { user } = useAuthContext()
    return useQuery([ServerStateKeys.CoachingSession, { id }], getCoachingSessions, {
        select: useCallback(data => data, []),
        enabled: !!user && enabled
    })
}
