import {
    type Dispatch,
    type FC,
    type NamedExoticComponent,
    type ReactElement,
    type SetStateAction,
    memo,
    useCallback,
    useMemo,
    useState
} from "react"

import classNames from "classnames"
import {
    FixedSizeList as List,
    type FixedSizeListProps as TListProps,
    type ListOnItemsRenderedProps as TRenderFunctionParams
} from "react-window"

import { type IUseTranslation, useTranslation } from "@/hooks"
import WarnIcon from "@/svgs/WarnIcon"

import {
    ECoachSessionWithoutAttendanceMarksModality as ESessionModality,
    type ICoachSessionWithoutAttendanceMarksModel as IModel
} from "../api"

import { CoachMarkAttendanceSessionListItem as ListItem } from "./CoachMarkAttendanceSessionListItem"

type TProps = {
    sessions: IModel[]
    handleOpenAttendanceModal(sessionId: number): void
}

const LIST_ITEM_HEIGHT_IN_PX: number = 80
const LIST_ITEMS_COUNT: number = 3

const initialLoadCount = 20
const incrementalLoadCount = 10

const CoachMarkAttendanceBox: FC<TProps> = ({ sessions, handleOpenAttendanceModal }: TProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const [visibleItems, setVisibleItems]: [IModel[], Dispatch<SetStateAction<IModel[]>>] = useState<IModel[]>(
        sessions.length > 0 ? sessions.slice(0, initialLoadCount) : []
    )

    const handleItemRender: ({ visibleStopIndex }: TRenderFunctionParams) => void = useCallback(
        ({ visibleStopIndex }: TRenderFunctionParams) => {
            if (
                visibleStopIndex >= visibleItems.length - incrementalLoadCount &&
                visibleItems.length < sessions.length
            ) {
                const nextItems = sessions.slice(visibleItems.length, visibleItems.length + incrementalLoadCount)

                if (nextItems.length > 0) {
                    setVisibleItems((prevItems: IModel[]): IModel[] => [...prevItems, ...nextItems])
                }
            }
        },
        [sessions, visibleItems.length]
    )

    const lazyListProps: Omit<TListProps, "children"> = useMemo(
        (): Omit<TListProps, "children"> => ({
            onItemsRendered: handleItemRender,
            itemCount: visibleItems.length,
            itemSize: LIST_ITEM_HEIGHT_IN_PX,
            height: LIST_ITEM_HEIGHT_IN_PX * LIST_ITEMS_COUNT,
            width: "100%",
            overscanCount: 3,
            className: classNames("mark-attendance-box__session-list", { scrollable: sessions.length > 3 })
        }),
        [handleItemRender, sessions.length, visibleItems.length]
    )

    return (
        <section className="mark-attendance-box">
            <div className="mark-attendance-box__message-box">
                <WarnIcon className="mr-2" />
                <span className="font-extrabold font-sm">{t("coachSide.dashboard.attendancesBox.title")}</span>
            </div>
            <List {...lazyListProps}>
                {({ index, style }): ReactElement => (
                    <div style={style}>
                        <ListItem
                            key={`coach-mark-attendance-session-${visibleItems[index].sessionId}`}
                            session={visibleItems[index]}
                            onOpenAttendanceModal={handleOpenAttendanceModal}
                            isIndividualSession={visibleItems[index]?.sessionModality === ESessionModality.individual}
                        />
                    </div>
                )}
            </List>
        </section>
    )
}

function propsAreEqual(prev: TProps, next: TProps): boolean {
    return prev?.sessions?.length === next?.sessions?.length
}

const MemoizedCoachMarkAttendanceBox: NamedExoticComponent<TProps> = memo(CoachMarkAttendanceBox, propsAreEqual)

export { MemoizedCoachMarkAttendanceBox as default, type TProps as TCoachMarkAttendanceBoxProps }
