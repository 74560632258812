export enum CoachCalendarStepUrls {
    LINK_CALENDAR_STEP = "/link-calendar",
    LINK_ACCOUNT_STEP = "/link-account",
    AVAILABILITY_STEP = "/availability-step",
    AVAILABILITY_CONFIRM_STEP = "/availability-confirm-step",
    CALENDAR_ADD = "/add-calendar",
    RELINK_ACCOUNTS = "/relink-accounts"
}

export enum CoachSettingsUrls {
    CALENDARS = "/setup-calendar/calendars",
    AVAILABILITY = "/setup-calendar/availability",
    ZOOM_ACCOUNT = "/setup-calendar/zoom-account"
}
