import Heading from "$/components/Heading/Heading"

import "./styles.scss"

type Props = {
    heading: string
    text: string
}

const Notification = ({ heading, text }: Props) => {
    return (
        <div className="notification-success">
            <Heading tag="h4" textAlign="center" className="font-extrabold color-gray" fontSize={16}>
                {heading}
            </Heading>
            <p className="m-0 color-gray text-center">{text}</p>
        </div>
    )
}

export default Notification
