import { FunctionComponent, ReactElement } from "react"

import classNames from "classnames"

interface IProps {
    loading: boolean
}

const TableLoader: FunctionComponent<IProps> = ({ loading }: IProps): ReactElement => (
    <div className={classNames("table-loader", { hidden: !loading })} data-testid="loader">
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <div className="table-spinner" />
        </div>
    </div>
)

export { TableLoader }
