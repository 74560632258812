import CronofyWidget from "$/components/CronofyWidgets/CronofyWidget"
import { CRONOFY_WIDGET } from "$/constants"
import { getErrorMessages } from "$/utils/get-error-messages"

import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { useCalendarsAttrs } from "@/entities/coach-calendar/api/use-calendars-attrs"
import { SimpleLayout } from "@/layouts"
import DateWidget from "@/shared/DateWidget"
import { FullSpinner } from "@/shared/spinner"

const CalendarAdd = () => {
    const { data, isLoading, isError, error } = useCalendarsAttrs()

    return (
        <SimpleLayout header={<DateWidget className="coach-date-title" />} headerVariant="gray" showProfilePhoto>
            <div className="coach-calendar-wrapper mx-auto">
                {isError && <WarningMessage message={getErrorMessages(error?.response?.data)?.join("")} />}
                {isLoading ? (
                    <FullSpinner />
                ) : (
                    <CronofyWidget
                        id="calendar-sync"
                        widget={CRONOFY_WIDGET.CalendarSync}
                        options={{
                            authorization: data?.authorization,
                            data_center: data?.data_center,
                            locale: data?.locale,
                            target_id: "cronofy-calendar-sync",
                            styles: {
                                prefix: "CalendarSync"
                            }
                        }}
                    />
                )}
            </div>
        </SimpleLayout>
    )
}

export default CalendarAdd
