import { FC } from "react"

interface IProps {
    height?: number
    width?: number
}
const CheckMark: FC<IProps> = ({ height = 20, width = 16 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.58134 7.67752L7.88344 14.0063L18.1109 1.88273"
                stroke="#B0B0B0"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default CheckMark
