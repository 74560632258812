import { type FC, type NamedExoticComponent, type ReactElement, memo } from "react"

import Heading from "$/components/Heading/Heading"

import { type IUseTranslation, type TTranslationKeys, useTranslation } from "@/hooks"

import type { ICoachAccountCalendarToRelinkModel, ICoachAccountZoomToRelinkModel } from "../api"
import type { THandleButtonClickFunctions } from "../types"

import { CoachRelinkAccountsAccountItem as ListItem, CoachRelinkAccountFallbackSpinner as Spinner } from "."

enum ECoachRelinkAccountsAccountsListType {
    Zoom,
    Google
}

type THeadingMap = { [T in ECoachRelinkAccountsAccountsListType]: keyof TTranslationKeys }

const headingMap: THeadingMap = {
    [ECoachRelinkAccountsAccountsListType.Google]: "coachSide.relinkAccountsPage.calendarsBlock.title",
    [ECoachRelinkAccountsAccountsListType.Zoom]: "coachSide.relinkAccountsPage.zoomBlock.title"
}

type TCoachRelinkAccountsAccountListProps = {
    listType: ECoachRelinkAccountsAccountsListType
    accountsData: (ICoachAccountCalendarToRelinkModel[] | ICoachAccountZoomToRelinkModel[]) | []
    isAccountsDataLoading: boolean
} & THandleButtonClickFunctions

const CoachRelinkAccountsAccountListComponent: FC<TCoachRelinkAccountsAccountListProps> = ({
    listType,
    isAccountsDataLoading,
    accountsData,
    handleDisconnectButtonClick
}: TCoachRelinkAccountsAccountListProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className="coach-relink-accounts__accounts-list">
            <Heading tag="h4" fontSize={24} className="mb-1">
                {t(headingMap[listType])}
            </Heading>

            {isAccountsDataLoading ? (
                <Spinner />
            ) : (
                accountsData.map((a: ICoachAccountCalendarToRelinkModel | ICoachAccountZoomToRelinkModel) => (
                    <ListItem {...a} key={a.profileName} handleDisconnectButtonClick={handleDisconnectButtonClick} />
                ))
            )}
        </div>
    )
}

function propsAreEqual(
    prevProps: Readonly<TCoachRelinkAccountsAccountListProps>,
    nextProps: Readonly<TCoachRelinkAccountsAccountListProps>
): boolean {
    return (
        prevProps.accountsData === nextProps.accountsData &&
        prevProps.isAccountsDataLoading === nextProps.isAccountsDataLoading
    )
}

const CoachRelinkAccountsAccountList: NamedExoticComponent<TCoachRelinkAccountsAccountListProps> = memo(
    CoachRelinkAccountsAccountListComponent,
    propsAreEqual
)

export {
    CoachRelinkAccountsAccountList as default,
    ECoachRelinkAccountsAccountsListType,
    type TCoachRelinkAccountsAccountListProps
}
