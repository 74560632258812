import { FC } from "react"

import { isNullOrUndefined } from "$/utils/gates"

import useSpotCoaching from "@/api/use-spot-coaching"
import { CARE_TEAM_EMAIL } from "@/constants"

interface IProps {
    className?: string
}

const CareLinkEmail: FC<IProps> = ({ className }) => {
    const { data: productType } = useSpotCoaching()

    const productTypeData = isNullOrUndefined(productType)
        ? {
              supportEmail: CARE_TEAM_EMAIL.split(":")[1],
              supportTeamName: "Wiser Care"
          }
        : productType

    return (
        <a href={`mailto:${productTypeData?.supportEmail}`} className={className}>
            {productTypeData?.supportEmail}
        </a>
    )
}

export default CareLinkEmail
