import { FC } from "react"

import classNames from "classnames"

import LiveNowSvg from "@/svgs/LiveNowSvg"

import "./styles.scss"

interface IProps {
    joinLink?: string
}

const LiveNowLine: FC<IProps> = ({ joinLink }) => {
    return (
        <div className="live-now" data-testid="live-now-line">
            <div className="live-now-line left" />
            <div className="live-now-widget">
                <LiveNowSvg />
                <a
                    href={joinLink}
                    target="_blank"
                    className={classNames("live-now-link", { "disable-link": !joinLink })}
                    rel="noreferrer"
                >
                    <p className="live-now-text">Live Now</p>
                </a>
            </div>
            <div className="live-now-line rigth" />
        </div>
    )
}

export default LiveNowLine
