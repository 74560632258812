import { FC, memo } from "react"

import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { Resource } from "@/models/resource"
import ArrowRightMobile from "@/svgs/ArrowRightMobile"
import ArrowRightNav from "@/svgs/ArrowRightNav"

interface IProps {
    resource: Resource
}

const ResourceLink: FC<IProps> = ({ resource }) => {
    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    return (
        <li className="resources-item ">
            <a
                href={resource.url}
                target="_blank"
                className="align-items-start justify-content-md-start justify-content-center"
                rel="noreferrer"
            >
                <span className="resources-arrow mr-20 d-none d-md-block">
                    <ArrowRightNav />
                </span>
                <span className="resources-arrow d-block d-md-none mt-62-mobile">
                    <ArrowRightMobile />
                </span>
                <span className="mr-20 fs-16-regular font-weight-light">
                    {getValueFromTranslatedObject(resource.name)}{" "}
                    <span
                        className="resources-item__source"
                        style={matches ? { display: "block" } : { display: "inline" }}
                    >
                        {resource.source}
                        {resource.duration ? `, ${resource.duration}` : ""}
                    </span>
                </span>
            </a>
        </li>
    )
}

export default memo(ResourceLink)
