import { useTranslation } from "react-i18next"

import useBoolean from "$/hooks/use-boolean"
import { pluralize } from "$/utils/functions"

import Accordion from "@/shared/accordion/Accordion"
import ChevronToggle from "@/shared/chevron-toggle/ChevronToggle"
import { findSessionToSchedule, findThreeWaySession } from "@/utils/session"

import ScheduleItem from "./ScheduleItem"

import "./styles.scss"

type Props = {
    sessions: any[]
    threeWayScheduled?: any
    onScheduleClick: (session) => void
    onMultipleSessionClick: (session) => void
    setThreeWaySession: (session) => void
}

const ScheduleBox = ({
    sessions,
    threeWayScheduled,
    setThreeWaySession,
    onScheduleClick,
    onMultipleSessionClick
}: Props) => {
    const { t } = useTranslation()
    const { value: showAccordion, toggle: toggleAccordion } = useBoolean(false)

    const onClickButton = () => {
        const threeWaySession = findThreeWaySession(sessions)
        if (threeWaySession) {
            setThreeWaySession(threeWaySession)
        }

        if (sessions?.length > 1) {
            const scheduleSession = findSessionToSchedule(sessions, threeWayScheduled)
            onMultipleSessionClick(scheduleSession)
            return
        }

        toggleAccordion()
    }

    const onClickScheduleItem = session => {
        onScheduleClick(session)
        toggleAccordion()
    }

    return (
        <div className="schedule-box-accordion" data-testid="schedule-box">
            <div
                className="schedule-box-accordion-header d-flex align-items-center justify-content-center"
                onClick={onClickButton}
            >
                <p className="color-gray m-0 text-center">
                    <span className="font-extrabold d-block d-md-inline">{t("Schedule Now")}</span> You have (
                    {sessions?.length}) {pluralize(sessions?.length, "session")} to schedule.
                </p>
                <div className="ml-3">
                    <ChevronToggle active={showAccordion} />
                </div>
            </div>
            <Accordion active={showAccordion}>
                <div className="schedule-box-accordion-body mx-auto mt-2">
                    <div className="gray-line mb-2" />
                    {sessions?.map(session => (
                        <div className="mb-2">
                            <ScheduleItem key={session.id} session={session} onScheduleClick={onClickScheduleItem} />
                        </div>
                    ))}
                </div>
            </Accordion>
        </div>
    )
}

export default ScheduleBox
