import { type Dispatch, type SetStateAction, useCallback, useState } from "react"

import { type NavigateFunction, useNavigate } from "react-router-dom"

import { GroupSessionParticipant } from "@/models/participants"
import type { TEmptyCallback } from "@/shared/types/functions"

interface IUsePaginatedSessions {
    sessionId: number
    isRsvpModalShown: boolean
    isAttendanceModalShown: boolean
    selectedRsvp: GroupSessionParticipant | null
    handleClickParticipant(id: number): void
    handleOpenAttendanceModal(id: number): void
    handleCloseAttendanceModal: TEmptyCallback
    handleSubmitAttendanceModal(cb?: Function): void
    handleCloseRsvpModal: TEmptyCallback
    handleOpenRsvpModal(participant: GroupSessionParticipant): void
}

function usePaginatedSessions(): ReturnType<() => IUsePaginatedSessions> {
    const [sessionId, setSessionId]: [number, Dispatch<SetStateAction<number>>] = useState<number>(null)

    const [isRsvpModalShown, setIsRsvpModalShown]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)
    const [selectedRsvp, setSelectedRsvp]: [
        GroupSessionParticipant,
        Dispatch<SetStateAction<GroupSessionParticipant>>
    ] = useState<GroupSessionParticipant | null>(null)

    const [isAttendanceModalShown, setIsAttendanceModalShown]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState(false)

    const navigate: NavigateFunction = useNavigate()

    const handleOpenAttendanceModal: (id: number) => void = useCallback(
        (id: number): void => (setSessionId(id), setIsAttendanceModalShown(true)),
        []
    )

    const handleCloseAttendanceModal: TEmptyCallback = useCallback(() => setIsAttendanceModalShown(false), [])

    const handleSubmitAttendanceModal: (cb?: Function) => void = useCallback((cb?: Function): void => cb?.(), [])

    const handleClickParticipant: (id: number) => void = useCallback(
        (id: number): void => navigate(`/participant-works/${id}`),
        [navigate]
    )

    const handleOpenRsvpModal: (participantSession: GroupSessionParticipant) => void = useCallback(
        (participant: GroupSessionParticipant) => (setIsRsvpModalShown(true), setSelectedRsvp(participant)),
        []
    )

    const handleCloseRsvpModal: TEmptyCallback = useCallback(
        (): void => (setIsRsvpModalShown(false), setSelectedRsvp(null)),
        []
    )

    return {
        sessionId,
        isRsvpModalShown,
        isAttendanceModalShown,
        selectedRsvp,
        handleOpenAttendanceModal,
        handleCloseAttendanceModal,
        handleSubmitAttendanceModal,
        handleOpenRsvpModal,
        handleCloseRsvpModal,
        handleClickParticipant
    }
}

export { usePaginatedSessions, type IUsePaginatedSessions }
