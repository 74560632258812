import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "$/http"

import { NEW_MESSAGES_REFETCH_INTERVAL, QueryKey } from "../constants"
import { SimulaConversationFeedback, SimulabConversationFeedbackApi } from "../models/conversation"

import { Urls } from "./urls"

const getSimulabConversationFeedback = async ({ queryKey }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_key, { id }] = queryKey
    const { data } = await http.get(Urls.conversationFeedback(id))
    return data
}

export function useSimulabConversationFeedback(conversationId: string, enabled = false) {
    return useQuery<SimulabConversationFeedbackApi, AxiosError, SimulaConversationFeedback>(
        [QueryKey.ConversationFeedback, { id: conversationId }],
        getSimulabConversationFeedback,
        {
            select: useCallback(data => new SimulaConversationFeedback(data), []),
            enabled,
            refetchInterval: NEW_MESSAGES_REFETCH_INTERVAL
        }
    )
}
