import { FC } from "react"

import { useParams } from "react-router-dom"

import Skeleton from "$/components/Skeleton/Skeleton"
import { isNullOrUndefined } from "$/utils/gates"

import usePhases from "@/api/use-phases"
import { Status } from "@/constants"
import { useAuthContext } from "@/context"
import { Phase as PhaseEntity } from "@/models/phase"
import { TrackPracticeModalData } from "@/models/types"

import Phase from "./Phase"

import "./styles.scss"

interface IProps {
    relatedSession: any | null
    onTrackPracticeClick?: (trackPracticeModalData: TrackPracticeModalData) => void
    onSessionScheduleClick?: () => void
}

const Phases: FC<IProps> = ({ onTrackPracticeClick, onSessionScheduleClick, relatedSession }) => {
    const params = useParams()
    const { user } = useAuthContext()
    const module = user?.enrolledModules?.find(module => module.rank === +params?.moduleId)
    const { data: phases, isLoading: isLoadingPhases, isFetching, isRefetching } = usePhases(module?.id)

    const computeActivePhase = (phase: PhaseEntity, index: number) => {
        const previosPhaseCompleted = phases[index - 1]?.status === Status.Completed

        const isOpenOrInProgress = phase.status === Status.InProgress || phase.status === Status.Open

        const isFirstPhase = index === 0

        const isInQueue = phase.status === Status.Queued

        return (
            (previosPhaseCompleted && isOpenOrInProgress) ||
            (isOpenOrInProgress && isFirstPhase) ||
            (previosPhaseCompleted && isInQueue) ||
            (isFirstPhase && isInQueue && relatedSession)
        )
    }

    const canCollapse = (phase: PhaseEntity, index: number) => {
        const previosPhaseCompleted = phases[index - 1]?.status === Status.Completed

        const isCurrentCompletePhase = phase.status === Status.Completed

        const isInQueue = phase.status === Status.Queued

        return previosPhaseCompleted || isCurrentCompletePhase || (previosPhaseCompleted && isInQueue)
    }

    if ((isLoadingPhases && isFetching) || isRefetching) {
        return <Skeleton rows={4} height={63} className="mb-3" />
    }

    const showPhases = phases?.length > 0 && !isNullOrUndefined(module)

    return (
        <div className="accordion accordion-phases w-100" id="accordionExample" data-testid="phases">
            {showPhases &&
                phases?.map((phase, index) => (
                    <Phase
                        phase={phase}
                        phaseOrder={index + 1}
                        phaseIndex={index}
                        relatedSession={relatedSession}
                        onScheduleButtonClick={onSessionScheduleClick}
                        key={phase.id}
                        phaseMaxRank={phases.length}
                        canCollapse={canCollapse(phase, index)}
                        isActivePhase={computeActivePhase(phase, index)}
                        onTrackPracticeClick={onTrackPracticeClick}
                    />
                ))}
        </div>
    )
}

export default Phases
