import { SVGProps, memo } from "react"

const LockSvg = (props: SVGProps<SVGSVGElement>) => {
    const { width = 14, height = 20, className = "" } = props

    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5985 7.99836V4.82938C11.5985 2.60065 9.62785 0.793945 7.1958 0.793945C4.76423 0.793945 2.79311 2.60241 2.79311 4.82938V7.99836H1.89994C1.06693 7.99836 0.391638 8.71643 0.391638 9.59809V17.6056C0.391638 18.4891 1.06648 19.2053 1.89994 19.2053H12.4917C13.3247 19.2053 14 18.4872 14 17.6056V9.59809C14 8.71454 13.3252 7.99836 12.4917 7.99836H11.5985ZM12.399 9.59934V17.6042H1.99262V9.59934H12.399ZM4.39409 7.99836V4.82938C4.39409 3.51851 5.61959 2.39493 7.1958 2.39493C8.77309 2.39493 9.99752 3.51729 9.99752 4.82938V7.99836H4.39409ZM7.99629 12.6287C7.99629 12.1867 7.64102 11.8283 7.1958 11.8283C6.75372 11.8283 6.39531 12.1836 6.39531 12.6287V15.0305C6.39531 15.4725 6.75059 15.8309 7.1958 15.8309C7.63788 15.8309 7.99629 15.4756 7.99629 15.0305V12.6287Z"
                fill="#C4C4C4"
            />
        </svg>
    )
}

export default memo(LockSvg)
