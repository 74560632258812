import { type FC, type ReactElement } from "react"

import { type IUseTranslation, useTranslation } from "@/hooks"

const SurveysAttentionBox: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className="surveys__attention-box">
            <p className="font-extrabold color-gray m-0">{t("participantSide.surveysPage.attentionBox.description")}</p>
            &nbsp;
            <p className="color-gray m-0">{t("participantSide.surveysPage.attentionBox.action")}</p>
        </div>
    )
}

export { SurveysAttentionBox }
