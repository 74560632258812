import { type FC, type ReactElement, useMemo } from "react"

import { type IUseTranslation, useTranslation } from "@/hooks"

import type { IParticipantSurveyModel as IModel } from "../api"

import { SurveysDataListItem as ListItem, TSurveysDataListItemProps } from "./SurveysDataListItem"
import type { THandleListItemClickFunction } from "./types"
import { surveysPageUiUtils as utils } from "./utils"

type TProps = {
    surveysData: IModel[]
    handleListItemClick: THandleListItemClickFunction
}

const SurveysDataList: FC<TProps> = ({ surveysData, handleListItemClick }: TProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const headingListItem: TSurveysDataListItemProps = useMemo(
        (): TSurveysDataListItemProps => utils.getHeadingListItem(t),
        [t]
    )

    return (
        <ul className="surveys__list">
            {[headingListItem, ...surveysData].map((model: IModel, idx: number) => (
                <ListItem
                    key={`survey-${model.id}`}
                    {...model}
                    handleClick={handleListItemClick}
                    tabIndex={idx !== 0 ? 0 : undefined}
                />
            ))}
        </ul>
    )
}

export { SurveysDataList as default, type TProps as TSurveysDataListProps }
