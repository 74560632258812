import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"
import { ServerStateKeys } from "@/constants"
import { useAuthContext } from "@/context"
import { OnboardingStep } from "@/models/onboarding"
import { isParticipant } from "@/utils/gates/user"

const getSteps = async () => {
    const { data } = await http.get(Urls.onboardingSteps())
    return data
}

export default function useOnboardingSteps() {
    const { user } = useAuthContext()

    return useQuery([ServerStateKeys.onboardingSteps], getSteps, {
        select: useCallback(data => {
            return new OnboardingStep(data)
        }, []),
        enabled: isParticipant(user)
    })
}
