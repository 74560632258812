import { type FC, type KeyboardEvent, type ReactElement, ReactNode, useCallback } from "react"

import classNames from "classnames"

import { emptyCallback } from "@/shared/types/functions"

import { ESurveyStatus, type IParticipantSurveyModel as IModel } from "../api"

import type { THandleListItemClickFunction, THandleListItemKeyDownFunction } from "./types"

import { SurveysDataListItemStatusBadge as Status } from "."

type TProps = Pick<IModel, "id" | "closeDate" | "title" | "accessCode"> & {
    status: ESurveyStatus | string
    handleClick: THandleListItemClickFunction
    tabIndex: 0 | undefined
}

const WarningIcon: ReactNode = (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="16" height="16" rx="8" stroke="#FD4D00" strokeWidth="2" />
        <path
            d="M8.44941 10.324L7.88541 4.54H10.1174L9.54141 10.324H8.44941ZM8.02941 13V11.08H9.97341V13H8.02941Z"
            fill="#FD4D00"
        />
    </svg>
)

const SurveysDataListItem: FC<TProps> = ({
    tabIndex,
    status,
    closeDate,
    title,
    accessCode,
    handleClick
}: TProps): ReactElement => {
    const hadItemWarning: boolean = status === ESurveyStatus.InCompleted

    const handleKeyDown: THandleListItemKeyDownFunction = useCallback(
        (event: KeyboardEvent): void | Promise<void> =>
            event.key === "Enter" || event.key === " " ? handleClick(accessCode) : emptyCallback(),
        [handleClick, accessCode]
    )

    return (
        <li
            className="surveys__list__item"
            onKeyDown={handleKeyDown}
            {...(tabIndex === 0 ? { tabIndex } : {})}
            onClick={() => handleClick(accessCode)}
        >
            <div className="surveys__list__item__title">
                {hadItemWarning && WarningIcon}
                <span className={classNames({ "font-extrabold": hadItemWarning })}>{title}</span>
            </div>
            <span className="surveys__list__item__date">{closeDate}</span>
            <Status status={status} />
        </li>
    )
}

export { SurveysDataListItem, type TProps as TSurveysDataListItemProps }
