import classNames from "classnames"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Option } from "$/models/option"

import { FeedbackTypes, SimulabReport } from "@/entities/simulab"
import User from "@/models/user"
import Button from "@/shared/button/Button"
import TextInputBox from "@/shared/input/TextInputBox"
import Select from "@/shared/select-default/Select"

import { FeedbackView } from "./FeedbackView"

const options = [
    new Option({ value: "", label: "--", disabled: true }),
    new Option({ value: "mild", label: "Mild" }),
    new Option({ value: "medium", label: "Moderate" }),
    new Option({ value: "severe", label: "Severe" })
]

type Props = {
    report: SimulabReport
    user: User
    onSubmit: (values) => void
}

export const SimulabFeedbackForm = ({ report, user, onSubmit }: Props) => {
    const { t } = useTranslation()

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: {
            feedback: "",
            severity: ""
        }
    })

    const onSelectSeverity = (option: Option) => {
        setValue("severity", option.value as string)
    }

    const isNotGeneralFeedback = report?.type !== FeedbackTypes.PRACTICE_LAB_GENERAL

    return (
        <main data-testid="simulab-feedback-form">
            <div className={classNames("d-flex justify-content-center", { "mb-45": isNotGeneralFeedback })}>
                <FeedbackView report={report} user={user} />
            </div>
            <TextInputBox
                register={register}
                name="feedback"
                labelText={t("What’s your feedback?")}
                placeholder={t("Start typing...")}
                className="simulab-report-modal__input mb-10"
                inputBehavior="textarea"
                boxVariant="rect"
                inputVariant="rect-no-border"
                errors={errors}
                rows={3}
            />
            {isNotGeneralFeedback && (
                <div className="d-flex align-items-center justify-content-center mb-35" data-test-id="select-severity">
                    <div className="simulab-report-modal__select-wrapper">
                        <p className="simulab-report-modal__select-label">{t("Severity")}:</p>
                        <Select
                            variant="secondary"
                            className="simulab-report-modal__select"
                            backgroundWhite
                            optionsList={options}
                            defaultOptionIndex={0}
                            onSelect={onSelectSeverity}
                        />
                    </div>
                </div>
            )}
            <div className="mt-3 text-center mb-25">
                <Button
                    className="simulab-report-modal__btn-submit"
                    data-testid="simulab-feedback-form-submit"
                    onClick={handleSubmit(onSubmit)}
                >
                    {t("Submit Feedback")}
                </Button>
            </div>

            <p className="m-0 text-center fs-sm-14">
                {t(
                    "Thank you for providing this feedback. We will review it along with relevant data records and may reach out for follow-up if required."
                )}
            </p>
        </main>
    )
}
