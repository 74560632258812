import { useEffect, useMemo, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import Heading from "$/components/Heading/Heading"
import Skeleton from "$/components/Skeleton/Skeleton"
import useUpdateEffect from "$/hooks/use-update-effect"
import { Option } from "$/models/option"
import { isEmptyString, isNullOrUndefined } from "$/utils/gates"
import { getErrorMessages } from "$/utils/get-error-messages"

import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { useAuthContext } from "@/context"
import { ArchiveModal, isEnabledArchiveModal } from "@/entities/archive-goal"
import { CoachingGoalList, CoachingGoalModel, CoachingGoalQueryKey, useCoachingGoalApi } from "@/entities/coaching-goal"
import { GoalsSortSelector } from "@/features/goals-sort-selector/ui/GoalsSortSelector"
import { StorageService } from "@/services"
import PlusIcon from "@/shared/assets/icon/PlusIcon"
import Button from "@/shared/button/Button"
import { GoalsSortType } from "@/shared/types/sort"

import "./styles.scss"

type FormValues = {
    goals: CoachingGoalModel[]
}

const storageService: StorageService = new StorageService()

const CoachingGoal = () => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const { user } = useAuthContext()
    const params = useParams()
    const userId = !isNullOrUndefined(params?.id) ? +params?.id : user?.id

    const [sort, setSort] = useState<GoalsSortType>("active")
    const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [archiveText, setArchiveText] = useState({ text: "", index: null, id: null })

    const {
        coachingGoal,
        isLoadingCoachingGoal,
        coachingGoalError,
        archivedGoals,
        archiveError,
        createCoachingGoal,
        editCoachingGoal,
        archiveGoal,
        unarchiveGoal,
        sortGoals
    } = useCoachingGoalApi({ userId, sort })

    const methods = useForm<FormValues>({
        defaultValues: {
            goals: coachingGoal ?? []
        }
    })

    const { prepend, replace, remove, fields } = useFieldArray({
        control: methods.control,
        keyName: "uuid",
        name: "goals"
    })

    const hasArchived = useMemo(() => {
        return archivedGoals?.length > 0
    }, [archivedGoals])

    useEffect(() => {
        if (!isLoadingCoachingGoal && coachingGoal) {
            replace(coachingGoal)
        }
    }, [coachingGoal, isLoadingCoachingGoal])

    useUpdateEffect(() => {
        if (!hasArchived && sort === "archived") {
            setSort("active")
        }
    }, [hasArchived])

    const onArchiveCardClick = async (index: number, id: number) => {
        const archivedGoal = archivedGoals.includes(id)

        if (archivedGoal) {
            try {
                await unarchiveGoal.mutateAsync(id)
                invalidateCoachingGoals()
                setErrorMsg(null)
            } catch (error) {
                setErrorMsg(getErrorMessages(error))
            }
            return
        }

        setArchiveText({ text: methods.getValues(`goals.${index}.description`), index, id })

        if (isEnabledArchiveModal(storageService)) {
            setIsOpenArchiveModal(true)
        } else {
            onArchiveClick(id)
        }
    }

    const onArchiveClick = async (id?: number) => {
        setIsOpenArchiveModal(false)
        try {
            await archiveGoal.mutateAsync(id ?? archiveText.id)
            invalidateCoachingGoals()
            setErrorMsg(null)
        } catch (error) {
            setErrorMsg(getErrorMessages(error))
        }
        setArchiveText({ text: "", index: null, id: null })
    }

    const onCloseArchiveModal = () => {
        setIsOpenArchiveModal(false)
        setArchiveText({ text: "", index: null, id: null })
    }

    const onAddCoachingGoal = () => {
        const hasEmptyDescription = methods.getValues("goals").some(goal => isEmptyString(goal.description))

        const isNewAddedItem = fields.length !== coachingGoal.length

        const hasEmptyAddedGoal = hasEmptyDescription && fields.length !== 0

        if (hasEmptyAddedGoal || isNewAddedItem) {
            return
        }

        prepend({ description: "", order: 0, id: Date.now(), participantId: userId }, { shouldFocus: true })
    }

    const onChangeSort = (sort: Option) => {
        setSort(sort.value as GoalsSortType)
    }

    const invalidateCoachingGoals = () => {
        queryClient.invalidateQueries([CoachingGoalQueryKey.CoachingGoal])
    }

    const onBlur = async (coachingGoalIndex: number) => {
        const { description, id } = methods.getValues(`goals.${coachingGoalIndex}`)

        if (isEmptyString(description)) {
            remove(coachingGoalIndex)
            return
        }

        createEditCoachingGoal({ description, order: coachingGoalIndex + 1 }, id)
    }

    const createEditCoachingGoal = async (goal: Pick<CoachingGoalModel, "description" | "order">, id: number) => {
        const goals = methods.getValues("goals")

        try {
            if (goals.length !== coachingGoal.length) {
                const { data } = await createCoachingGoal.mutateAsync(goal)
                makeSorting({ oldId: id, newId: data.id })
            } else {
                await editCoachingGoal.mutateAsync({ ...goal, id })
                invalidateCoachingGoals()
                setErrorMsg(null)
            }
        } catch (error) {
            setErrorMsg(getErrorMessages(error))
        }
    }

    const makeSorting = async ({ oldId, newId }: { oldId: number; newId: number }) => {
        const goals = methods.getValues("goals")
        const ids = goals.map(goal => (goal.id === oldId ? newId : goal.id))

        try {
            await sortGoals.mutateAsync({ ids })
            invalidateCoachingGoals()
            setErrorMsg(null)
        } catch (error) {
            setErrorMsg(getErrorMessages(error))
        }
    }

    if (isLoadingCoachingGoal) {
        return (
            <div className="mb-2">
                <Skeleton rows={1} height={50} width="200px" className="mb-1" />
                <Skeleton rows={3} height={70} />
            </div>
        )
    }

    if (coachingGoalError || archiveError) {
        const error = coachingGoalError ?? archiveError
        const msg = getErrorMessages(error?.message)?.join() ?? t("An error occurred, please try again.")
        return <WarningMessage message={msg} />
    }

    return (
        <FormProvider {...methods}>
            <section className="mb-3">
                <div className="mb-10 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                    <div className="d-flex flex-column flex-row-md align-items-md-center flex-md-row">
                        <Heading tag="h2" fontSize={16} className="mb-3 m-0 mr-4 mb-md-0">
                            {t("Coaching Goal")}
                        </Heading>
                        <div>
                            <Button variant="outline-rect" onClick={onAddCoachingGoal}>
                                <PlusIcon />
                                <span className="d-inline-block ml-2">{t("Add Coaching Goal")}</span>
                            </Button>
                        </div>
                    </div>
                    {hasArchived && (
                        <GoalsSortSelector
                            className="align-self-md-end mt-3 m-md-0"
                            onChangeSelect={onChangeSort}
                            sort={sort}
                        />
                    )}
                </div>
                {fields?.length === 0 ? (
                    <p className="m-0 color-gray font-italic font-sm">
                        {t("What are you hoping to accomplish in this coaching engagement?")}
                    </p>
                ) : null}
            </section>
            <WarningMessage message={errorMsg} />
            <CoachingGoalList
                sort={sort}
                archivedGoals={archivedGoals}
                goals={fields}
                onArchiveClick={onArchiveCardClick}
                onBlur={onBlur}
            />
            <ArchiveModal
                archiveTitle={t("Coaching Goal")}
                isOpen={isOpenArchiveModal}
                onClose={onCloseArchiveModal}
                onArchive={onArchiveClick}
                archiveText={archiveText.text}
            />
        </FormProvider>
    )
}

export default CoachingGoal
