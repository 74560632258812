import { TranslationObj } from "$/utils/lang"

interface CohortModuleApi {
    id: number
    category: string
    category_description: string
    is_completed: boolean
    module_description: string
    enrolled: boolean
    version: TranslationObj
}

class Module {
    id: number

    longDescription: TranslationObj

    slug: string

    version: string

    versionId: number

    name: string

    titleVersion: TranslationObj

    constructor(data) {
        this.id = data.id
        this.longDescription = data.long_description
        this.slug = data.slug
        this.version = data.version
        this.versionId = data.version_id
        this.name = data.name
        this.titleVersion = data.version_title
    }
}

class CohortModule {
    id: number

    category: string

    categoryDescription: string

    isCompleted: boolean

    moduleDescription: string

    enrolled: boolean

    version: TranslationObj

    constructor(data: CohortModuleApi) {
        this.id = data.id
        this.category = data.category
        this.categoryDescription = data.category_description
        this.isCompleted = data.is_completed
        this.moduleDescription = data.module_description
        this.version = data.version
        this.enrolled = !!data.enrolled
    }
}

export { Module, CohortModule, CohortModuleApi }
