import { useSimulabContext } from "@/entities/simulab"
import Modal from "@/shared/modal"

import { StageItem } from "./Stage"

import "./styles.scss"

type Props = {
    show: boolean
    onClose: () => void
}

export const SimulabMobileStagesModal = ({ show, onClose }: Props) => {
    const { conversation } = useSimulabContext()

    return (
        <Modal
            show={show}
            headerContent={<div />}
            onClose={onClose}
            modalBodyClass="simulab-mobile-stage-modal"
            disableBodyScroll
        >
            <div className="simulab-mobile-stage-modal__body">
                {conversation?.stages?.map(
                    stage =>
                        (stage.completed || stage.current) && (
                            <StageItem key={stage.id} stage={stage} showBubbleArrow={false} />
                        )
                )}
            </div>
        </Modal>
    )
}
