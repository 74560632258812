import { memo } from "react"

type Props = {
    className?: string
    size: "sm" | "default"
}

const RedCheckMark = (props: Props) => {
    const { className = "", size = "default" } = props

    return (
        <>
            {size === "sm" ? (
                <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    className={className}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="10.5" cy="10.5" r="10.5" fill="#FD4D00" />
                    <path
                        d="M6.84766 10.7291L9.35853 13.4682L15.065 7.76172"
                        stroke="white"
                        strokeWidth="1.96875"
                        strokeLinecap="square"
                    />
                </svg>
            ) : (
                <svg
                    width="32"
                    height="32"
                    className={className}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    {...props}
                >
                    <circle cx={16} cy={16} r={16} fill="#FD4D00" />
                    <path
                        d="M10.435 16.348l3.827 4.174 8.695-8.696"
                        stroke="#fff"
                        strokeWidth={3}
                        strokeLinecap="square"
                    />
                </svg>
            )}
        </>
    )
}

export default memo(RedCheckMark)
