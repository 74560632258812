import { TooltipDimensions, TooltipPosition } from "./types"

export const calculateTooltipPosition = (
    position: TooltipPosition,
    dimensions: TooltipDimensions,
    offsets: { offsetX: number; offsetY: number },
    bounds: DOMRect
) => {
    const { offsetX, offsetY } = offsets
    const { width, height } = dimensions
    const { left, top, width: targetWidth, height: targetHeight } = bounds

    const horizontalCenter = left + targetWidth / 2 - width / 2
    const verticalCenter = top + targetHeight / 2 - height / 2

    switch (position) {
        case "top":
        case "top-start":
        case "top-end":
            return {
                adjustedTop: top - height - offsetY,
                adjustedLeft:
                    position === "top"
                        ? horizontalCenter + offsetX
                        : position === "top-start"
                          ? left + offsetX
                          : left + targetWidth / 2 + offsetX
            }
        case "bottom":
        case "bottom-start":
        case "bottom-end":
            return {
                adjustedTop: top + targetHeight + offsetY,
                adjustedLeft:
                    position === "bottom"
                        ? horizontalCenter + offsetX
                        : position === "bottom-start"
                          ? left + offsetX
                          : left + targetWidth / 2 + offsetX
            }
        case "left":
            return {
                adjustedTop: verticalCenter + offsetY,
                adjustedLeft: left - width - offsetX
            }
        case "right":
            return {
                adjustedTop: verticalCenter + offsetY,
                adjustedLeft: left + targetWidth + offsetX
            }
        default:
            return { adjustedLeft: left, adjustedTop: top }
    }
}
