import type { FC, ReactElement } from "react"

import { DateTime } from "luxon"

import { Session } from "@/models/session"
import Button from "@/shared/button/Button"

import { CoachTableGeneralCell } from "./CoachTableGeneralCell"

type TCoachTableJoinSessionCellProps = { session: Session }

const CoachTableJoinSessionCell: FC<TCoachTableJoinSessionCellProps> = ({
    session
}: TCoachTableJoinSessionCellProps): ReactElement => {
    const isSessionAvailable: boolean = ((): boolean => {
        const currentTime: DateTime = DateTime.now()
        const sessionTime: DateTime = DateTime.fromISO(session.sessionTime)
        const sessionAvailableTime: DateTime = sessionTime.minus({ minutes: 15 })
        const sessionEndTime: DateTime = sessionTime.plus({ minutes: session.sessionType.duration })

        return (
            currentTime.toMillis() >= sessionAvailableTime.toMillis() &&
            currentTime.toMillis() < sessionEndTime.toMillis() &&
            !!session?.joinUrl
        )
    })()

    return (
        <CoachTableGeneralCell isLarge isHidden withoutLeftAlignedText withoutLeftPadding>
            {isSessionAvailable ? (
                <a href={session?.joinUrl} target="_blank" className="font-extrabold d-block w-max" rel="noreferrer">
                    <Button className="table-btn w-auto" variant="brand">
                        Join Session
                    </Button>
                </a>
            ) : null}
        </CoachTableGeneralCell>
    )
}

export { CoachTableJoinSessionCell, type TCoachTableJoinSessionCellProps }
