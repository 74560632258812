import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"
import { ServerStateKeys } from "@/constants"
import { useAuthContext } from "@/context"
import { NpsAPI } from "@/models/api/nps"
import { Nps } from "@/models/nps"

const getNps = async () => {
    const { data } = await http.get(Urls.npsQuestion())
    return data
}

export default function useNps() {
    const { user } = useAuthContext()
    return useQuery<NpsAPI, Error, Nps>([ServerStateKeys.Nps], getNps, {
        select: useCallback(data => {
            return new Nps(data)
        }, []),
        enabled: !!user
    })
}
