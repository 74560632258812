import { FC, useRef, useState } from "react"

import sanitizeHtml from "sanitize-html"

import Chevron from "@/svgs/Chevron"

interface IProps {
    version: string
    content: string
}

const Accordion: FC<IProps> = ({ version, content }) => {
    const [isActive, setIsActive] = useState(false)
    const contentRef = useRef<HTMLDivElement>()

    const toggleAccordion = () => {
        setIsActive(prev => !prev)
    }

    return (
        <button type="button" className="accordion-item" onClick={toggleAccordion}>
            <span className="accordion-item-text">{version}</span>{" "}
            <Chevron isActive={isActive} className="accordion-item-chevron" />
            <div
                className="mt-2 accordion-item-content"
                ref={contentRef}
                style={isActive ? { height: `${contentRef.current?.scrollHeight}px` } : { height: "0px" }}
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }}
            />
        </button>
    )
}

export default Accordion
