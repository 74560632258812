import { FC, useState } from "react"

import classNames from "classnames"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import TrackPracticeTextAreaDesktop from "./TrackPracticeTextAreaDesktop"

interface IProps {
    rowIndex: number
}

const TrackPracticeMobileBoxes: FC<IProps> = ({ rowIndex }) => {
    const { t } = useTranslation()
    const [focused, setFocused] = useState(false)
    const [focusedIndex, setFocusedIndex] = useState(null)

    const { control, getValues, register } = useFormContext()

    const { fields: columns } = useFieldArray({
        control,
        name: `rows[${rowIndex}].columns`,
        keyName: "column_uuid"
    })

    const onFocus = (index: number) => {
        setFocused(true)
        setFocusedIndex(index)
    }

    const onBlur = () => {
        setFocused(false)
        setFocusedIndex(null)
    }

    return (
        <>
            {columns.map((col, index) => (
                <div className="track-practice-edit-item" key={col.column_uuid}>
                    <h4 className="h4 font-extrabold">{getValues(`rows.${rowIndex}.columns.${index}.title`)}</h4>
                    <div className="col-item-content">
                        <div
                            className={classNames("col-edit br-top-10 text-center", {
                                focus: focused && index === focusedIndex
                            })}
                        >
                            <p className="font-sm">{getValues(`rows.${rowIndex}.columns.${index}.subtitle`)}</p>
                        </div>

                        <TrackPracticeTextAreaDesktop
                            name={`rows.${rowIndex}.columns.${index}.answer`}
                            className="col-item-textarea br-bottom-10"
                            onFocus={() => onFocus(index)}
                            register={register}
                            control={control}
                            // @ts-expect-error legacy prop below
                            rowIndex={rowIndex}
                            onBlur={onBlur}
                            placeholder={
                                getValues(`rows.${rowIndex}.columns.${index}.typing_prompt`) || t("Start typing...")
                            }
                        />
                    </div>
                </div>
            ))}
        </>
    )
}

export default TrackPracticeMobileBoxes
