import type { FC, ReactElement, ReactNode } from "react"

import classNames from "classnames"
import { Link } from "react-router-dom"

import { CoachUrls } from "@/constants"
import Avatar from "@/shared/avatar/Avatar"

import { AVATAR_FALLBACK_COLOR } from "../config"

import { CoachWelcomeUserListItemImage as ParticipantImage } from "./CoachWelcomeUserListItemImage"
import { ECoachUserListItemVariant, type TCoachUserListItemToRender } from "./types"

const UserListItem: FC<TCoachUserListItemToRender> = ({
    variant,
    id,
    description,
    role,
    color,
    lastName,
    firstName,
    photo
}: TCoachUserListItemToRender): ReactElement => {
    const isCoachListItem: boolean = variant === ECoachUserListItemVariant.Coach

    const coachAvatarDimension: number = 128

    function renderWrapperContent(): ReactNode {
        return (
            <>
                {isCoachListItem ? (
                    <Avatar className="mb-3" url={photo} width={coachAvatarDimension} height={coachAvatarDimension} />
                ) : (
                    <ParticipantImage fillerColor={color ?? AVATAR_FALLBACK_COLOR} className="mb-4" />
                )}

                <p className="font-extrabold mb-1">{role}</p>
                <span className="text-center font-sm mb-2">{description}</span>
                <span className="text-center font-sm color-gray">
                    {firstName}&nbsp;{lastName}
                </span>
            </>
        )
    }

    return (
        <li
            tabIndex={0}
            role="tab"
            data-index={String(id)}
            className={classNames("coach-welcome__user-list__item", {
                primary: isCoachListItem,
                secondary: !isCoachListItem
            })}
        >
            {isCoachListItem ? (
                <Link tabIndex={-1} to={CoachUrls.SESSIONS_LIST} className="coach-welcome__user-list__item__container">
                    {renderWrapperContent()}
                </Link>
            ) : (
                <div className="coach-welcome__user-list__item__container">{renderWrapperContent()}</div>
            )}
        </li>
    )
}

export { UserListItem as CoachWelcomeUserListItem }
