import { type FC, type ReactElement, type ReactNode, useCallback, useMemo } from "react"

import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import Coach from "@/models/coach"
import { type IAvailableGuestSessionModel } from "@/pages/swap-session/api"
import { type ISwiperProps, Swiper } from "@/shared/swiper"
import type { TEmptyCallback } from "@/shared/types/functions"

import type { IAvailableSessionDataGroupedByWeek } from "./types"
import { getGroupedAvailableSessionData } from "./utils"

import { SwapSessionSlide as Slide } from "."

type TSwapSessionSliderProps = {
    availableSessions: IAvailableGuestSessionModel[] | []
    coach?: Coach
    handleSetSuggestedSession(session: IAvailableGuestSessionModel): void
    handleOpenSwapSessionModal: TEmptyCallback
    originalSessionTime: string
}

const SwapSessionSlider: FC<TSwapSessionSliderProps> = ({
    coach,
    availableSessions = [],
    handleOpenSwapSessionModal,
    handleSetSuggestedSession,
    originalSessionTime
}: TSwapSessionSliderProps): ReactElement => {
    const groupedData: IAvailableSessionDataGroupedByWeek[] = useMemo(
        (): IAvailableSessionDataGroupedByWeek[] =>
            getGroupedAvailableSessionData({ sessionData: availableSessions, originalSessionTime }),
        [availableSessions, originalSessionTime]
    )

    const initialSlideIndex: number = useMemo(
        (): number =>
            groupedData.findIndex(({ isPreferredWeek }: IAvailableSessionDataGroupedByWeek) => isPreferredWeek) ?? 0,
        [groupedData]
    )

    const renderSlideContent = useCallback(
        ({ weekRange, sessions, isPreferredWeek }: IAvailableSessionDataGroupedByWeek, coach: Coach): ReactElement => (
            <Slide
                key={`slide-${weekRange}`}
                isPreferredWeek={isPreferredWeek}
                handleSetSuggestedSession={handleSetSuggestedSession}
                handleOpenSwapSessionModal={handleOpenSwapSessionModal}
                coach={coach}
                title={weekRange}
                sessions={sessions}
            />
        ),
        [handleOpenSwapSessionModal, handleSetSuggestedSession]
    )

    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const swiperProps: ISwiperProps = {
        withNavigation: true,
        withPagination: true,
        initialSlide: initialSlideIndex,
        withTouchableSlides: !isMobile,
        slides: groupedData?.map((s: IAvailableSessionDataGroupedByWeek) => ({
            children: renderSlideContent(s, coach) as ReactNode,
            className: "swap-session__slide"
        }))
    }

    return <Swiper {...swiperProps} />
}

export { SwapSessionSlider as default, type TSwapSessionSliderProps }
