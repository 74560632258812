import { useMemo } from "react"

import classNames from "classnames"
import { Link } from "react-router-dom"

import { useAuthContext } from "@/context"
import useNavigationOnboarding from "@/utils/hooks/use-navigation-onboarding"

import { OnboardingSteps, navigationDefault } from "../constants"

type Props = {
    activeStep: { id: OnboardingSteps; order: number }
}

export const StepsNavigator = ({ activeStep }: Props) => {
    const { onboardingSteps } = useNavigationOnboarding()
    const { user } = useAuthContext()

    const nav = useMemo(
        () =>
            navigationDefault.filter(key =>
                key.id === OnboardingSteps.SESSION_HOLD_TIMES && user?.groupExists
                    ? false
                    : !!onboardingSteps?.availableSteps?.find(step => key.id === step)
            ),
        [onboardingSteps]
    )

    return (
        <div className="step-holder">
            <ul className="step-bar">
                {nav?.length > 1
                    ? nav?.map((item, index) => (
                          <li
                              key={index}
                              className={classNames({
                                  active: item.id === activeStep.id,
                                  passed: !!onboardingSteps?.previousSteps?.[index + 1] && item.id !== activeStep.id
                              })}
                          >
                              {item.id === activeStep.id || onboardingSteps?.previousSteps?.[index] ? (
                                  <span
                                      className={classNames("step-item", { "no-line": nav.length === 1 })}
                                      aria-label={item.title}
                                  />
                              ) : (
                                  <Link
                                      to={item.url}
                                      className={classNames("step-item text-hide", { "no-line": nav.length === 1 })}
                                  >
                                      {item.title}
                                  </Link>
                              )}
                          </li>
                      ))
                    : null}
            </ul>
        </div>
    )
}
