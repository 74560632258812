import { type FC, type ReactElement } from "react"

import Coach from "@/models/coach"
import type { IAvailableGuestSessionModel } from "@/pages/swap-session/api"
import type { TEmptyCallback } from "@/shared/types/functions"

import type { IAvailableSessionDataGroupedByDay } from "./types"

import { SwapSessionAccordionHeading as AccordionHeading, SwapSessionAccordionList as AccordionList } from "."

type TSwapSessionSlideProps = {
    title: string
    sessions?: IAvailableSessionDataGroupedByDay[]
    coach?: Coach
    handleSetSuggestedSession(session: IAvailableGuestSessionModel): void
    handleOpenSwapSessionModal: TEmptyCallback
    isPreferredWeek: boolean
}

const SwapSessionSlide: FC<TSwapSessionSlideProps> = ({
    title,
    sessions,
    coach,
    handleSetSuggestedSession,
    handleOpenSwapSessionModal,
    isPreferredWeek
}: TSwapSessionSlideProps): ReactElement => (
    <div className="swap-session__accordion__container">
        <AccordionHeading title={title} isPreferredWeek={isPreferredWeek} />
        <AccordionList
            handleSetSuggestedSession={handleSetSuggestedSession}
            handleOpenSwapSessionModal={handleOpenSwapSessionModal}
            sessions={sessions}
            coach={coach}
        />
    </div>
)

export { SwapSessionSlide, type TSwapSessionSlideProps }
