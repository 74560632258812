import { SVGProps, memo } from "react"

interface Props extends SVGProps<SVGSVGElement> {
    disabled?: boolean
}

const DisabledSendIconSvg = memo(({ width = 18, height = 18, className = "", ...rest }: Props) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...rest}
    >
        <path
            d="M0.0250072 17.1654L1.54499 10.5854C1.60502 10.3054 1.84499 10.0854 2.14499 10.0454L10.645 9.16541C10.885 9.14542 10.885 8.78539 10.645 8.74542L2.14499 7.92544C1.84499 7.90545 1.60502 7.68547 1.54499 7.40546L0.0250072 0.845288C-0.114966 0.285322 0.485041 -0.174695 1.00502 0.0853217L17.625 8.40532C18.125 8.66535 18.125 9.38534 17.625 9.64529L1.00502 17.9253C0.485041 18.1853 -0.114959 17.7253 0.0250072 17.1653V17.1654Z"
            fill="#B0B0B0"
        />
    </svg>
))

export { DisabledSendIconSvg }
