import { Link } from "react-router-dom"

import Heading from "$/components/Heading/Heading"

import { CoachCalendarStepUrls } from "@/pages/coach-settings/constants"
import Button from "@/shared/button/Button"
import Modal from "@/shared/modal"

import CoachSetupListStep from "./CoachSetupListStep"

import "./styles.scss"

type Props = {
    show: boolean
    onClose: () => void
}

const CoachSetupScheduleModal = ({ show, onClose }: Props) => {
    return (
        <Modal
            show={show}
            onClose={onClose}
            headerContent={
                <Heading className="text-center mb-0 p-3" fontSize={24}>
                    Set Up Your Schedule
                </Heading>
            }
            modalBodyClass="coach-setup-schedule-modal"
        >
            <div className="coach-setup-schedule-modal__body">
                <div className="text-center">
                    <CoachSetupListStep />
                    <div className="text-center mt-50">
                        <Link to={CoachCalendarStepUrls.LINK_CALENDAR_STEP} className="color-inherit">
                            <Button>Get Started</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CoachSetupScheduleModal
