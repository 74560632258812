import { type Dispatch, type SetStateAction, useEffect, useState } from "react"

import { PARTICIPANTS_IN_ROW_MAX_COUNT } from "@/constants"
import { ParticipantBase } from "@/models/participants"

interface ICroppedAndSortedParticipantList {
    sortedParticipantList: ParticipantBase[]
    isParticipantsAmountExceedingViewLimit: boolean
    exceedingParticipantsCount: number
}

type TParams = { participantList: ParticipantBase[] | []; viewLimit?: number }

type TReturn = ReturnType<({ participantList }: TParams) => ICroppedAndSortedParticipantList>

function sortParticipantByPhotoAndName(pFirst?: ParticipantBase, pNext?: ParticipantBase): number {
    return pFirst?.photo && !pNext?.photo
        ? -1
        : !pFirst?.photo && pNext?.photo
          ? 1
          : pFirst?.firstName?.localeCompare(pNext.firstName)
}

function useCroppedAndSortedParticipantList({
    participantList,
    viewLimit = PARTICIPANTS_IN_ROW_MAX_COUNT
}: TParams): TReturn {
    const [sortedParticipantList, setSortedParticipantList]: [
        ParticipantBase[],
        Dispatch<SetStateAction<ParticipantBase[]>>
    ] = useState<ParticipantBase[] | []>(participantList ?? [])

    const isParticipantsAmountExceedingViewLimit: boolean = participantList.length > viewLimit

    const exceedingParticipantsCount: number = participantList.length - sortedParticipantList.length

    useEffect(
        (): void => (
            isParticipantsAmountExceedingViewLimit &&
                setSortedParticipantList((prev: ParticipantBase[]): ParticipantBase[] =>
                    prev?.sort(sortParticipantByPhotoAndName)?.slice(0, viewLimit)
                ),
            void 0
        ),
        [isParticipantsAmountExceedingViewLimit, viewLimit]
    )

    return { sortedParticipantList, isParticipantsAmountExceedingViewLimit, exceedingParticipantsCount }
}

export { useCroppedAndSortedParticipantList, type ICroppedAndSortedParticipantList }
