import { useCallback } from "react"

export const DOTS = "..."

export const DEFAULT_PAGINATION_PAGE: number = 1

const getRange = (start: number, end: number): number[] => {
    const length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
}

type TGetPaginationRangeParams = {
    totalCount: number
    pageSize: number
    siblingCount: number
    currentPage: number
}

type TPaginationRange = (number | string)[]

interface IPagination {
    getPaginationRange(params: TGetPaginationRangeParams): TPaginationRange
}

type TReturn = ReturnType<() => IPagination>

const usePagination = (): TReturn => ({
    getPaginationRange: useCallback(
        ({ totalCount, pageSize, siblingCount = 1, currentPage }: TGetPaginationRangeParams): TPaginationRange => {
            const totalPageCount = Math.ceil(totalCount / pageSize)

            const totalPageNumbers = siblingCount + 5

            if (totalPageNumbers >= totalPageCount) {
                return getRange(1, totalPageCount)
            }

            const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
            const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)

            const shouldShowLeftDots = leftSiblingIndex > 2
            const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

            const firstPageIndex = 1
            const lastPageIndex = totalPageCount

            if (!shouldShowLeftDots && shouldShowRightDots) {
                const leftItemCount = 3 + 2 * siblingCount
                const leftRange = getRange(1, leftItemCount)

                return [...leftRange, DOTS, totalPageCount]
            }

            if (shouldShowLeftDots && !shouldShowRightDots) {
                const rightItemCount = 3 + 2 * siblingCount
                const rightRange = getRange(totalPageCount - rightItemCount + 1, totalPageCount)
                return [firstPageIndex, DOTS, ...rightRange]
            }

            if (shouldShowLeftDots && shouldShowRightDots) {
                const middleRange = getRange(leftSiblingIndex, rightSiblingIndex)
                return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
            }
        },
        []
    )
})

export { type TGetPaginationRangeParams, type TPaginationRange, type IPagination, usePagination }
