import { Category } from "./category"
import { Module } from "./module"

export class ModulesCategory {
    category: Category

    modules: Module[]

    constructor(data) {
        this.category = new Category(data.category)
        this.modules = data.modules.map(module => new Module(module))
    }
}
