import type { AxiosResponse } from "axios"

import { http } from "$/http"

import Urls from "@/api/urls"
import { capitalized } from "@/utils/common"

import type { ISubUserDto as IDto, ISubUserModel as IModel } from "."

function adapter(dtos: IDto[]): IModel[] {
    return dtos?.map(
        ({ first_name, last_name, product_type, jwt_pair, role, ...rest }: IDto): IModel => ({
            firstName: first_name,
            lastName: last_name,
            productType: product_type,
            jwtPair: jwt_pair,
            role: capitalized(role),
            ...rest
        })
    )
}

async function fetcher(): Promise<IDto[]> {
    const { data }: AxiosResponse<IDto[]> = await http.get<IDto[]>(Urls.getSubUsers())

    return data
}

type TUtils = {
    adaptSubUserList(dtos: IDto[]): IModel[]
    fetchSubUserList(): Promise<IDto[]>
}

const utils: TUtils = { adaptSubUserList: adapter, fetchSubUserList: fetcher }

export { utils as subUserListQueryUtils, type TUtils as TSubUserListQueryUtils }
