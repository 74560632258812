import * as yup from "yup"

export const createValidationSchema = questions => {
    const requiredString = yup.string().nullable().required("Answer is required")

    const notRequiredString = yup.string().nullable().notRequired()

    const answerSchema = questions.reduce((schema, question) => {
        schema[question.id] = yup.object().shape({
            answer: question.isFreeResponse ? notRequiredString : requiredString,
            free_response: question.isFreeResponse ? requiredString : notRequiredString
        })
        return schema
    }, {})

    return yup.object().shape(answerSchema)
}
