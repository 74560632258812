import { startTransition, useCallback, useMemo } from "react"

import { type NavigateFunction, useNavigate } from "react-router-dom"

import { CoachUrls, INDEX_URL, PRODUCT_TYPE_WITH_ENROLLMENTS, ParticipantUrls } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import type { TEmptyCallback } from "@/shared/types/functions"
import { isCoach, isParticipant, isProgramManager } from "@/utils/gates/user"
import useNavigationOnboarding from "@/utils/hooks/use-navigation-onboarding"

type TUseLogoNavigation = {
    handleLogoNavigation: TEmptyCallback
    logoNavigationUrl: string
}

function useLogoNavigation(): ReturnType<() => TUseLogoNavigation> {
    const navigate: NavigateFunction = useNavigate()

    const { user }: IAuthContext = useAuthContext()

    const { getNextStepUrl } = useNavigationOnboarding()

    const logoNavigationUrl: string = useMemo((): string => {
        let url: string = INDEX_URL

        if (isCoach(user) || isProgramManager(user)) {
            url = CoachUrls.SESSIONS_LIST
        } else if (isParticipant(user)) {
            if (user?.hasNotCompletedSurveys && user?.onboardingFinished) {
                url = ParticipantUrls.SURVEYS
            } else if (user?.onboardingFinished && PRODUCT_TYPE_WITH_ENROLLMENTS.includes(user?.productType)) {
                url = ParticipantUrls.DASHBOARD
            } else if (!user?.onboardingFinished) {
                getNextStepUrl().then(step => (url = step?.url))
            } else if (!user.cohortExists) {
                url = ParticipantUrls.WAITING_LIST
            } else {
                url = ParticipantUrls.SESSION_LIST
            }
        }

        return url
    }, [getNextStepUrl, user])

    const handleLogoNavigation: TEmptyCallback = useCallback(
        (): void => startTransition((): void => navigate(logoNavigationUrl)),
        [navigate, logoNavigationUrl]
    )

    return { handleLogoNavigation, logoNavigationUrl }
}

export { useLogoNavigation, type TUseLogoNavigation }
