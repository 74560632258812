import { useCallback } from "react"

import { type UseQueryResult, useQuery } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

import { http } from "$/http"

import { ServerStateKeys as EQueryKey } from "@/constants"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { WaitilingListApi as IDto } from "@/models/waiting-list"

import Urls from "./urls"

interface IModel {
    websiteLink: string
    contactEmail: string
    text: string
    textTitleData: string
    textInfoButtonData: string
    textInfoEmailData: string
    textSubtitleData: string
}

function adapter(data: IDto, translationAdapter: TGetValueFromTranslatedObjectFunction): IModel {
    return {
        websiteLink: data.website_link,
        contactEmail: data.contact_email,
        text: translationAdapter(data.text_data),
        textTitleData: translationAdapter(data.text_title_data),
        textInfoButtonData: translationAdapter(data.text_info_button_data),
        textInfoEmailData: translationAdapter(data.text_info_email_data),
        textSubtitleData: translationAdapter(data.text_subtitle_data)
    }
}

const getWaitingListText: () => Promise<IDto> = async (): Promise<IDto> => {
    const { data }: AxiosResponse<IDto> = await http.get(Urls.onboardingWaitListText())

    return data
}

export default function useWaitingListText(): UseQueryResult<IModel, AxiosError> {
    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    return useQuery<IDto, AxiosError, IModel>([EQueryKey.WaitingListText], getWaitingListText, {
        select: useCallback(
            (data: IDto): IModel => adapter(data, getValueFromTranslatedObject),
            [getValueFromTranslatedObject]
        )
    })
}
