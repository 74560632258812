import { Session } from "@/models/session"

enum ECoachSessionWithoutAttendanceMarksModality {
    individual = "individual",
    group = "group"
}

interface ICoachSessionWithoutAttendanceMarksDto {
    session_id: number | null
    session_modality: "group" | "individual"
    session_time: string | null
    session_type: string | null
    duration: number
    title: string | null
    photo: string | null
}

interface ICoachSessionWithoutAttendanceMarksModel extends Pick<Session, "sessionId" | "sessionTime" | "title"> {
    photo: string | null
    sessionType: string | null
    sessionModality: string | null
    duration: number
}

export {
    type ICoachSessionWithoutAttendanceMarksModel,
    type ICoachSessionWithoutAttendanceMarksDto,
    ECoachSessionWithoutAttendanceMarksModality
}
